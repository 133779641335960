import React, { useState } from 'react'
import '../../../scss/CandidateList.scss'
import Header from '../../../components/Header'
import { Tab, Tabs } from 'react-bootstrap';
import Shortlisted from '../../../components/Shortlisted';
import WhatsappShortlisted from '../../../components/WhatsappShortlisted';
import FormShortlisted from '../../../components/FormShortlisted';
import PoolShortlisted from '../../../components/PoolShortlisted';

export default function CandidateList() {
    const [key, setKey] = useState('shortlisted');

    return (
        <div className='candidate-list-wrapper'>
            <Header heading={'Shortlisted Candidates'} description={'List of all the candidates shortlisted by AI'} />
            <div className="list-content">
                <div className='tabs'>
                    <Tabs
                        id="controlled-tab-example"
                        activeKey={key}
                        onSelect={(k) => setKey(k)}
                    >
                        <Tab eventKey="shortlisted" title="Shortlisted" >
                            {key === 'shortlisted' && <Shortlisted />}
                        </Tab>
                        <Tab eventKey="whatsapp" title="WhatsApp">
                            {key === 'whatsapp' && <WhatsappShortlisted />}
                        </Tab>
                        <Tab eventKey="form" title="Form">
                            {key === 'form' && <FormShortlisted />}
                        </Tab>
                        <Tab eventKey="pool" title="Pool">
                            {key === 'pool' && <PoolShortlisted />}
                        </Tab>
                    </Tabs>
                </div>
            </div>
        </div>
    )
}
