import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { FaPlus } from "react-icons/fa6";
import { Modal } from 'react-bootstrap';
import { Accordion } from 'react-bootstrap';
import Select from "react-select";
import toast from 'react-hot-toast';
import axios from '../../../util/api';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../../redux/userSlice';

const gender = [
    { label: 'Male', value: 'male' },
    { label: 'Female', value: 'female' },
]


export default function ShortListedCandidates({ candidates, job_id }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [selectAll, setSelectAll] = useState(false);
    const [users, setUsers] = useState([]);
    const [modalShow, setModalShow] = useState(false);
    const [interviewerGender, setInterviewerGender] = useState(gender[0]);
    const [linkType, setLinkType] = useState('mcq');
    const [formData, setFormData] = useState({ no_of_mcq_questions: '', mcq_duration: '', ai_link_validity: '', ai_duration: '', mcq_link_validity: '' });
    const [formDataError, setFormDataError] = useState({
        no_of_mcq_questionsError: '',
        mcq_durationError: '',
        mcq_link_validityError: '',
        ai_link_validityError: '',
        ai_durationError: '',
    })

    const handleSelectAll = (e) => {
        setSelectAll(e.target.checked);
        let updatedList = [...users];
        setUsers(updatedList.map(item => ({ ...item, selected: e.target.checked })))
    }

    const handleSingleSelect = (e, index) => {
        let updatedList = [...users];
        updatedList[index].selected = e.target.checked;
        setSelectAll(updatedList.every(item => item.selected))
        setUsers(updatedList);
    }

    const handleFormChange = (e) => {
        let { value, name } = e.target;
        value = value.slice(0, 2);
        setFormData({ ...formData, [name]: value })

    }

    const handleKeyDown = (e) => {
        if (['-', '+', 'e', 'E'].includes(e.key)) {
            e.preventDefault();
        }
    }

    const validateForm = () => {
        const errors = {
            no_of_mcq_questionsError: '',
            mcq_durationError: '',
            mcq_link_validityError: '',
            ai_link_validityError: '',
            ai_durationError: '',
        }

        let isError = false;

        if (linkType === 'mcq') {
            if (!formData.no_of_mcq_questions || Number(formData.no_of_mcq_questions) === 0) {
                errors.no_of_mcq_questionsError = "Plese enter valid no of questions";
                isError = true;
            }

            if (!formData.mcq_duration || Number(formData.mcq_duration) === 0) {
                errors.mcq_durationError = 'Please enter valid duration';
                isError = true;
            }

            if (!formData.mcq_link_validity || Number(formData.mcq_link_validity) === 0) {
                errors.mcq_link_validityError = "Please enter valid link validity";
                isError = true;
            }
        } else if (linkType === 'ai') {
            if (!formData.ai_duration || Number(formData.ai_duration) === 0) {
                errors.ai_durationError = 'Please enter valid duration';
                isError = true;
            }

            if (!formData.ai_link_validity || Number(formData.ai_link_validity) === 0) {
                errors.ai_link_validityError = 'Please enter valid link validity';
                isError = true;
            }
        }

        setFormDataError(errors);
        return isError;
    }

    const handleSendLink = (e) => {
        e.preventDefault();
        if (!validateForm()) {
            let selectedEmails = users.filter(candidate => candidate.selected).map((data) => data.email_id);
            if (linkType === 'mcq') {
                let data = {
                    candidates: JSON.stringify(selectedEmails),
                    numberOfQuestions: formData.no_of_mcq_questions,
                    testDuration: formData.mcq_duration,
                    linkValidity: formData.mcq_link_validity * 60,
                    job_id: job_id
                };
                dispatch(setLoading(true));
                axios.post('/generate_link', data).then(res => {
                    console.log(res);
                    if (res.success) {
                        setModalShow(false);
                        toast.dismiss(); toast.success(res.message);
                    }else {
                        toast.dismiss(); 
                        toast.error(res.message);
                    }
                    dispatch(setLoading(false));
                }).catch(error => {
                    console.log(error);
                    dispatch(setLoading(false));
                })
            } else if (linkType === 'ai') {
                const data = {
                    candidates: JSON.stringify(selectedEmails),
                    job_id: job_id,
                    testDuration: formData.ai_duration,
                    linkValidity: formData.ai_link_validity * 60,
                    interviewerGender: interviewerGender.value
                }
                dispatch(setLoading(true));
                axios.post('/generate_interview_link', data).then((res) => {
                    if (res.success) {
                        toast.dismiss(); toast.success('Interview link send successfully')
                        setModalShow(false);
                    } else {
                        toast.dismiss(); toast.error('Error in generating link')
                    }
                    dispatch(setLoading(false))
                }).catch((error) => {
                    toast.dismiss(); toast.error('Internal error')
                    dispatch(setLoading(false))
                    console.log(error)
                })
            }
        }
    }

    useEffect(() => {
        setUsers(candidates.map(item => ({ ...item, selected: false })))
    }, [candidates])

    const openSendLink = () => {
        let selectedEmails = users.filter(candidate => candidate.selected);
        if (selectedEmails.length > 0) {
            setModalShow(true);
        } else {
            toast.dismiss(); toast.error('Select atleast One Employye')
        }
    }

    const handleReject = () => {
        let selectedIds = users.filter(candidate => candidate.selected).map((data) => data.id);
        if (selectedIds.length > 0) {
            dispatch(setLoading(true));
            axios.post('/send_rejection_emails', { candidate_ids: JSON.stringify(selectedIds) }).then(res => {
                console.log(res);
                dispatch(setLoading(false));
                if (res.success) {
                    toast.dismiss();
                    toast.success('Mail sent successfully');
                }
            }).catch(error => {
                dispatch(setLoading(false));
                toast.dismiss();
                toast.error('Something went wrong');
            })
        } else {
            toast.dismiss();
            toast.error('Select Candidate');
        }
    }

    return (
        <div className='shortedlisted-candidates-wrapper'>
            <div className="user-table">
                <Table responsive="md">
                    <thead>
                        <tr>
                            <th className='text-center'><input type="checkbox" checked={selectAll} onChange={handleSelectAll} /></th>
                            <th className='text-center'>S.No</th>
                            <th className='text-center'>Name</th>
                            <th className='text-center'>Email</th>
                            <th className='text-center'>Contact Number</th>
                            <th className='text-center'>Resume Score</th>
                        </tr>
                    </thead>
                    <tbody>
                        {users.map((item, index) => {
                            return (
                                <tr key={index}>
                                    <td className='text-center'><input type="checkbox" onChange={(e) => handleSingleSelect(e, index)} checked={item.selected} /></td>
                                    <td className='text-center'>{index + 1}</td>
                                    <td className='text-center' style={{ color: '#1875fe', textDecoration: 'underline', cursor: 'pointer' }} onClick={() => navigate('/candidateSummary', { state: { userId: item.id, job_id } })}>{item.username}</td>
                                    <td className='text-center'>{item.email_id}</td>
                                    <td className='text-center'>{item.whatsapp_number}</td>
                                    <td className='text-center'>{item.resume_score}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            </div>
            <div className="bottom">
                <button className='add-candidate' onClick={() => navigate('/corporateDashboard/jobs/addResume', { state: { data: { job_id } } })}><FaPlus />Add Candidates</button>
                <button onClick={openSendLink}>Send Link</button>
                <button onClick={handleReject}>Rejected Candidates</button>
            </div>

            <Modal
                className='send_link_modal'
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={modalShow}
                onHide={() => setModalShow(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Select Phase To Send Link</Modal.Title>
                </Modal.Header>
                <Modal.Body className='d-flex flex-column gap-4'>
                    <Accordion defaultActiveKey="0">
                        <form>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header onClick={() => { setLinkType('mcq') }}>
                                    <input type='radio' id='mcqtest' checked={linkType === 'mcq'} name='interviewType'></input>
                                    <label htmlFor='mcqtest'>MCQ Test</label>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <div className='form-fields'>
                                        <div className='no-of-questions field'>
                                            <label htmlFor='no-of-mcq-questions'>No of Questions<span>*</span></label>
                                            <input type='number' onChange={handleFormChange} min={1} max={99} id='no-of-mcq-questions' name='no_of_mcq_questions' value={formData.no_of_mcq_questions} onKeyDown={handleKeyDown}></input>
                                            <span className='form-error'>{formDataError.no_of_mcq_questionsError}</span>
                                        </div>
                                        <div className='mcq-duration field'>
                                            <div>
                                                <label htmlFor='mcq_duration'>Duration (In Minutes)<span>*</span></label>
                                                <input type='number' onChange={handleFormChange} min={1} max={99} id='mcq-duration' name='mcq_duration' value={formData.mcq_duration} onKeyDown={handleKeyDown}></input>
                                                <span className='form-error'>{formDataError.mcq_durationError}</span>
                                            </div>
                                        </div>
                                        <div className=' field'>
                                            <label htmlFor='mcq_link_validity'>Link Validity (In Hours)<span>*</span></label>
                                            <input type='number' onChange={handleFormChange} min={1} max={99} id='mcq_link_validity' name='mcq_link_validity' value={formData.mcq_link_validity} onKeyDown={handleKeyDown}></input>
                                            <span className='form-error'>{formDataError.mcq_link_validityError}</span>
                                        </div>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header onClick={() => setLinkType('ai')}>
                                    <input type='radio' id='ai_test' checked={linkType === 'ai'} name='interviewType'></input>
                                    <label htmlFor='ai_test'>AI Interview</label>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <div className='form-fields'>
                                        <div className='mcq-duration field'>
                                            <div>
                                                <label htmlFor='ai_duration'>Duration<span>*</span></label>
                                                <input type='number' onChange={handleFormChange} id='ai_duration' name='ai_duration' value={formData.ai_duration} onKeyDown={handleKeyDown}></input>
                                                <span className='form-error'>{formDataError.ai_durationError}</span>
                                            </div>
                                        </div>
                                        <div className=' field'>
                                            <label htmlFor='ai_link_validity'>Link Validity(In Hours)<span>*</span></label>
                                            <input type='number' onChange={handleFormChange} min={1} max={99} id='ai_link_validity' name='ai_link_validity' value={formData.ai_link_validity} onKeyDown={handleKeyDown}></input>
                                            <span className='form-error'>{formDataError.ai_link_validityError}</span>
                                        </div>
                                        <div className=' field'>
                                            <label htmlFor='ai_link_validity'>Gender<span>*</span></label>
                                            <Select
                                                value={interviewerGender}
                                                onChange={setInterviewerGender}
                                                options={gender}
                                            />
                                        </div>
                                    </div>
                                </Accordion.Body>

                            </Accordion.Item>
                        </form>
                    </Accordion>
                    <div className='send-test-link-btn'>
                        <button className='p-2' onClick={handleSendLink}>Send Link</button>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}
