import React from 'react'
import GaugeChart from './GaugeChart'
import ReactPlayer from 'react-player'

function AiInterviewSummary({ aiSummaryData }) {
  return (
    <div className='ai-summary-wrapper'>
      <div className='ai-test-score'>
        {/* <h2>AI Interview Score</h2> */}
        <div className='charts'>
          <div className='left'>
            <GaugeChart data={aiSummaryData?.interview_score} />
          </div>
          <div className='right'>
            <h2>AI Interview Recording</h2>
            <ReactPlayer
              url={aiSummaryData?.interview_link}
              controls={true}
              width='100%'
              height='250px'
            />
          </div>
        </div>
      </div>
      <div className='remarks'>
        <h2>Remarks</h2>
        <p>{aiSummaryData?.remark}</p>
      </div>
      <div className='ai-test-question'>
        <h2>AI Interview Questions</h2>
        <div className='questions-container'>
          {aiSummaryData?.interview_data?.map((question, index) => {
            return (
              <div key={index} className='question'>
                <h2 className='question-heading'>{index + 1}. {question.question}</h2>
                <p className='answer'>Ans. {question.answer}</p>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default AiInterviewSummary