import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import axios from '../util/api';
import { useDispatch } from 'react-redux';
import { setLoading } from '../redux/userSlice';
import toast from 'react-hot-toast';
import Pagination from './Pagination';

function CorporateTable() {
    const [corporateModalShow, setCorporateModalShow] = useState(false);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const dispatch = useDispatch();
    const [OrganizationData, setOrganizationData] = useState([]);
    const [corporateSearchInput, setCorporateSearchInput] = useState('');
    const [orgFormData, setOrgFormData] = useState({
        org_name: '',
        db_name: '',
        db_username: '',
        org_domain: '',
        db_password: '',
        org_admin_name: '',
        org_admin_email: ''
    })

    const [orgFormDataError, setOrgFormDataError] = useState({
        org_nameError: '',
        db_nameError: '',
        db_usernameError: '',
        org_domainError: '',
        db_passwordError: '',
        org_admin_nameError: '',
        org_admin_emailError: '',
        fieldMatchError: ''
    })

    useEffect(() => {
        const data = {
            page: page,
            search_query: corporateSearchInput
        }
        dispatch(setLoading(true));
        axios.post('/organizations_pagination', data).then((res) => {
            if (res.success) {
                setOrganizationData(res.organizations);
                setTotalPages(res.total_pages);
            } else {
                toast.dismiss();
                toast.error('something went wrong')
            }
            dispatch(setLoading(false));
        }).catch((error) => {
            dispatch(setLoading(false));
            console.log(error)
        })
    }, [page, corporateSearchInput])

    const handleOrgFormChange = (e) => {
        const { name, value } = e.target;
        setOrgFormData((prevData) => {
            return ({
                ...prevData,
                [name]: value
            })
        })
    }

    const validateOrgForm = () => {
        let errors = {
            org_nameError: '',
            db_nameError: '',
            db_usernameError: '',
            org_domainError: '',
            db_passwordError: '',
            org_admin_nameError: '',
            org_admin_emailError: '',
            fieldMatchError: ''
        }

        let { org_name, db_name, db_username, org_domain, db_password, org_admin_name, org_admin_email } = orgFormData;
        var isError = false;
        let org_admin_email_domain = orgFormData?.org_admin_email?.split('@')[1]?.split('.')[0];
        if (!org_name.length) {
            errors.org_nameError = "Plese enter org_name";
            isError = true;
        }

        if (!db_name.length) {
            errors.db_nameError = 'Please enter db_name';
            isError = true;
        }

        if (!db_username.length) {
            errors.db_usernameError = 'Please enter db_username';
            isError = true;
        }

        if (!org_domain.length) {
            errors.org_domainError = 'Please enter org_domain';
            isError = true;
        }

        if (db_password.length < 4) {
            errors.db_passwordError = 'Please enter valid password';
            isError = true;
        }

        if (!org_admin_name.length) {
            errors.org_admin_nameError = 'Please enter org_admin_name';
            isError = true;
        }

        if (!org_admin_email.match(/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i)) {
            errors.org_admin_emailError = 'Please enter valid org_admin_email';
            isError = true;
        }

        if ((org_admin_email_domain !== db_name) && org_name.length && db_name.length && org_admin_email_domain.length) {
            errors.fieldMatchError = 'org_name, db_name and org_admin_email domain should be same'
            isError = true;
        }

        setOrgFormDataError(errors)
        return isError;
    }

    const handleOrgFormSubmit = (e) => {
        e.preventDefault();
        if (!validateOrgForm()) {
            dispatch(setLoading(true))
            axios.post('/create_organization', orgFormData).then((res) => {
                if (res.success) {
                    setCorporateModalShow(false);
                    setOrgFormData({});
                    setOrganizationData(prevOrgData => [
                        ...prevOrgData,
                        {
                            id: res.data.id,
                            org_name: res.data.org_name,
                            org_admin_name: res.data.org_admin_name,
                            org_admin_email: res.data.org_admin_email,
                            db_name: res.data.db_name,
                            db_username: res.data.db_username,
                            org_domain: res.data.org_domain
                        }
                    ]);
                } else {
                    toast.dismiss();
                    toast.error(res.message);
                }
                dispatch(setLoading(false));
            }).catch((err) => {
                dispatch(setLoading(false))
                console.log(err)
            })
        }
    }


    return (
        <>
            <div className='corporate-table-wrapper'>
                <div className='add-corporate-with-search'>
                    <div className='corporate-search-input'>
                        <input type='text' id='corporate_search' onChange={(e) => setCorporateSearchInput(e.target.value)} placeholder='search'></input>
                    </div>
                    <button onClick={() => setCorporateModalShow(true)}>Add Corporate</button>
                </div>
                <h4>Corporate's</h4>
                <table className='user-table'>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Organizaition Name</th>
                            <th>Organization Admin Name</th>
                            <th>Organization Admin Email</th>
                            <th>DB Name</th>
                            <th>DB UserName</th>
                            <th>Org Domain</th>
                        </tr>
                    </thead>
                    <tbody>
                        {OrganizationData?.map((data, index) => {
                            return (
                                <tr key={index}>
                                    <td>{data.id}</td>
                                    <td>{data.org_name}</td>
                                    <td>{data.org_admin_name}</td>
                                    <td>{data.org_admin_email}</td>
                                    <td>{data.db_name}</td>
                                    <td>{data.db_username}</td>
                                    <td>{data.org_domain}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>

                <Pagination page={page} setPage={setPage} total={totalPages} />
            </div>
            <Modal
                className='corporate_add_modal'
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={corporateModalShow}
                onHide={() => setCorporateModalShow(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Add Corporate</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleOrgFormSubmit}>
                        <div className='org_name field'>
                            <label htmlFor='org_name'>Organizaition Name</label>
                            <input type='text' id='org_name' name='org_name' onChange={handleOrgFormChange}></input>
                            <p className='form-error'>{orgFormDataError.org_nameError}</p>
                        </div>
                        <div className='org_name field'>
                            <label htmlFor='db_name'>DB Name</label>
                            <input type='text' id='db_name' name='db_name' onChange={handleOrgFormChange}></input>
                            <p className='form-error'>{orgFormDataError.db_nameError}</p>
                        </div>
                        <div className='db_username field'>
                            <label htmlFor='db_username'>DB Username</label>
                            <input type='text' id='db_username' name='db_username' onChange={handleOrgFormChange}></input>
                            <p className='form-error'>{orgFormDataError.db_usernameError}</p>
                        </div>
                        <div className='org_domain field'>
                            <label htmlFor='org_domain'>Organizaition Domain</label>
                            <input type='text' id='org_domain' name='org_domain' onChange={handleOrgFormChange}></input>
                            <p className='form-error'>{orgFormDataError.org_domainError}</p>
                        </div>
                        <div className='db_password field'>
                            <label htmlFor='db_password'>DB Password</label>
                            <input type='text' id='db_password' name='db_password' onChange={handleOrgFormChange}></input>
                            <p className='form-error'>{orgFormDataError.db_passwordError}</p>
                        </div>
                        <div className='org_admin_name field'>
                            <label htmlFor='org_admin_name'>Organizaition Admin Name</label>
                            <input type='text' id='org_admin_name' name='org_admin_name' onChange={handleOrgFormChange}></input>
                            <p className='form-error'>{orgFormDataError.org_admin_nameError}</p>

                        </div>
                        <div className='org_admin_email field'>
                            <label htmlFor='org_admin_email'>Organizaition Admin Email</label>
                            <input type='email' id='org_admin_email' name='org_admin_email' onChange={handleOrgFormChange}></input>
                            <p className='form-error'>{orgFormDataError.org_admin_emailError}</p>
                        </div>
                        <p className='form-error text-danger'>{orgFormDataError.fieldMatchError}</p>
                        <div className='submitBtn'>
                            <button className='submit-btn'>Submit</button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default CorporateTable;