import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { FaPlus } from "react-icons/fa6";
import { Modal } from 'react-bootstrap';
import { Accordion } from 'react-bootstrap';
import Select from "react-select";
import toast from 'react-hot-toast';
import axios from '../../../util/api';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../../redux/userSlice';

const testDurationOptions = [
    { value: "10", label: "10 min" },
    { value: "15", label: "15 min" },
    { value: "20", label: "20 min" },
    { value: "25", label: "25 min" },
    { value: "30", label: "30 min" },
    { value: "35", label: "35 min" },
    { value: "40", label: "40 min" },
    { value: "45", label: "45 min" },
    { value: "50", label: "50 min" },
];

export default function AvatarCandidates({ candidates, job_id }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [selectAll, setSelectAll] = useState(false);
    const [users, setUsers] = useState([]);
    const [modalShow, setModalShow] = useState(false);
    const [testDuration, setTestDuration] = useState(testDurationOptions[0]);
    const [mainInterviewerEmail, setMainInterviewerEmail] = useState('');
    const [mainInterviewSelectedEmail, setMainInterviewSelectedEmail] = useState([{ candidateEmail: '', timeSlot: '' }]);
    const [mainInterviewFormError, setMainInterviewFormError] = useState({
        mainInterviewEmailError: '',
        mainInterviewSelectedEmailError: ''
    })
    const [minDateTime, setMinDateTime] = useState('');

    const handleSelectAll = (e) => {
        setSelectAll(e.target.checked);
        let updatedList = [...users];
        setUsers(updatedList.map(item => ({ ...item, selected: e.target.checked })))
    }
    const handleSingleSelect = (e, index) => {
        let updatedList = [...users];
        updatedList[index].selected = e.target.checked;
        setSelectAll(updatedList.every(item => item.selected))
        setUsers(updatedList);
    }

    const validateForm = () => {
        const errors = {
            mainInterviewEmailError: '',
            mainInterviewSelectedEmailError: ''
        }
        let isError = false;

        if (!mainInterviewerEmail.trim()) {
            errors.mainInterviewEmailError = 'Please enter Interviewer email';
            isError = true;
        }

        if (mainInterviewSelectedEmail.some(selectedEmail => selectedEmail.timeSlot === '')) {
            errors.mainInterviewSelectedEmailError = 'Please select all time slots';
            isError = true;
        }

        setMainInterviewFormError(errors);
        return isError;
    }

    const handleMainInterviewLink = (e) => {
        e.preventDefault();
        if (!validateForm()) {
            const data = {
                testDuration: testDuration.value,
                mainInterviewerEmail,
                candidates: JSON.stringify(mainInterviewSelectedEmail),
                job_id
            }

            dispatch(setLoading(true))
            axios.post('/send_appointment_emails', data).then((res) => {
                if (res.success) {
                    setModalShow(false);
                    toast.dismiss();toast.success('Main Interview link Sent Successfully');
                } else {
                    toast.dismiss();toast.error(res.message)
                }
                dispatch(setLoading(false))
            }).catch((error) => {
                console.log(error);
                dispatch(setLoading(false));
                toast.dismiss();toast.error('Something went wrong');
            })
        }
    }

    const openSendLink = () => {
        let selectedEmails = users.filter(candidate => candidate.selected);
        if (selectedEmails.length > 0) {
            setModalShow(true);
            setMainInterviewSelectedEmail(selectedEmails.map((item) => ({ candidateEmail: item.email_id, timeSlot: '' })))
        } else {
            toast.dismiss();toast.error('Select atleast One Employye')
        }
    }

    const handleDateChange = (e, index) => {
        const newData = [...mainInterviewSelectedEmail];
        newData[index].timeSlot = e.target.value;
        setMainInterviewSelectedEmail(newData);
    }

    useEffect(() => {
        setUsers(candidates.map(item => ({ ...item, selected: false })))
    }, [candidates])

    const handleReject = () => {
        let selectedIds = users.filter(candidate => candidate.selected).map((data) => data.id);
        if (selectedIds.length > 0) {
            dispatch(setLoading(true));
            axios.post('/send_rejection_emails', { candidate_ids: JSON.stringify(selectedIds) }).then(res => {
                console.log(res);
                dispatch(setLoading(false));
                if (res.success) {
                    toast.dismiss();
                    toast.success('Mail sent successfully');
                }
            }).catch(error => {
                dispatch(setLoading(false));
                toast.dismiss();
                toast.error('Something went wrong');
            })
        } else {
            toast.dismiss();
            toast.error('Select Candidate');
        }
    }
    return (
        <div className='avatar-candidates-wrapper'>
            <div className="user-table">
                <Table responsive="md">
                    <thead>
                        <tr>
                            <th className='text-center'><input type="checkbox" checked={selectAll} onChange={handleSelectAll} /></th>
                            <th className='text-center'>S.No</th>
                            <th className='text-center'>Name</th>
                            <th className='text-center'>Email</th>
                            <th className='text-center'>Contact Number</th>
                            <th className='text-center'>MCQ Score</th>
                            <th className='text-center'>AI Interview Score</th>
                        </tr>
                    </thead>
                    <tbody>
                        {users.map((item, index) => {
                            return (
                                <tr key={index}>
                                    <td className='text-center'><input type="checkbox" onChange={(e) => handleSingleSelect(e, index)} checked={item.selected} /></td>
                                    <td className='text-center'>{index + 1}</td>
                                    <td className='text-center' style={{color: '#1875fe', textDecoration: 'underline', cursor: 'pointer'}} onClick={() => navigate('/candidateSummary', { state : {userId: item.id, job_id}})}>{item.username}</td>
                                    <td className='text-center'>{item.email_id}</td>
                                    <td className='text-center'>{item.whatsapp_number}</td>
                                    <td className='text-center'>{item.mcq_score}</td>
                                    <td className='text-center'>{item.interview_score}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            </div>
            <div className="bottom">
                <button onClick={openSendLink}>Send Main Interview Link</button>
                <button onClick={handleReject}>Rejected Candidates</button>
            </div>

            <Modal
                className='send-main-interview-modal'
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={modalShow}
                onHide={() => setModalShow(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Send Main Interview Link</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleMainInterviewLink} className='form-fields'>
                        <div className='test-duration field'>
                            <label htmlFor="testDuration">Test Duration</label>
                            <Select
                                value={testDuration}
                                onChange={setTestDuration}
                                options={testDurationOptions}
                                name='testDuration'
                            />
                        </div>
                        <div className='interviewer_email field'>
                            <label htmlFor='interviewerEmail'>Interviewer Email</label>
                            <input type='email' name='interviewerEmail' id='interviewerEmail' value={mainInterviewerEmail} onChange={(e) => setMainInterviewerEmail(e.target.value)}></input>
                            <p style={{ color: 'red', fontSize: '14px' }} className='formError'>{mainInterviewFormError.mainInterviewEmailError}</p>
                        </div>
                        <table>
                            <thead>
                                <th>Selected Candidates</th>
                                <th>Select Time Slot</th>
                            </thead>
                            <tbody>
                                {mainInterviewSelectedEmail.map((email, index) => {
                                    return (
                                        <tr key={index}>
                                            <td className='email'>{email?.candidateEmail}</td>
                                            <td className='timeSlot'><input
                                                type='datetime-local'
                                                id='dateAndTime'
                                                name='dateAndTime'
                                                onChange={(e) => handleDateChange(e, index)}
                                                min={minDateTime}
                                                value={mainInterviewSelectedEmail.timeSlot}
                                            ></input></td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                        <span className='form-error'>{mainInterviewFormError.mainInterviewSelectedEmailError}</span>

                        <div className='send-test-link-btn'>
                            <button className='p-2'>Send Link</button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </div>
    )
}
