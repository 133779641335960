import React, { useEffect, useState } from 'react'
import { Table } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';
// import { FaPlus } from "react-icons/fa6";

export default function FinalCandidates({ candidates, job_id }) {
    const navigate = useNavigate();
    const [selectAll, setSelectAll] = useState(false);
    const [users, setUsers] = useState([]);
    const handleSelectAll = (e) => {
        setSelectAll(e.target.checked);
        let updatedList = [...users];
        setUsers(updatedList.map(item => ({ ...item, selected: e.target.checked })))
    }
    const handleSingleSelect = (e, index) => {
        let updatedList = [...users];
        updatedList[index].selected = e.target.checked;
        setSelectAll(updatedList.every(item => item.selected))
        setUsers(updatedList);
    }

    useEffect(() => {
        setUsers(candidates.map(item => ({ ...item, selected: false })))
    }, [candidates])
    return (
        <div className='final-candidates-wrapper'>
             <div className="user-table">
                <Table responsive="md">
                    <thead>
                        <tr>
                            <th className='text-center'><input type="checkbox" checked={selectAll} onChange={handleSelectAll} /></th>
                            <th className='text-center'>S.No</th>
                            <th className='text-center'>Name</th>
                            <th className='text-center'>Email</th>
                            <th className='text-center'>Contact Number</th>
                        </tr>
                    </thead>
                    <tbody>
                        {users.map((item, index) => {
                            return (
                                <tr key={index}>
                                    <td className='text-center'><input type="checkbox" onChange={(e) => handleSingleSelect(e, index)} checked={item.selected} /></td>
                                    <td>{index + 1}</td>
                                    <td className='text-center' style={{ color: '#1875fe', textDecoration: 'underline', cursor: 'pointer' }} onClick={() => navigate('/candidateSummary', { state: { userId: item.id, job_id } })}>{item.username}</td>
                                    <td className='text-center'>{item.email_id}</td>
                                    <td className='text-center'>{item.whatsapp_number}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            </div>
        </div>
    )
}
