import React, { useEffect, useState } from 'react';
import { PiHandbagSimple } from "react-icons/pi";
import { GoArrowUpRight } from "react-icons/go";
import { IoCalendarNumberOutline } from "react-icons/io5";
import { ImAlarm } from "react-icons/im";
import DoughnutChart from '../../components/DoughnutChart';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../redux/userSlice';
import axios from '../../util/api';
import dayjs from 'dayjs';
import toast from 'react-hot-toast';
import Header from '../../components/Header';
import tokenService from '../../util/TokenService';

function Dashboard() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = tokenService.getUser();

  const [data, setData] = useState({ open_positions: 0, ai_interview: 0, hr_interview: 0, offer_letter: 0, not_interested_candidates: 0 });
  const [upcomingInterviews, setUpcomingInterviews] = useState([]);
  const [upcomingInterviewsCount, setUpcomingInterviewsCount] = useState(0);
  const [myTeam, setMyTeam] = useState([])
  const [recentJobs, setRecentJobs] = useState([])


  useEffect(() => {
    dispatch(setLoading(true));
    axios.post('/dashboard').then(res => {
      if (res.success) {
        setData({
          open_positions: res.job_status_1_count,
          ai_interview: res.avatar_interview_scheduled,
          hr_interview: res.main_meet_scheduled,
          offer_letter: res.offer_letter_accepted_count,
          not_interested_candidates: res.not_interested_candidates_mcq
        });
        setUpcomingInterviews(res.upcoming_meetings);
        setUpcomingInterviewsCount(res.upcoming_meet_count);
        setRecentJobs(res.recent_jobs);
      }
      dispatch(setLoading(false));
    }).catch(error => {
      console.log(error);
      dispatch(setLoading(false));
    })

    axios.post('/get_corporate_users').then((res) => {
      if (res.success) {
        setMyTeam(res.users);
      }
      dispatch(setLoading(false))
    }).catch((error) => {
      console.log(error);
      dispatch(setLoading(false));
    })
  }, [])

  const getInitials = (name) => {
    const names = name.split(' ');
    let initials = names[0].charAt(0).toUpperCase();
    if (names.length > 1) {
      initials += names[1].charAt(0).toUpperCase();
    }
    return initials;
  }


  return (
    <div className='dashboard-wrapper'>
      <Header heading={`Welcome ${user.first_name} ${user.last_name}`}/>
      <div className="second-section">
        <div className="card-details">
          <span className='number'>{data.open_positions}</span>
          <span className='text'>Open Positions</span>
        </div>
        <div className="card-details">
          <span className='number'>{data.ai_interview}</span>
          <span className='text'>AI Interview Scheduled</span>
        </div>
        <div className="card-details">
          <span className='number'>{data.hr_interview}</span>
          <span className='text'>HR Interview Scheduled</span>
        </div>
        <div className="card-details">
          <span className='number'>{data.offer_letter}</span>
          <span className='text'>Offer Letters Sent</span>
        </div>
        <div className="card-details">
          <span className='number'>{data.not_interested_candidates}</span>
          <span className='text'>Not Interested Candidates</span>
        </div>
      </div>
      <div className="third-section">
        <div className="job-posting">
          <div className="title">
            <h2><PiHandbagSimple />Job Postings</h2>
          </div>
          <div className="job-cards">
            {recentJobs.map((item, index) => {
              return (
                <div key={index} className="job-card">
                  <div className="icon">
                    <PiHandbagSimple />
                  </div>
                  <div className="info">
                    <div className="card-title"><span>Job Title: </span>{item.title}</div>
                    <div className="location">Location: {item.location}</div>
                    <div className="application">Applications: {item.candidate_count}</div>
                  </div>
                </div>
              )
            })}
          </div>
          <div className="bottom">
            <button onClick={() => navigate('/corporateDashboard/jobs')}>View All <GoArrowUpRight /></button>
          </div>
        </div>
        <div className="monthly-overview">
          <div className='heading'>
            <h2><IoCalendarNumberOutline />Monthly Overview</h2>
          </div>
          <div className="monthly-overview-chart">
            <DoughnutChart data={data} />
          </div>
        </div>
      </div>
      <div className="fourth-section">
        <div className='my-team-wrapper'>
          <div className="title">
            <h2 className='heading'><PiHandbagSimple />My Team</h2>
          </div>
          <div className='team-cards'>
            {user.role === 'corporateAdmin' && (
              <div className="team-card add-new-member" onClick={() => navigate('/corporateDashboard/teams/addTeam')}>
                <div className='image'>+</div>
                <div className="add-new">Add New</div>
                <div className='team-member'>Team Member</div>
              </div>
            )}
            {myTeam.slice(0, 5).map((item, index) => {
              return (
                <div key={index} className="team-card">
                  <div className='image'>{getInitials(item.username)}</div>
                  <div className="name">{item.username}</div>
                  <div className="designation">{item.designation}</div>
                </div>
              )
            })}
          </div>
          <div className="bottom">
            <button onClick={() => navigate('/corporateDashboard/teams')}>view all <GoArrowUpRight /></button>
          </div>
        </div>
        <div className='upcoming-interviews-wrapper'>
          <div className='heading'>
            <h2><ImAlarm />Upcoming Interviews</h2>
          </div>
          <div className='cards'>
            {upcomingInterviews.map((item, index) => {
              return (
                <div key={index} className="card add-new-member">
                  <div className='image'>{getInitials(item.username)}</div>
                  <div className="name">{item.username}</div>
                  <div className='time-slot'>{dayjs(item.time).format('DD-MM-YYYY')}</div>
                  {/* <div className='time-slot'>{dayjs(item.time).format('h:mm a')}</div> */}
                </div>
              )
            })}

            {upcomingInterviewsCount > 2 && (
              <div className="card total-number">
                <div className='image'>+{upcomingInterviewsCount - 1}</div>
              </div>
            )}

          </div>
          <div className="bottom">
            <button onClick={() => navigate('/corporateDashboard/jobs/upcomingInterviews')}>view all <GoArrowUpRight /></button>
          </div>

        </div>
      </div>
    </div>
  )
}

export default Dashboard