import React, { useEffect, useState } from 'react';
import Select from "react-select";
import ShortListedCandidates from '../pages/corporate/candidateList/ShortListedCandidates';
import MCQCandidates from '../pages/corporate/candidateList/MCQCandidates';
import AvatarCandidates from '../pages/corporate/candidateList/AvatarCandidates';
import MainInterviewCandicates from '../pages/corporate/candidateList/MainInterviewCandicates';
import FinalCandidates from '../pages/corporate/candidateList/FinalCandidates';
import { useDispatch } from 'react-redux';
import axios from '../util/api';
import { setLoading } from '../redux/userSlice';
import { useLocation } from 'react-router-dom';

const roundOptions = [
    { value: "shortlisted", label: "Shortlisted" },
    { value: "mcq", label: "MCQ" },
    { value: "ai_interview", label: "AI Interview" },
    { value: "main_interview", label: "Final Interview" },
    { value: "final_candidates", label: "Offer Letter Sent" },
];

function Shortlisted() {
    const dispatch = useDispatch();
    const location = useLocation();
    const job_id = location?.state ? location.state?.job_id : null;
    const [users, setUsers] = useState([]);
    const [tableType, setTableType] = useState(roundOptions[0]);

    const handleTableTypeChange = (e) => {
        setTableType(e);
    }

    useEffect(() => {
        if (job_id) {
            dispatch(setLoading(true));
            const data = {
                job_id: job_id,
                table: tableType.value
            }
            axios.post('/get_filtered_candidates', data).then(res => {
                if (res.success) {
                    setUsers(res.candidates);
                }
                dispatch(setLoading(false));
            }).catch(error => {
                console.log(error);
                dispatch(setLoading(false));
            })
        }
    }, [tableType])
    return (
        <div className='shortlisted-wrapper'>
            <div className="filter">
                <Select
                    value={tableType}
                    options={roundOptions}
                    onChange={handleTableTypeChange}
                    id='round-type'
                />
            </div>
            <div className="table-content">
                {tableType.value === 'shortlisted' && <ShortListedCandidates candidates={users} job_id={job_id} />}
                {tableType.value === 'mcq' && <MCQCandidates candidates={users} job_id={job_id} />}
                {tableType.value === 'ai_interview' && <AvatarCandidates candidates={users} job_id={job_id} />}
                {tableType.value === 'main_interview' && <MainInterviewCandicates candidates={users} job_id={job_id} />}
                {tableType.value === 'final_candidates' && <FinalCandidates candidates={users} job_id={job_id} />}
            </div>
        </div>
    )
}

export default Shortlisted