import React, { useRef, useState } from 'react';
import PostTemplate from '../../components/PostTemplate';
import { toPng } from 'html-to-image';
import '../../scss/CreateJob.scss';
import { FiPlusCircle } from "react-icons/fi";
import { FiMinusCircle } from "react-icons/fi";
import axios from '../../util/api';
import toast from 'react-hot-toast';
import { setLoading } from '../../redux/userSlice';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Select from "react-select";
import TokenService from '../../util/TokenService';
import Header from '../../components/Header';
import { IoIosAddCircleOutline } from "react-icons/io";
import MultiRangeSlider from "multi-range-slider-react";

const jobTypeOptions = [
    { value: 'Full-Time', label: 'Full-Time' },
    { value: 'Part-Time', label: 'Part-Time' }
]

const remoteOptions = [
    { value: 'Yes', label: 'Yes' },
    { value: 'No', label: 'No' }
]

function CreateJob() {
    const componentRef = useRef();
    const fileInputRef = useRef(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [primarySkills, setPrimarySkills] = useState(['']);
    const [secondarySkills, setSecondarySkills] = useState(['']);
    const [jobResponsibilities, setJobResponsibilities] = useState(['']);
    const [additionalRequirements, setAdditionalRequirements] = useState(['']);
    const [jobType, setJobType] = useState(jobTypeOptions[0])
    const [remoteOption, setRemoteOption] = useState(remoteOptions[0])
    const [companyLogo, setCompanyLogo] = useState(null);
    const [minSalaryValue, setMinSalaryValue] = useState(0);
    const [maxSalaryValue, setMaxSalaryValue] = useState(10);
    const [formData, setFormData] = useState({
        jobTitle: '',
        location: '',
        noofOpening: '1',
        experience: '0',
        companyEmail: '',
        companyName: '',
        jobDescription: ''
    });

    const [formDataErrors, setFormDataErrors] = useState({
        jobTitleError: '',
        locationError: '',
        noofOpeningError: '',
        experienceError: '',
        additionalDetailsError: '',
        companyEmailError: '',
        companyNameError: '',
        jobDescriptionError: '',
        logoError: '',
        jobDescripitionError: '',
        designationError: '',
        qualificationError: '',
        primarySkillsError: '',
        secondarySkillsError: ''
    })

    const [showPostTemplate, setShowPostTemplate] = useState(false);

    const user = TokenService.getUser();
    let email = user?.email;

    const handleSalaryInput = (e) => {
        setMinSalaryValue(e.minValue);
        setMaxSalaryValue(e.maxValue)
    }

    const handleDownloadImage = () => {
        if (componentRef.current === null) {
            return;
        }

        toPng(componentRef.current)
            .then((dataUrl) => {
                const link = document.createElement('a');
                link.href = dataUrl;
                link.download = 'post-template.png';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
            .catch((error) => {
                console.error('Error exporting image:', error);
            });
    };

    const handleFormChange = (e) => {
        let { value, name } = e.target;
        if (name === 'experience') {
            value = value.slice(0, 2);
        }
        setFormData({ ...formData, [name]: value })
    }

    const handleExperienceKeyDown = (e) => {
        if (['-', '+', 'e', 'E'].includes(e.key)) {
            e.preventDefault();
        }
    }

    const handleLogoChange = (e) => {
        console.log(e.target.files);
        const file = e.target.files[0];

        if (file && file.type.startsWith('image/')) {
            const imageUrl = URL.createObjectURL(file);
            setCompanyLogo(imageUrl);
        }

    }

    const validateForm = () => {
        let errors = {
            jobTitleError: '',
            locationError: '',
            noofOpeningError: '',
            experienceError: '',
            additionalDetailsError: '',
            companyEmailError: '',
            companyNameError: '',
            jobDescriptionError: '',
            logoError: '',
            jobDescripitionError: '',
            designationError: '',
            qualificationError: '',
            primarySkillsError: '',
            secondarySkillsError: '',
        };
        let isError = false;

        if (!formData.jobTitle.trim()) {
            errors.jobTitleError = 'Job title is required';
            isError = true;
        }

        if (!formData.location.trim()) {
            errors.locationError = 'Location is required';
            isError = true;
        }

        if (!formData.noofOpening || formData.noofOpening < 1) {
            errors.noofOpeningError = 'Number of openings must be at least 1';
            isError = true;
        }

        if (!formData.experience || isNaN(formData.experience) || formData.experience < 0) {
            errors.experienceError = 'Valid experience is required';
            isError = true;
        }

        if (!formData.companyName.trim()) {
            errors.companyNameError = 'Company name is required';
            isError = true;
        }

        if (!formData.companyEmail.trim() || !/\S+@\S+\.\S+/.test(formData.companyEmail)) {
            errors.companyEmailError = 'Valid company email is required';
            isError = true;
        }

        // if (!formData.jobDescription.trim()) {
        //     errors.jobDescription = 'Job description is required';
        //     isError = true;
        // }

        if (!companyLogo) {
            errors.logoError = 'Please select a company logo';
            isError = true;
        }

        if (primarySkills.some(skill => skill.trim() === '')) {
            errors.primarySkillsError = 'Please enter all primary skills';
            isError = true;
        }

        if (secondarySkills.some(skill => skill.trim() === '')) {
            errors.secondarySkillsError = 'Please enter all secondary skills';
            isError = true;
        }

        // if (jobResponsibilities.some(responsibility => responsibility.trim() === '')) {
        //     errors.jobResponsibilityError = 'Please enter all job responsibilities';
        //     isError = true;
        // }

        setFormDataErrors(errors);
        return isError;
    }

    const handlePrimarySkillChange = (e, index) => {
        const newKeySkills = [...primarySkills];
        newKeySkills[index] = e.target.value;
        setPrimarySkills(newKeySkills);
    }

    const handlePrimarySkillIncrement = () => {
        if (primarySkills.length < 6) {
            setPrimarySkills([...primarySkills, ''])
        }
    }

    const handlePrimarySkillDecrement = (index) => {
        if (primarySkills.length > 1) {
            const newKeySkills = primarySkills.filter((_, i) => i !== index);
            setPrimarySkills(newKeySkills);
        }
    }

    const handleSecondarySkillChange = (e, index) => {
        const newKeySkills = [...secondarySkills];
        newKeySkills[index] = e.target.value;
        setSecondarySkills(newKeySkills);
    }

    const handleSecondarySkillIncrement = () => {
        if (secondarySkills.length < 6) {
            setSecondarySkills([...secondarySkills, ''])
        }
    }

    const handleSecondarySkillDecrement = (index) => {
        if (secondarySkills.length > 1) {
            const newKeySkills = secondarySkills.filter((_, i) => i !== index);
            setSecondarySkills(newKeySkills);
        }
    }

    const handleJobResponsibiltyChange = (e, index) => {
        const newJobResponsibilities = [...jobResponsibilities];
        newJobResponsibilities[index] = e.target.value;
        setJobResponsibilities(newJobResponsibilities);
    }

    const handleJobResponsibilityIncrement = () => {
        if (jobResponsibilities.length < 6) {
            setJobResponsibilities([...jobResponsibilities, ''])
        }
    }

    const handleJobResponsibilityDecrement = (index) => {
        if (jobResponsibilities.length > 1) {
            const newJobResponsibilities = jobResponsibilities.filter((_, i) => i !== index);
            setJobResponsibilities(newJobResponsibilities);
        }
    }

    const handleAdditionalRequirementChange = (e, index) => {
        const newAdditionalRequirements = [...additionalRequirements];
        newAdditionalRequirements[index] = e.target.value;
        setAdditionalRequirements(newAdditionalRequirements);
    }

    const handleAdditionalRequirementIncrement = () => {
        if (additionalRequirements.length < 6) {
            setAdditionalRequirements([...additionalRequirements, ''])
        }
    }

    const handleAdditionalRequirementDecrement = (index) => {
        if (additionalRequirements.length > 1) {
            const newAdditionalRequirements = additionalRequirements.filter((_, i) => i !== index);
            setAdditionalRequirements(newAdditionalRequirements);
        }
    }

    const handleFileInputClick = (e) => {
        fileInputRef.current.click();
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!validateForm() && formData.jobDescription.trim()) {
            navigate('/corporateDashboard/jobs/viewTemplate', { state: { data: formData, primarySkills, secondarySkills, jobResponsibilities, jobType, remoteOption, companyLogo, additionalRequirements, minSalaryValue, maxSalaryValue } })
        } else {
            setFormDataErrors((prevData) => ({ ...prevData, jobDescripitionError: 'Job description is required' }))
        }

    }

    const handleJobGeneration = (e) => {
        e.preventDefault();
        if (!validateForm()) {
            const data = {
                job_title: formData.jobTitle,
                location: formData.location,
                number_of_opening: formData.noofOpening,
                job_type: jobType.value,
                remote_option: remoteOption.value,
                primary_skills: JSON.stringify(primarySkills),
                secondary_skills: JSON.stringify(secondarySkills),
                job_responsibility: JSON.stringify(jobResponsibilities),
                salary_range: `${minSalaryValue}-${maxSalaryValue}`,
                minimum_experience: formData.experience,
                additional_requirements: JSON.stringify(additionalRequirements),
                company_name: formData.companyName,
                company_email: formData.companyEmail
            }

            dispatch(setLoading(true));
            axios.post('/generate_job_description_view', data).then(res => {
                dispatch(setLoading(false));
                if (res.success) {
                    setFormData({ ...formData, jobDescription: res.job_description })
                }
            }).catch(error => {
                dispatch(setLoading(false));
                console.log(error);

            })
        }

    }

    return (
        <div className='d-flex flex-column gap-4'>
            <Header heading={'Create New Job'} description={'Fill in the detailed form to attract top-tier candidates'} />
            <div className='createpost-container'>
                <form className='createPost-form' onSubmit={handleSubmit}>
                    <div className='basic-info-section'>
                        <h2>Basic Information</h2>
                        <div className='job-title field'>
                            <label htmlFor='jobTitle'>Job Title:<span className='mandate-field'>*</span></label>
                            <input name='jobTitle' value={formData.jobTitle} id='jobTitle' type='text' onChange={handleFormChange}></input>
                            <p className='form-error'>{formDataErrors.jobTitleError}</p>
                        </div>
                        <div className='job-location field'>
                            <label htmlFor='location'>Location:<span className='mandate-field'>*</span></label>
                            <input name='location' value={formData.location} id='location' type='text' onChange={handleFormChange}></input>
                            <p className='form-error'>{formDataErrors.locationError}</p>
                        </div>
                        <div className='job-type field'>
                            <label htmlFor='job_type'>Job Type:<span className='mandate-field'>*</span></label>
                            <Select
                                value={jobType}
                                onChange={setJobType}
                                options={jobTypeOptions}
                            />
                        </div>
                        <div className='remote-option field'>
                            <label htmlFor='remote_option'>Remote Option:<span className='mandate-field'>*</span></label>
                            <Select
                                value={remoteOption}
                                onChange={setRemoteOption}
                                options={remoteOptions}
                            />
                        </div>
                        <div className='candidate-experience field'>
                            <label htmlFor='candidate_experience'>Candidate Experience:<span className='mandate-field'>*</span></label>
                            <div className='candidate_experience_input'>
                                <input type='number' min={0} max={99} value={formData.experience} name='experience' onChange={handleFormChange} maxLength={2} onKeyDown={handleExperienceKeyDown}></input>
                                <span>Years</span>
                            </div>
                            <p className='form-error'>{formDataErrors.experienceError}</p>
                        </div>
                        <div className='no-of-openings field'>
                            <label htmlFor='noofOpening'>No. of Openings:<span className='mandate-field'>*</span></label>
                            <input type='number' min={1} value={formData.noofOpening} name='noofOpening' id='noofOpening' onChange={handleFormChange}></input>
                            <p className='form-error'>{formDataErrors.noofOpeningError}</p>
                        </div>
                        <div className='primary-skills field'>
                            <label htmlFor='primary_skills'>Primary Skills:<span className='mandate-field'>*</span></label>
                            {primarySkills.map((primarySkill, index) => {
                                return (
                                    <div className='primary-skill-input-section' key={index}>
                                        <input type='text' id='primary_skills' name='primary_skills' value={primarySkill} onChange={(e) => handlePrimarySkillChange(e, index)}></input>
                                        <div className='increment-decrement-buttons'>
                                            <FiPlusCircle onClick={() => handlePrimarySkillIncrement(index)} size={20} />
                                            <FiMinusCircle onClick={() => handlePrimarySkillDecrement(index)} size={20} />
                                        </div>
                                    </div>
                                )
                            })}
                            <p className='form-error'>{formDataErrors.primarySkillsError}</p>
                        </div>
                        <div className='secondary-skills field'>
                            <label htmlFor='secondary_skills'>Secondary Skills:<span className='mandate-field'>*</span></label>
                            {secondarySkills.map((skill, index) => {
                                return (
                                    <div className='secondary-skill-input-section' key={index}>
                                        <input type='text' id='secondary_skills' name='secondary_skills' value={skill} onChange={(e) => handleSecondarySkillChange(e, index)}></input>
                                        <div className='increment-decrement-buttons'>
                                            <FiPlusCircle onClick={() => handleSecondarySkillIncrement(index)} size={20} />
                                            <FiMinusCircle onClick={() => handleSecondarySkillDecrement(index)} size={20} />
                                        </div>
                                    </div>
                                )
                            })}
                            <p className='form-error'>{formDataErrors.secondarySkillsError}</p>
                        </div>
                        <div className='job-responsibility field'>
                            <label htmlFor='job_responsibility'>Job Responsibility:</label>
                            {jobResponsibilities.map((jobResponsibility, index) => {
                                return (
                                    <div className='job-responsibility-input-section' key={index}>
                                        <input type='text' id='job_responsibility' name='job_responsibility' value={jobResponsibility} onChange={(e) => handleJobResponsibiltyChange(e, index)}></input>
                                        <div className='increment-decrement-buttons'>
                                            <FiPlusCircle onClick={() => handleJobResponsibilityIncrement(index)} size={20} />
                                            <FiMinusCircle onClick={() => handleJobResponsibilityDecrement(index)} size={20} />
                                        </div>
                                    </div>
                                )
                            })}
                            <p className='form-error'>{ }</p>
                        </div>
                        <div className='salary-range'>
                            <div className='salary-range-selector'>
                                <label htmlFor='salary_range'>Salary Range:(LPA)<span className='mandate-field'>*</span></label>
                                <MultiRangeSlider
                                    ruler={false}
                                    barInnerColor='#0a84ed'
                                    min={0}
                                    max={100}
                                    step={1}
                                    labels={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
                                    minValue={minSalaryValue}
                                    maxValue={maxSalaryValue}
                                    onInput={(e) => {
                                        handleSalaryInput(e);
                                    }}
                                />
                            </div>
                            <div className='min-max-salary-inputs'>
                                <label htmlFor='min-salary-value'>Min</label>
                                <input type='text' id='min-salary-value' value={minSalaryValue} disabled></input>
                                <label htmlFor='max-salary-value'>Max</label>
                                <input type='text' id='max-salary-value' value={maxSalaryValue} disabled></input>
                            </div>
                        </div>
                        <div className='additional-requirement field'>
                            <label htmlFor='additionalRequirements'>Additional Requirements, if any:</label>
                            {additionalRequirements?.map((additionalRequirement, index) => {
                                return (
                                    <div className='additional-requirement-input-section' key={index}>
                                        <input type='text' id='additionalRequirements' name='additional_requirements' value={additionalRequirement} onChange={(e) => handleAdditionalRequirementChange(e, index)}></input>
                                        <div className='increment-decrement-buttons'>
                                            <FiPlusCircle onClick={() => handleAdditionalRequirementIncrement(index)} size={20} />
                                            <FiMinusCircle onClick={() => handleAdditionalRequirementDecrement(index)} size={20} />
                                        </div>
                                    </div>
                                )
                            })}
                            <p className='form-error'></p>
                        </div>
                    </div>
                    <div className='company-info-section'>
                        <h2>Company Information</h2>
                        <div className='company-name field'>
                            <label htmlFor='companyName'>Company Name:<span className='mandate-field'>*</span></label>
                            <input name='companyName' value={formData.companyName} id='companyName' type='text' onChange={handleFormChange}></input>
                            <p className='form-error'>{formDataErrors.companyNameError}</p>
                        </div>
                        <div className='company-email field'>
                            <label htmlFor='companyEmail'>Company Email:<span className='mandate-field'>*</span></label>
                            <input name='companyEmail' value={formData.companyEmail} id='companyEmail' type='email' onChange={handleFormChange}></input>
                            <p className='form-error'>{formDataErrors.companyEmailError}</p>
                        </div>
                        <div className='logo field d-flex flex-column gap-2'>
                            <label>Company Logo:<span className='mandate-field'>*</span></label>
                            <input name='logo' hidden={true} id='logo' ref={fileInputRef} type='file' onChange={handleLogoChange} multiple={false}></input>
                            <div className='logo-input-div' onClick={handleFileInputClick}>
                                <div className='logo-with-text'>
                                    <IoIosAddCircleOutline size={22} />
                                    <p>Click here to browse you files</p>
                                </div>
                                <div className='text-center'>
                                    {companyLogo}
                                </div>
                            </div>
                            <p className='form-error'>{formDataErrors.logoError}</p>
                        </div>
                    </div>
                    <div className='jd-section'>
                        <h2>Job Description</h2>
                        <div className='job-description field'>
                            <label htmlFor='jobDescription'>Job Description:<span className='mandate-field'>*</span></label>
                            <textarea name='jobDescription' value={formData.jobDescription} id='jobDescription' rows={6} onChange={handleFormChange}></textarea>
                            <p className='form-error'>{formDataErrors.jobDescripitionError}</p>
                        </div>
                    </div>
                    <div className='button-section d-flex gap-2'>
                        <button type='button' onClick={handleJobGeneration}>Generate Job Description</button>
                        <button type='submit'>View Template</button>
                    </div>
                </form>
            </div>
            {showPostTemplate && (
                <div className='post-template-wrapper'>
                    <div ref={componentRef}>
                        <PostTemplate formData={formData} skillsRequirements={primarySkills} email={email} />
                    </div>
                    <div className='download-template-btn'>
                        <button className='' onClick={handleDownloadImage}>Download Template</button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default CreateJob;
