import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useDispatch } from 'react-redux';
import { setLoading } from '../redux/userSlice';
import axios from '../util/api';
import toast from 'react-hot-toast';
import Pagination from './Pagination';
import Select from "react-select";

const allroles = [
    { value: "generaUser", label: "General User" },
    { value: "corporateUser", label: "Corporate User" }
];

function UserTable() {
    const dispatch = useDispatch();
    const [userModalShow, setUserModalShow] = useState(false);
    const [userData, setUserData] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [userFormData, setUserFormData] = useState({
        username: '',
        password: '',
        email: ''
    })

    const [userSearchInput, setUserSearchInput] = useState('');
    const [role, setRole] = useState(allroles[0]);
    const [formErrors, setFormErrors] = useState({
        userNameError: '',
        emailError: '',
        passwordError: ''
    })


    const handleUserFormChange = (e) => {
        const { name, value } = e.target;
        setUserFormData((prevData) => {
            return ({
                ...prevData,
                [name]: value
            })
        })
    }

    const validateUserForm = () => {
        const errors = {
            userNameError: '',
            emailError: '',
            passwordError: ''
        }

        let isError = false;

        if (!userFormData.username) {
            errors.userNameError = "Please enter userName";
            isError = true;
        }

        if (!userFormData.email) {
            errors.emailError = "Please enter email";
            isError = true;
        }

        if (!userFormData.password) {
            errors.passwordError = 'Please enter Password';
            isError = true;
        }

        setFormErrors(errors);
        return isError;
    }

    useEffect(() => {
        const data = {
            page: page,
            search_query: userSearchInput
        }

        dispatch(setLoading(true));
        axios.post('/users_pagination', data).then((res) => {
            if (res.success) {
                setUserData(res.users);
                setTotalPages(res.total_pages);
                if (page > Number(res.total_pages)) {
                    setPage(1);
                }

            } else {
                toast.dismiss(); toast.error("Something went wrong")
            }
            dispatch(setLoading(false));
        }).catch((error) => {
            dispatch(setLoading(false));
            console.log(error)
        })
    }, [page, userSearchInput])

    const handleUserFormSubmit = (e) => {
        e.preventDefault();
        const data = { ...userFormData, role: role.value }
        if (!validateUserForm()) {
            dispatch(setLoading(true));
            axios.post('/user_creation', data).then((res) => {
                if (res.success) {
                    setUserModalShow(false);
                    setUserFormData({});
                    setUserData(preUserData => [
                        ...preUserData,
                        {
                            id: res.data.id,
                            username: res.data.username,
                            email: res.data.email,
                            role: res.data.role
                        }
                    ]);
                } else {
                    toast.dismiss(); toast.error(res.message)
                }
                dispatch(setLoading(false));
            }).catch((err) => {
                console.log(err);
                dispatch(setLoading(false));
            })
        }
    }
    return (
        <>
            <div className='user-table-wrapper'>
                <div className='add-user-with-search'>
                    <div className='user-search-input'>
                        <input type='text' id='user_search' placeholder='search' onChange={(e) => setUserSearchInput(e.target.value)}></input>
                    </div>
                    <button onClick={() => setUserModalShow(true)}>Add User</button>
                </div>
                <h4>Users</h4>
                <table className='user-table'>
                    <thead>
                        <tr>
                            <th>UserName</th>
                            <th>Email</th>
                            <th>Role</th>
                        </tr>
                    </thead>
                    <tbody>
                        {userData?.map((user, index) => {
                            return (
                                <tr key={index}>
                                    <td>{user.username}</td>
                                    <td>{user.email}</td>
                                    <td>{user.role}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                <Pagination page={page} setPage={setPage} total={totalPages} />
            </div>
            <Modal
                className='user-add-modal'
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={userModalShow}
                onHide={() => setUserModalShow(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Add User</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleUserFormSubmit}>
                        <div className='username field'>
                            <label htmlFor='username'>User Name</label>
                            <input type='text' id='username' name='username' onChange={handleUserFormChange}></input>
                            <p className='form-error'>{formErrors.userNameError}</p>
                        </div>
                        <div className='email field'>
                            <label htmlFor='email'>Email</label>
                            <input type='text' id='email' name='email' onChange={handleUserFormChange}></input>
                            <p className='form-error'>{formErrors.emailError}</p>
                        </div>
                        <div className='password field'>
                            <label htmlFor='password'>Password</label>
                            <input type='text' id='password' name='password' onChange={handleUserFormChange}></input>
                            <p className='form-error'>{formErrors.passwordError}</p>
                        </div>
                        <div className='role field'>
                            <label htmlFor="role">Role</label>
                            <Select
                                value={role}
                                onChange={setRole}
                                options={allroles}
                                name='role'
                            />
                            <p></p>
                        </div>
                        {/* <div className='role field'>
                            <label htmlFor='role'>Role</label>
                            <input type='text' id='role' name='role' onChange={handleUserFormChange}></input>
                        </div> */}
                        <div className='submitBtn'>
                            <button className='submit-btn'>Submit</button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default UserTable