import React, { useEffect, useState } from 'react';
import FinalMCQTestPreview from '../../../components/FinalMCQTestPreview';
import axios from '../../../util/api';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading, setPreviewQuestions } from '../../../redux/userSlice';
import Select from 'react-select';
import toast from 'react-hot-toast';

export default function AIGenerated({ job_id, aiGeneratedQuestion, setAiGeneratedQuestion, options, setOptions, selectedSkills, setselectedSkills, questionCount, setQuestionCount }) {
    const dispatch = useDispatch();
    const finalQuestion = useSelector(state => state.user.previewQuestions)
    const [selectAll, setSelectAll] = useState(false);
    const [formError, setFormError] = useState({ skillError: '', questionCountError: '' });

    useEffect(() => {
        if (options.length <= 0) {
            dispatch(setLoading(true));
            const data = {
                job_id
            }
            axios.post('/generate_similar_skills', data).then(res => {
                console.log(res);
                dispatch(setLoading(false));
                if (res.success) {
                    setOptions(res.similar_skills.map(item => ({ value: item, label: item })))
                }
            }).catch(error => {
                console.log(error);
                dispatch(setLoading(false));

            })
        }
    }, [])

    const handleSkillChange = (e) => {
        setselectedSkills(e);
        if (e.length > 0) {
            setFormError(prevState => ({ ...prevState, skillError: '' }));
        }
    }

    const handleSelect = (e, index) => {
        let updatedList = [...aiGeneratedQuestion];
        let updatedItem = { ...updatedList[index] };

        let isQuestionPresent = finalQuestion.find((item) => item.question === updatedItem.question);
        if (isQuestionPresent && e.target.checked) {
            toast.dismiss();
            toast.error('Question Already Present');
            return;
        }

        updatedItem.isSelected = e.target.checked;
        updatedList[index] = updatedItem;
        setSelectAll(updatedList.every(item => item.isSelected))
        setAiGeneratedQuestion(updatedList);

        if (e.target.checked) {
            let moveQuestion = { ...updatedList[index] };
            moveQuestion.ai = index
            moveQuestion.isSelected = false

            let newFinal = [...finalQuestion];
            newFinal.push(moveQuestion);

            dispatch(setPreviewQuestions(newFinal))
        } else {
            let newFinal = [...finalQuestion];
            newFinal = newFinal.filter(item => item.ai !== index);
            dispatch(setPreviewQuestions(newFinal));
        }
    }

    const handleSelectAll = (e) => {
        setSelectAll(e.target.checked);
        let updatedList = [...aiGeneratedQuestion];
        setAiGeneratedQuestion(updatedList.map(item => ({ ...item, isSelected: e.target.checked })))

        if (e.target.checked) {
            let remainingManualQuestion = updatedList.filter(item => !item.isSelected).map((item, index) => ({ ...item, ai: index, isSelected: false }));
            let newFinal = [...finalQuestion, ...remainingManualQuestion];
            dispatch(setPreviewQuestions(newFinal));
        } else {
            let manualArray = Array.from({ length: aiGeneratedQuestion.length }, (_, index) => index)
            let newFinal = [...finalQuestion];
            newFinal = newFinal.filter(item => !manualArray.includes(item.ai));
            dispatch(setPreviewQuestions(newFinal));
        }
    };

    const handleQuestionCountKeyPress = (e) => {
        const invalidChars = ['e', 'E', '+', '-', '.'];
        if (invalidChars.includes(e.key)) {
            e.preventDefault();
        }
    };

    const handleNumberChange = (e) => {
        const value = e.target.value;
        if (/^\d{0,3}$/.test(value)) {
            setQuestionCount(value);
        }
    }

    const validateForm = () => {
        let isValid = true;
        if (selectedSkills.length === 0) {
            setFormError(prevState => ({ ...prevState, skillError: 'Please select at least one skill.' }));
            isValid = false;
        }

        if (questionCount < 1 || questionCount > 100 || questionCount === '') {
            setFormError(prevState => ({ ...prevState, questionCountError: 'Please enter a number between 1 and 100.' }));
            isValid = false;
        }
        return isValid;
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!validateForm()) return;
        dispatch(setLoading(true));
        const data = {
            job_id,
            skills: JSON.stringify(selectedSkills.map(item => item.value)),
            nquestions: questionCount
        }
        axios.post('/generate_ai_questions', data).then(res => {
            console.log(res);
            dispatch(setLoading(false));
            if (res.success) {
                let newAiQuestion = [...aiGeneratedQuestion, ...res.questions];
                setAiGeneratedQuestion(newAiQuestion);
                setSelectAll(false);
            }
        }).catch(error => {
            console.log(error);
            dispatch(setLoading(false));
        })
    }

    return (
        <div className='ai-wrapper'>
            <div className='ai-test-container'>
                <div className='ai-test-generate-header'>
                    <div className='heading'>
                        {/* <h2>AI Generated Questions</h2> */}
                        <p>The questions are generated based on the job description and the skills specified during the job creation process:</p>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className='field'>
                            <label htmlFor='skills'>Skills:</label>
                            <Select id="skills" value={selectedSkills} options={options} isMulti={true} placeholder="Select your skills" onChange={handleSkillChange} />
                            <span className='form-error'>{formError.skillError}</span>
                        </div>
                        <div className='field'>
                            <label htmlFor='no_of_questions'>No. of Questions:</label>
                            <input type='number' onKeyDown={handleQuestionCountKeyPress} value={questionCount} id='no_of_questions' onChange={handleNumberChange}></input>
                            <span className='form-error'>{formError.questionCountError}</span>
                        </div>
                        <div className='generate-questions-button'>
                            <button type='submit'>Generate Questions</button>
                        </div>
                    </form>
                </div>
                {aiGeneratedQuestion?.length > 0 && (
                    <div className='ai-test'>
                        <div className='d-flex p-2 gap-2'>
                            <input type='checkbox' id='selectAll' checked={selectAll} onChange={handleSelectAll}></input>
                            <label htmlFor='selectAll'>Select All</label></div>
                        <div className='questions-container'>
                            {aiGeneratedQuestion.map((question, index) => (
                                <div key={index} className='question-card'>
                                    <div className="question-count d-flex gap-2"><input type='checkbox' checked={question.isSelected} onChange={(e) => handleSelect(e, index)}></input>Question {index + 1}</div>
                                    <h3 className='question'>{question.question}</h3>
                                    <ul className='options'>
                                        <li>A. {question.optionone}</li>
                                        <li>B. {question.optiontwo}</li>
                                        <li>C. {question.optionthree}</li>
                                        <li>D. {question.optionfour}</li>
                                    </ul>
                                    <p className='answer'><strong>Correct Answer:</strong> {question.answer}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </div>
            <div className='final-mcq-test-preview-wrapper'>
                {finalQuestion.length ? (
                    <FinalMCQTestPreview job_id={job_id} />
                ) : (
                    <>
                        <h2>Final MCQ Test</h2>
                        <div className='select-the-questions-wrapper'>
                            <h2>Select the Questions</h2>
                            <p>To Finalize the Test!</p>
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}
