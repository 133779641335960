import React, { useState } from 'react';
import '../../scss/CorporateAdminDashboard.scss';
import { NavLink, Outlet, useNavigate } from 'react-router-dom';
import HireNowXLogo from '../../assests/images/HireNowXDashboardLogo.png';
import HireNowXLogo1 from '../../assests/images/HireNowXLogo.png';
import hirenowx from '../../assests/images/hirenowx.png';
import smalllogo from '../../assests/images/small-logo.png';
import SideBarBottomLogo from '../../assests/images/sidebarBottomLogo.png'
import { GoHome } from "react-icons/go";
import { MdLogout, MdOutlineLightbulb } from "react-icons/md";
import { PiHandbagSimple } from "react-icons/pi";
import { TbUserPentagon } from "react-icons/tb";
import { FiPlus } from "react-icons/fi";
import tokenService from '../../util/TokenService';
import { Container, Navbar, Offcanvas, Tooltip } from 'react-bootstrap';
import { PiReadCvLogoBold } from "react-icons/pi";

function CorporateAdminDashboard() {
    const navigate = useNavigate();
    const user = tokenService.getUser();
    const [isOffcanvasOpen, setOffcanvasOpen] = useState(false);
    const [size, setsize] = useState('22%');
    const expand = 'lg';
    const handleLogout = () => {
        tokenService.removeUser();
        navigate('/');
    }

    const getInitials = (name) => {
        const names = name.split(' ');
        let initials = names[0].charAt(0).toUpperCase();
        if (names.length > 1) {
            initials += names[1].charAt(0).toUpperCase();
        }
        return initials;
    }

    const handleSize = () => {
        if (size === '120px') {
            setsize('22%');
        } else {
            setsize('120px')
        }
    }

    return (
        <div className='corporate-admin-dashboard-wrapper'>
            <div className="sidebar">
                <Navbar key={expand} expand={expand} className="sidebar-navbar flex-column">
                    <Container fluid>
                        {/* <Navbar.Brand href="#">Navbar Offcanvas</Navbar.Brand> */}
                        <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} onClick={() => setOffcanvasOpen(!isOffcanvasOpen)} />
                        <Navbar.Offcanvas
                            show={isOffcanvasOpen}
                            onHide={() => setOffcanvasOpen(false)}
                            id={`offcanvasNavbar-expand-${expand}`}
                            aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                            placement="start"
                        >
                            <Offcanvas.Header closeButton>
                                {/* <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                                Offcanvas
                            </Offcanvas.Title> */}
                            </Offcanvas.Header>
                            <Offcanvas.Body className='flex-column'>
                                <div className='sidebar-tabs-wrapper'>
                                    <div className='sidebar-container'>
                                        <div className='sidebar-logo'>
                                            <img src={hirenowx} alt='logo'></img>
                                            {/* <span className='move-icon' onClick={handleSize}>
                                                <IoIosArrowForward />
                                            </span> */}
                                        </div>
                                        <div className='sidebar-tabs'>
                                            <div className='tabs'>
                                                <NavLink to='/corporateDashboard' onClick={() => setOffcanvasOpen(false)} end><GoHome />Home</NavLink>
                                                <NavLink to='/corporateDashboard/teams' onClick={() => setOffcanvasOpen(false)}><MdOutlineLightbulb />My Team</NavLink>
                                                <NavLink to='/corporateDashboard/jobs' onClick={() => setOffcanvasOpen(false)}><PiHandbagSimple />Jobs</NavLink>
                                                <NavLink to='/corporateDashboard/profile' onClick={() => setOffcanvasOpen(false)}><TbUserPentagon />Profile</NavLink>
                                                <NavLink to='/corporateDashboard/pool' onClick={() => setOffcanvasOpen(false)}><PiReadCvLogoBold />Pool</NavLink>
                                            </div>
                                        </div>
                                        <div className='create-new-job-btn'>
                                            <button onClick={() => { navigate('/corporateDashboard/jobs/createJob'); setOffcanvasOpen(false) }}><FiPlus />Create New Job</button>
                                        </div>
                                    </div>
                                    <div className='bottom-logo'>
                                        <div className='powered-by'>
                                            Powered by <img src={smalllogo} alt="img" />
                                        </div>
                                    </div>
                                </div>
                            </Offcanvas.Body>
                        </Navbar.Offcanvas>
                        <div className='profile'>
                            <span onClick={handleLogout} className='logout-switch-btn'>
                                <MdLogout
                                    size={27}
                                    className='logout-icon'
                                    onClick={handleLogout}
                                    data-tooltip-id="logout-tooltip"
                                    data-tooltip-content="Logout"
                                />
                            </span>
                            <span>{getInitials(`${user.first_name} ${user.last_name}`)}</span>
                        </div>
                    </Container>
                </Navbar>
            </div>

            <div className='content-wrapper'>
                <Outlet />
            </div>
        </div>
    )
}

export default CorporateAdminDashboard