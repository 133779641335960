import React from 'react';
import { Navigate } from 'react-router-dom';
import TokenService from '../util/TokenService';
function PrivateRoutes({ children, allowedRoles }) {
    const user = TokenService.getUser();

    if (allowedRoles.includes(user?.role)) {
        return <>{children}</>
    } else {
        return <><Navigate to='/' replace='true' /></>
    }
}

export default PrivateRoutes;
