import React, { useEffect, useState } from 'react';
import GaugeChart from './GaugeChart';

function ResumeSummary({ resumeSummaryData }) {
  const [primarySkills, setPrimarySkills] = useState([]);
  const [secondarySkills, setSecondarySkills] = useState([]);

  useEffect(() => {
    if(resumeSummaryData?.primary_skill){
      setPrimarySkills(JSON.parse(resumeSummaryData?.primary_skill));
    }
    if(resumeSummaryData?.secondary_skill){
      setSecondarySkills(JSON.parse(resumeSummaryData?.secondary_skill));
    }
  }, [resumeSummaryData])


  return (
    <div className='resume-summary-wrapper'>
      <div className='summary'>
        <h2>Resume Summary :</h2>
        <p>{resumeSummaryData?.resume_summary}</p>
      </div>
      <div className='experience-skills'>
        <div className='experience'>
          <h2>Experience :</h2>
          <p>{resumeSummaryData?.candidate_exp} years</p>
        </div>
        <div className='primary-skills'>
          <h2>Primary Skills :</h2>
          {primarySkills.length > 0 ? (
            primarySkills.map((skill, index) => <p key={index}>{skill}</p>)
          ) : (
            <p>No primary skills available</p>
          )}
        </div>
        <div className='secondary-skills'>
          <h2>Secondary Skills :</h2>
          {secondarySkills.length > 0 ? (
            secondarySkills.map((skill, index) => <p key={index}>{skill}</p>)
          ) : (
            <p>No secondary skills available</p>
          )}
        </div>
      </div>
      <div className='score-chart'>
        <GaugeChart data={resumeSummaryData?.resume_score} />
      </div>
    </div>
  );
}

export default ResumeSummary;
