import React, { useEffect, useState } from 'react';
import '../../scss/Profile.scss'
import Header from '../../components/Header';
import tokenService from '../../util/TokenService';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../redux/userSlice';
import axios from '../../util/api';
import { useLocation } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import toast from 'react-hot-toast';

function Profile() {
  const user = tokenService.getUser();
  const dispatch = useDispatch();
  const [candidate, setCandidate] = useState({});
  const location = useLocation();
  const [modalShow, SetModalShow] = useState(false);
  const [formData, setformData] = useState({
    email: '',
    phone: '',
    password: '',
    company: '',
    designation: '',
    department: '',
    employee_id: ''
  });

  const user_id = location?.state ? location.state.user_id : null;

  function getCorporateUser() {
    let url = '/get_corporate_user_with_id';
    if (user?.role === 'corporateUser') {
      url = '/get_corporate_user_by_id'
    }

    axios.post(url, { id: user_id ? user_id : user.user_id }).then(res => {
      dispatch(setLoading(false));
      if (res.success && res.user) {
        setCandidate({
          name: res.user.username,
          designation: res.user.designation,
          department: res.user.department,
          employeeid: res.user.employee_id,
          phoneNumber: res.user.phone_number,
          email: res.user.email,
          company: res.user.company
        })
      }
    }).catch(error => {
      console.log(error);
      dispatch(setLoading(false));
    })
  }

  useEffect(() => {
    dispatch(setLoading(true))
    getCorporateUser()
  }, [])

  const getInitials = (name) => {
    const names = name.split(' ');
    let initials = names[0].charAt(0).toUpperCase();
    if (names.length > 1) {
      initials += names[1].charAt(0).toUpperCase();
    }
    return initials;
  }

  const handleProfileEditClick = () => {
    setformData((prevData) => ({
      ...prevData,
      email: candidate.email,
      phone: candidate.phoneNumber,
      company: candidate.company,
      designation: candidate.designation,
      department: candidate.department,
      employee_id: candidate.employeeid
    }));

    SetModalShow(true);
  }

  const [formDataError, setFormDataError] = useState({
    emailError: '',
    phoneError: '',
    passwordError: '',
    companyError: '',
    designationError: '',
    departmentError: '',
    employeeIdError: ''
  })

  const handleEditFormChanges = (e) => {
    const { name, value } = e.target;

    if (name === 'phone') {
      let digitsOnly = value.replace(/\D/g, '');
      if (digitsOnly.length > 10) {
        digitsOnly = digitsOnly.slice(0, 10);
      }
      setformData((prevData) => ({
        ...prevData,
        [name]: digitsOnly,
      }));
    } else {
      setformData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const validateform = () => {
    let errors = {
      phoneError: '',
      emailError: '',
      passwordError: '',
      companyError: '',
      designationError: '',
      departmentError: '',
      employeeIdError: ''
    }

    let isError = false;

    if (!formData?.email?.trim()) {
      errors.emailError = "Please enter valid Email";
      isError = true;
    }

    if (!formData?.phone?.trim()) {
      errors.phoneError = "Please enter valid phone number";
      isError = true;
    }

    // if (!formData?.password?.trim() && formData?.password?.length < 4) {
    //   errors.passwordError = 'Please enter valid password';
    //   isError = true
    // }

    if (!formData?.company?.trim()) {
      errors.companyError = 'Please enter company name';
      isError = true;
    }

    if (!formData?.designation?.trim()) {
      errors.designationError = 'Please enter designation';
      isError = true;
    }

    if (!formData?.department?.trim()) {
      errors.departmentError = 'Please enter department';
      isError = true;
    }

    if (!formData?.employee_id?.trim()) {
      errors.employeeIdError = "Please enter employee Id";
      isError = true;
    }

    setFormDataError(errors);
    return isError;
  }

  const handleEdit = (e) => {
    e.preventDefault();
    if (!validateform()) {
      const data = {
        email: formData.email,
        password: formData.password,
        phone_number: formData.phone,
        company: formData.company,
        designation: formData.designation,
        department: formData.department,
        employee_id: formData.employee_id,
        user_id: user.user_id
      }

      dispatch(setLoading(true))
      axios.post('/edit_corporate_admin', data).then((res) => {
        if (res.success) {
          toast.dismiss(); toast.success(res.message);
          SetModalShow(false);
          getCorporateUser();
          dispatch(setLoading(false));
        }
      }).catch((error) => {
        dispatch(setLoading(false));
        console.log(error)
      })
    }
  }

  return (
    <div className="profile-wrapper">
      <Header heading={'My Profile'} />
      <div className='profile-container'>
        <div className="profile-information">
          <div className="profile">
            {getInitials(candidate.name || '')}
          </div>
          <div className='profile-details'>
            <div className='name-section'>
              <h2>{candidate.name}</h2>
              {user.role === 'corporateAdmin' && (
                <button onClick={handleProfileEditClick}>Edit</button>
              )}
            </div>
            <div className='contact-details-section'>
              <table>
                <tbody>
                  <tr>
                    <td className='email-label'>Email<span>:</span></td>
                    <td className='value'>{candidate.email}</td>
                  </tr>
                  <tr>
                    <td className='phone-label'>Phone Number<span>:</span></td>
                    <td className='value'>{candidate.phoneNumber}</td>
                  </tr>
                  {(user.role === 'corporateAdmin' && !user_id) && (
                    <tr>
                      <td className='password-label'>Password<span>:</span></td>
                      <td className='value'>************</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="basic-information profile-info">
          <h2>Basic Information:</h2>
          <table>
            <tbody>
              <tr>
                <td className='company-label label'>Company Name<span>:</span></td>
                <td className='value'>{candidate.company}</td>
              </tr>
              <tr>
                <td className='designation-label label'>Designation<span>:</span></td>
                <td className='value'>{candidate.designation}</td>
              </tr>
              <tr>
                <td className='department-label label'>Department<span>:</span></td>
                <td className='value'>{candidate.department}</td>
              </tr>
              <tr>
                <td className='empoloyee-id-label label'>Employee ID<span>:</span></td>
                <td className='value'>{candidate.employeeid}</td>
              </tr>
            </tbody>
          </table>
        </div>
        {(user.role === 'corporateAdmin' && !user_id) && (
          <div className="subscription-plan profile-info">
            <div className='heading-section'>
              <h2>Subscription Plan</h2>
              <button>Request Upgrade</button>
            </div>
            <div className='card'>
              <div className='card-plan'>
                <h2>HireNowX Premium Plan</h2>
                <p>Validity: 1 Year</p>
                <p>Corporate Admin: 3</p>
              </div>
              <div className='activated-date'>
                <p className='activated-text'>Activated on</p>
                <p className='date'>25 June 2024</p>
              </div>
            </div>
          </div>
        )}

      </div>
      <Modal
        className='edit_modal'
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={modalShow}
        onHide={() => SetModalShow(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit My Profile</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleEdit} className='form fields'>
            <div className='email field'>
              <label htmlFor='email'>Email:<span className='mandate-field'>*</span></label>
              <input type='email' name='email' value={formData.email} onChange={handleEditFormChanges} id='email'></input>
              <p className='form-error'>{formDataError.emailError}</p>
            </div>
            <div className='phone field'>
              <label htmlFor='phone'>Phone:<span className='mandate-field'>*</span></label>
              <input type='text' name='phone' value={formData.phone} id='phone' onChange={handleEditFormChanges}></input>
              <p className='form-error'>{formDataError.phoneError}</p>
            </div>
            <div className='password field'>
              <label htmlFor="password">Password:</label>
              <input type='text' id='password' name='password' value={formData.password} onChange={handleEditFormChanges}></input>
              <p className='form-error'>{formDataError.passwordError}</p>
            </div>
            <div className='company field'>
              <label htmlFor='company'>Company:<span className='mandate-field'>*</span></label>
              <input type='text' name='company' id='company' value={formData.company} onChange={handleEditFormChanges}></input>
              <p className='form-error'>{formDataError.companyError}</p>
            </div>
            <div className='designation field'>
              <label htmlFor='designation'>Designation:<span className='mandate-field'>*</span></label>
              <input type='text' name='designation' id='designation' value={formData.designation} onChange={handleEditFormChanges}></input>
              <p className='form-error'>{formDataError.designationError}</p>
            </div>
            <div className='department field'>
              <label htmlFor='department'>Department:<span className='mandate-field'>*</span></label>
              <input type='text' name='department' id='department' value={formData.department} onChange={handleEditFormChanges}></input>
              <p className='form-error'>{formDataError.departmentError}</p>
            </div>
            <div className='employee-id field'>
              <label htmlFor='employee_id'>Employee ID:<span className='mandate-field'>*</span></label>
              <input type='text' id='employee_id' name='employee_id' className='employee_id' value={formData.employee_id} onChange={handleEditFormChanges}></input>
              <p className='form-error'>{formDataError.employeeIdError}</p>
            </div>
            <div className='edit-btn'>
              <button>Edit</button>
            </div>
          </form>
        </Modal.Body>
      </Modal>

    </div>
  )
}

export default Profile