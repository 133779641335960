import React, { useEffect, useState } from 'react'
import { Tab, Tabs } from 'react-bootstrap'
import Manual from './Manual';
import AIGenrated from './AIGenrated';
import PoolQuestions from './PoolQuestions';
import '../../../scss/Questions.scss';
import Header from '../../../components/Header';
import FinalMCQTest from '../../../components/FinalMCQTest';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setLoading, setPreviewQuestions } from '../../../redux/userSlice';
import axios from '../../../util/api';
import dayjs from 'dayjs';

export default function Questions() {
    const dispatch = useDispatch();
    const location = useLocation();
    const job_id = location?.state ? location.state?.job_id : null;
    const date = location?.state ? location.state?.date : null;
    const title = location?.state ? location.state?.title : null;
    const [key, setKey] = useState('manual');
    const [manualQuestion, setManualQuestion] = useState([]);
    const [poolQuestion, setPoolQuestion] = useState([]);
    const [aiGeneratedQuestion, setAiGeneratedQuestion] = useState([]);
    const [options, setOptions] = useState([]);
    const [selectedSkills, setselectedSkills] = useState([]);
    const [questionCount, setQuestionCount] = useState('');

    useEffect(() => {
        let sessionQuestion = JSON.parse(sessionStorage.getItem('questions'));
        if (sessionQuestion && sessionQuestion.length > 0) {
            dispatch(setPreviewQuestions(sessionQuestion));
        } else {
            dispatch(setLoading(true));
            axios.post('get_question_paper', { job_id }).then(res => {
                dispatch(setLoading(false));
                if (res.success) {
                    let totalQuestion = [...res.quizzes];
                    sessionStorage.setItem('questions', JSON.stringify(totalQuestion));
                    dispatch(setPreviewQuestions(totalQuestion));
                }
            }).catch(error => {
                console.log(error);
                dispatch(setLoading(false));
            })
        }
    }, [])

    return (
        <>
            <div className="question-wrapper">
                <div className='header-with-job-description'>
                    <Header heading="MCQ Test" description="Create a Customized MCQ Test with the help of AI" />
                    <div className='job-details-section'>
                        <table>
                            <tbody>
                                <tr>
                                    <td className='title'>Job Title</td>
                                    <td className='value'><span>:</span>{title}</td>
                                </tr>
                                <tr>
                                    <td className='title'>Created Job</td>
                                    <td className='value'><span>:</span>{dayjs(date).format('DD MMM YYYY')}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className='body-content'>
                    <Tabs
                        id="controlled-tab-example"
                        activeKey={key}
                        onSelect={(k) => setKey(k)}
                    >
                        <Tab eventKey="manual" title="Manual" >
                            {key === 'manual' && <Manual job_id={job_id} manualQuestion={manualQuestion} setManualQuestion={setManualQuestion} />}
                        </Tab>
                        <Tab eventKey="ai" title="AI Generated">
                            {key === 'ai' && <AIGenrated job_id={job_id} aiGeneratedQuestion={aiGeneratedQuestion} setAiGeneratedQuestion={setAiGeneratedQuestion} options={options} setOptions={setOptions} selectedSkills={selectedSkills} setselectedSkills={setselectedSkills} questionCount={questionCount} setQuestionCount={setQuestionCount} />}
                        </Tab>
                        <Tab eventKey="pool" title="Pool">
                            {key === 'pool' && <PoolQuestions job_id={job_id} poolQuestion={poolQuestion} setPoolQuestion={setPoolQuestion} />}
                        </Tab>
                        <Tab eventKey="finalTest" title="Final Test">
                            {key === 'finalTest' && <FinalMCQTest job_id={job_id} manualQuestion={manualQuestion} setManualQuestion={setManualQuestion} aiGeneratedQuestion={aiGeneratedQuestion} setAiGeneratedQuestion={setAiGeneratedQuestion} poolQuestion={poolQuestion} setPoolQuestion={setPoolQuestion} />}
                        </Tab>
                    </Tabs>
                </div>
            </div>
        </>
    )
}
