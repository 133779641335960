import React, { useEffect, useState, useRef } from 'react';
import '../../scss/Interview.scss';
import * as SpeechSDK from "microsoft-cognitiveservices-speech-sdk";
import { HiMiniSpeakerWave } from "react-icons/hi2";
import Webcam from "react-webcam";
import Countdown from 'react-countdown';
import Loader from '../../components/Spinner/Loader';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../redux/userSlice';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { BsFillMicMuteFill } from "react-icons/bs";
import { MdKeyboardVoice } from "react-icons/md";
import { VscDebugRestart } from "react-icons/vsc";
import InterviewerImg from '../../assests/images/Male_Interviewer.jpg'
import HireNowXLogo from '../../assests/images/HireNowXLogo.png';
import companylogo from '../../assests/images/company-logo.png';
import axios from '../../util/api';
import toast from 'react-hot-toast';
import base64js from 'base64-js';
import { createAvatarSynthesizer, createWebRTCConnection, uploadFileToAzure } from '../../util/azureConfig';
import TokenService from '../../util/TokenService';
import { useNavigate } from 'react-router-dom';

function Interview() {
    const navigate = useNavigate();
    const [caption, setCaption] = useState('');
    const [cameraAccess, setCameraAccess] = useState(null);
    const [timer, setTimer] = useState(null);
    const [interviewQuestion, setInterviewQuestion] = useState('');
    const [sessionId, setSessionId] = useState('');
    const [candidateAnswer, setCandidateAnswer] = useState('');
    const [voice, setVoice] = useState(null);
    const [utterance, setUtterance] = useState(null);
    const [isListening, setIsListening] = useState(false)
    const { transcript, resetTranscript } = useSpeechRecognition();
    const dispatch = useDispatch();
    const videoRef = useRef(null);
    const chunks = useRef([]);
    const myAvatarVideoEleRef = useRef();
    const myAvatarAudioEleRef = useRef();
    const [avatarVideoEvent, setavatarVideoEvent] = useState(null);
    const [avatarAudioEvent, setavatarAudioEvent] = useState(null);
    const [avatarSynthesizer, setAvatarSynthesizer] = useState(null);
    const [mediaRecorder, setMediaRecorder] = useState(null);
    const [audioSrc, setAudioSrc] = useState(null);
    const [base64String, setBase64String] = useState(null);
    const [candidateDetails, setCandidateDetails] = useState({ email: '', name: '', gender: '' , job_Title: ''});
    const [isInterviewStarted, setIsInterviewStarted] = useState(false);
    const [isTimeRunningOut, setIsTimeRunningOut] = useState(false);

    useEffect(() => {
        if (base64String) {
            const byteArray = base64js.toByteArray(base64String);
            const blob = new Blob([byteArray], { type: 'audio/mp3' });
            const url = URL.createObjectURL(blob);
            setAudioSrc(url);

            return () => URL.revokeObjectURL(url);
        }
    }, [base64String]);

    useEffect(() => {
        const token = TokenService.getAccessToken();
        let decryptedTokenData;
        if (token) {
            decryptedTokenData = TokenService.decryptToken(token);
            setCandidateDetails(prevData => ({
                ...prevData,
                email: decryptedTokenData.email,
                name: decryptedTokenData.username,
                gender: decryptedTokenData.interviewerGender,
                job_Title: decryptedTokenData.job_title
            }));
        } else {
            setTimeout(() => {
                toast.dismiss(); toast.error('Token not found')
            }, 500);
        }
        setTimer(Date.now() + decryptedTokenData.duration * 60 * 1000);
    }, []);

    const handleOnTrack = (event) => {
        if (event.track.kind === 'video') {
            setavatarVideoEvent(event);
        } else {
            setavatarAudioEvent(event);
        }
    };

    const startSession = () => {
        try {
            dispatch(setLoading(true));
            let peerConnection = createWebRTCConnection();
            console.log("Peer connection ", peerConnection);
            peerConnection.ontrack = handleOnTrack;
            peerConnection.addTransceiver('video', { direction: 'sendrecv' })
            peerConnection.addTransceiver('audio', { direction: 'sendrecv' })

            let avatarSynthesizer = createAvatarSynthesizer(candidateDetails.gender);
            setAvatarSynthesizer(avatarSynthesizer);
            peerConnection.oniceconnectionstatechange = e => {
                console.log("WebRTC status: " + peerConnection.iceConnectionState)

                if (peerConnection.iceConnectionState === 'connected') {
                    console.log("Connected to Azure Avatar service");
                }

                if (peerConnection.iceConnectionState === 'disconnected' || peerConnection.iceConnectionState === 'failed') {
                    console.log("Azure Avatar service Disconnected");
                }
            }

            avatarSynthesizer.startAvatarAsync(peerConnection).then((r) => {
                axios.post('/ai_round_start').then((res) => {
                    if (res.success) {
                        setIsInterviewStarted(true);
                        setSessionId(res.session_id);
                        setInterviewQuestion(res.first_question)
                    } else {
                        toast.dismiss(); toast.error(res.message)
                    }
                    dispatch(setLoading(false))
                }).catch((error) => {
                    dispatch(setLoading(false))
                    console.log(error);
                    toast.dismiss(); toast.error('Something went wrong');
                })
            }).catch(
                (error) => {
                    dispatch(setLoading(false));
                    console.log("[" + (new Date()).toISOString() + "] Avatar failed to start. Error: " + error)
                }
            );
        } catch (error) {
            console.log(error);
            dispatch(setLoading(false));
        }
    }

    const stopSession = () => {
        try {
            //Stop speaking
            avatarSynthesizer.stopSpeakingAsync().then(() => {
                // Close the synthesizer
                avatarSynthesizer.close();
            }).catch();
        } catch (e) {
        }
    }

    const stopSpeaking = async () => {
        try {
            await avatarSynthesizer.stopSpeakingAsync()
        } catch (error) {
            console.log(error);
        }
    }

    const speakSelectedText = () => {

        //Start speaking the text
        const audioPlayer = myAvatarAudioEleRef.current;
        audioPlayer.muted = false;
        avatarSynthesizer.speakTextAsync(interviewQuestion).then(
            (result) => {
                if (result.reason === SpeechSDK.ResultReason.SynthesizingAudioCompleted) {
                    console.log("Speech and avatar synthesized to video stream.")
                } else {
                    console.log("Unable to speak. Result ID: " + result.resultId)
                    if (result.reason === SpeechSDK.ResultReason.Canceled) {
                        let cancellationDetails = SpeechSDK.CancellationDetails.fromResult(result)
                        console.log(cancellationDetails.reason)
                        if (cancellationDetails.reason === SpeechSDK.CancellationReason.Error) {
                            console.log(cancellationDetails.errorDetails)
                        }
                    }
                }
            }).catch((error) => {
                console.log(error)
                avatarSynthesizer.close()
            });
    }

    const handleInterviewStart = async (e) => {
        e.preventDefault();
        try {
            const stream = await navigator.mediaDevices.getUserMedia({
                video: {
                    width: { ideal: 1280 },
                    height: { ideal: 720 },
                    aspectRatio: 16 / 9
                }
            });

            const recorder = new MediaRecorder(stream);
            recorder.ondataavailable = event => {
                if (event.data.size > 0) {
                    chunks.current.push(event.data);
                }
            };
            recorder.onerror = error => {

            }
            startSession();

            recorder.start();
            setMediaRecorder(recorder);
            setCameraAccess(true);
        } catch (error) {
            if (error.name === 'NotAllowedError') {
                console.error('Camera access denied. Please allow camera access in your browser settings.');
                alert('Camera access was denied. Please enable camera access in your browser settings and try again.');
            } else if (error.name === 'NotFoundError') {
                console.error('No camera found. Please connect a camera and try again.');
                alert('No camera found. Please connect a camera and try again.');
            } else {
                console.error('An unexpected error occurred:', error);
                alert('An unexpected error occurred. Please check your camera settings and try again.');
            }
            setCameraAccess(false);
        }

    }

    useEffect(() => {
        if (isInterviewStarted && videoRef.current && mediaRecorder) {
            videoRef.current.srcObject = mediaRecorder.stream;
        }
    }, [isInterviewStarted, mediaRecorder])

    useEffect(() => {
        if (isInterviewStarted && avatarVideoEvent && myAvatarVideoEleRef.current) {
            const mediaPlayer = myAvatarVideoEleRef.current;
            mediaPlayer.id = avatarVideoEvent.track.kind;
            mediaPlayer.srcObject = avatarVideoEvent.streams[0];
            mediaPlayer.autoplay = true;
            mediaPlayer.playsInline = true;
            mediaPlayer.addEventListener('play', () => {
                window.requestAnimationFrame(() => { });
            });
        }
        if (isInterviewStarted && avatarAudioEvent && myAvatarAudioEleRef.current) {
            const audioPlayer = myAvatarAudioEleRef.current;
            audioPlayer.srcObject = avatarAudioEvent.streams[0];
            audioPlayer.autoplay = true;
            audioPlayer.playsInline = true;
            audioPlayer.muted = true;
        }
    }, [isInterviewStarted, avatarVideoEvent, avatarAudioEvent])


    useEffect(() => {
        if (interviewQuestion) {
            const u = new SpeechSynthesisUtterance(interviewQuestion);
            u.onend = () => {
                console.log('Speech synthesis finished');
            };

            u.onerror = (event) => {
                console.error('Speech synthesis error:', event.error);
            };
            setUtterance(u);
            speakSelectedText();
        }
    }, [interviewQuestion]);

    useEffect(() => {
        if (isListening) {
            setCandidateAnswer(transcript);
        }
    }, [transcript]);

    const handleSpeakAgain = () => {
        speakSelectedText();
    }

    const renderer = ({ minutes, seconds, completed }) => {
        if (completed) {
            handleEndInterview();
            return <span>00:00:00</span>;
        } else {
            if (minutes < 2 && !isTimeRunningOut) {
                setIsTimeRunningOut(true)
            }
            const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
            const formattedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
            const timerStyle = {
                color: isTimeRunningOut ? 'red' : 'white', 
            };
    
            return (
                <span style={timerStyle}>00:{formattedMinutes}:{formattedSeconds}</span>
            );
        }
    };

    const handleListen = () => {
        setIsListening(true);
        if (!SpeechRecognition.browserSupportsSpeechRecognition()) {
            alert("Your browser doesn't support speech recognition. Please try a different browser.");
            return;
        }
        SpeechRecognition.startListening({ continuous: true });
    }

    const handleStopListen = () => {
        setIsListening(false);
        SpeechRecognition.stopListening();
    }

    const handleReTry = () => {
        setIsListening(true);
        resetTranscript();
        setCandidateAnswer('');
        if (!SpeechRecognition.browserSupportsSpeechRecognition()) {
            alert("Your browser doesn't support speech recognition. Please try a different browser.");
            return;
        }
        SpeechRecognition.startListening({ continuous: true });
    }

    const handleNextQuestion = async () => {
        await stopSpeaking();
        const synth = window.speechSynthesis;
        synth.cancel();
        setIsListening(false);
        SpeechRecognition.stopListening();
        resetTranscript();
        setCandidateAnswer('')
        const data = {
            session_id: sessionId,
            user_response: candidateAnswer
        }
        dispatch(setLoading(true));
        axios.post('/ai_round_continue', data).then((res) => {
            if (res.success && !res.is_ai_interview_finished) {
                setInterviewQuestion(res.next_question)
            } else if (res.is_ai_interview_finished) {
                toast.dismiss(); toast.success(res.message);
                handleEndInterview();
                // navigate('/thankyou', { replace: true });
            } else {
                toast.dismiss(); toast.error(res.message)
            }
            dispatch(setLoading(false))
        }).catch((error) => {
            dispatch(setLoading(false))
            console.log(error);
            toast.dismiss(); toast.error('Something went wrong')
        })
    }

    const handleEndInterview = () => {
        if (mediaRecorder) {
            mediaRecorder.onstop = async () => {
                await stopSpeaking();
                stopSession();
                const synth = window.speechSynthesis;
                synth.cancel();
                setIsListening(false);
                SpeechRecognition.stopListening();
                resetTranscript();
                setCandidateAnswer('')
                const blob = new Blob(chunks.current, { type: 'video/webm' });
                const blobName = `video-${Date.now()}.webm`;
                try {
                    dispatch(setLoading(true));
                    const response = await uploadFileToAzure(blob, blobName);
                    if (response.success) {
                        let data = {
                            session_id: sessionId,
                            interview_link: response.url
                        }
                        axios.post('/end_technical_round', data).then((res) => {
                            dispatch(setLoading(false));
                            if (res.success) {
                                navigate('/thankyou', { replace: true })
                            }
                        }).catch(error => {
                            console.log(error);
                            dispatch(setLoading(false));
                        })
                    }
                } catch (error) {
                    alert(error.message);
                    dispatch(setLoading(false));
                }
            };
            mediaRecorder.stop();
        }

        if (videoRef.current.srcObject) {
            const tracks = videoRef.current.srcObject.getTracks();
            tracks.forEach(track => track.stop());
        }
    };

    return (
        <>
            <Loader />
            <div className='ai-interview-wrapper'>
                <div className='logo-section'>
                    <img src={HireNowXLogo} alt='Logo'></img>
                </div>
                <div className='interview-section'>
                    <div className='interview-content'>
                        <div className="heading-content">
                            <h2 className="heading">Welcome to AI Interview</h2>
                            {isInterviewStarted ? (
                                <p className='title text-center'>Job Title: {candidateDetails.job_Title}</p>
                            ) : (
                                <p className='description'>This interview aims to assess your knowledge and skills. Please answer all questions to the best of your ability.</p>
                            )}
                        </div>
                    </div>
                    {!isInterviewStarted && (
                        <div className='candidate-info'>
                            <div className='title'>Job Title: {candidateDetails.job_Title}</div>
                            <p className='name'>Candidate Name : {candidateDetails.name}</p>
                            <p className='email'>Candidate Email ID : {candidateDetails.email}</p>
                        </div>
                    )}

                    {!isInterviewStarted && (
                        <div className='instructions'>
                            <div>Instructions:</div>
                            <p>1. Read Each Question Carefully: Make sure you understand what is being asked before selecting your answer.</p>
                            <p>2. Time Management: Keep track of your time. It is advisable to allocate time evenly across all questions.</p>
                            <p>3. Selection of Answers: Choose the option that you believe is most correct. Only one option should be selected for each question.</p>
                            <p>4. Review: If time permits, review your answers before submitting the test.</p>
                            <p>5. No External Help: This test should be completed independently without assistance from others or the use of unauthorized materials.</p>
                            <p>6. Technical Requirements: Ensure your device is fully charged and that you have a stable internet connection.</p>
                            <p>7. Submission: You must submit the test before the time expires. Once submitted, you cannot change your answers.</p>
                            <p>8. Integrity: Any form of cheating or dishonesty will result in disqualification.</p>
                        </div>
                    )}

                    {!isInterviewStarted && (
                        <div className="btn-section">
                            <button onClick={handleInterviewStart}>Start Interview</button>
                        </div>
                    )}
                    {isInterviewStarted && (
                        <div className='interview-body'>
                            <div className='interview-screens'>
                                <div className='interviewer-panel'>
                                    <div className='avatar-interviwer'>
                                        <div className="avatar">
                                            <video className="myAvatarVideoElement" ref={myAvatarVideoEleRef} ></video>
                                            <audio ref={myAvatarAudioEleRef}></audio>
                                            <VscDebugRestart className='speak-again' onClick={handleSpeakAgain} />
                                        </div>
                                        <div className="text">{interviewQuestion}</div>
                                    </div>
                                    <div className='candidate'>
                                        <div className="video">
                                            <video height={'100%'} ref={videoRef} autoPlay></video>
                                            {isListening ? (<MdKeyboardVoice size={20} onClick={handleStopListen} />) : (<BsFillMicMuteFill onClick={handleListen} />)}
                                            <div className="timer">
                                                <span className='recording-indicator'></span>
                                                <Countdown
                                                    className='countdown-timer'
                                                    date={timer}
                                                    renderer={renderer}
                                                    key={timer}
                                                />
                                            </div>
                                        </div>
                                        <div className="text">{candidateAnswer}</div>
                                    </div>
                                </div>

                            </div>
                            <div className='screen-bottom'>
                                <div className='interview-buttons'>
                                    {!isTimeRunningOut && (
                                        <>
                                            <button className='retry' onClick={handleReTry}>Retry</button>
                                            <button className='save' onClick={handleNextQuestion}>Next</button>
                                        </>
                                    )}
                                    <button className='finish' onClick={handleEndInterview}>Finish</button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <div className='footer-section'>
                    <span>Powered by </span>
                    <img src={HireNowXLogo} alt='Logo'></img>
                </div>
            </div>
        </>
    );
}

export default Interview;
