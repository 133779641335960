import React, { useState, useEffect } from 'react';
import Select from "react-select";
import { useLocation } from 'react-router-dom';
import axios from '../util/api';
import { useDispatch } from 'react-redux';
import { setLoading } from '../redux/userSlice';
import toast from 'react-hot-toast';
import Modal from 'react-bootstrap/Modal';

const hiringRecommendationOptions = [
  { label: 'Yes', value: 'yes' },
  { label: 'No', value: 'no' },
]

function FinalInterviewSummary({ finalInterviewSummaryData, job_id, token }) {
  const [isOTPVisible, setIsOTPVisible] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const userIdByState = location?.state?.userId;
  const [hiringRecommendation, setHiringRecommendation] = useState(hiringRecommendationOptions[0])
  const [candidateOverAllRating, setCandidateOverAllRating] = useState(0);
  const [communicationSkillsValue, setCommunicationSkillsValue] = useState(0);
  const [technicalSkillsValue, setTechnicalSkillsValue] = useState(0);
  const [problemSolvingSkillsValue, setProblemSolvingSkillsValue] = useState(0);
  const [culturalFitSkillsValue, setCulturalFitSkillsValue] = useState(0);
  const [otpVerifyToken, setOtpVerifyToken] = useState(null);
  const [formData, setFormData] = useState({
    comments: '',
    area_for_improvement: '',
    additional_notes: ''
  })
  const [validateFormData, setValidateFormData] = useState({
    email: '',
    otp: ''
  })

  useEffect(() => {
    if (finalInterviewSummaryData) {
      setHiringRecommendation(finalInterviewSummaryData?.final_interview_fields?.hiring_recommendation === 'yes' ? { label: 'Yes', value: finalInterviewSummaryData?.final_interview_fields?.hiringRecommendation } : { label: 'No', value: finalInterviewSummaryData?.final_interview_fields?.hiringRecommendation });
      setCommunicationSkillsValue(finalInterviewSummaryData?.final_interview_fields?.communication_skills || 0);
      setTechnicalSkillsValue(finalInterviewSummaryData?.final_interview_fields?.technical_skills || 0);
      setProblemSolvingSkillsValue(finalInterviewSummaryData?.final_interview_fields?.problem_solving || 0);
      setCulturalFitSkillsValue(finalInterviewSummaryData?.final_interview_fields?.cultural_fit || 0);
      setCandidateOverAllRating(finalInterviewSummaryData?.final_interview_fields?.overall_skills || 0)
      setFormData((prevData) => {
        return (
          {
            ...prevData,
            comments: finalInterviewSummaryData?.final_interview_fields?.comment,
            area_for_improvement: finalInterviewSummaryData?.final_interview_fields?.area_of_improvement,
            additional_notes: finalInterviewSummaryData?.final_interview_fields?.additional_note
          }
        )
      })
    }
  }, [finalInterviewSummaryData]);

  const handleCandidateOverallRating = (e) => {
    setCandidateOverAllRating(e.target.value);
  };

  const handleCommunicationSkillsSliderChange = (e) => {
    setCommunicationSkillsValue(e.target.value)
  }

  const handleTechnicalSkillsSliderChange = (e) => {
    setTechnicalSkillsValue(e.target.value)
  }

  const handleProblemSolvingSkillsSliderChange = (e) => {
    setProblemSolvingSkillsValue(e.target.value)
  }

  const handleCulturalFitSliderChange = (e) => {
    setCulturalFitSkillsValue(e.target.value)
  }

  const handleFormChange = (e) => {
    const { value, name } = e.target;
    setFormData((prevData) => {
      return (
        {
          ...prevData,
          [name]: value
        }
      )
    })
  }

  const handleEdit = () => {
    if (job_id) {
      setIsEditable(true);
    } else {
      setModalShow(true);
    }
  }

  const handleValidateFormChange = (e) => {
    let { name, value } = e.target;
    setValidateFormData((prevData) => {
      return (
        {
          ...prevData,
          [name]: value
        }
      )
    })
  }

  const handleValidateEmail = (e) => {
    e.preventDefault();
    const data = {
      token: token,
      email: validateFormData.email
    }
    dispatch(setLoading(true));
    axios.post('/verify_interviewer_email', data).then((res) => {
      if (res.success) {
        toast.success(res.message);
        setIsOTPVisible(true);
      }
      dispatch(setLoading(false));
    }).catch((error) => {
      dispatch(setLoading(false));
      console.log(error)
    })
  }

  const handleValidateOTP = (e) => {
    e.preventDefault();
    const data = {
      token: token,
      email: validateFormData.email,
      otp: validateFormData.otp
    }
    dispatch(setLoading(true));
    axios.post('/verify_interviewer_otp', data).then((res) => {
      if (res.success) {
        toast.success(res.message);
        setOtpVerifyToken(res.token);
        setModalShow(false);
        setIsEditable(true);
      }
      dispatch(setLoading(false));
    }).catch((error) => {
      dispatch(setLoading(false));
      console.log(error)
    })
  }

  const handleSave = (e) => {
    e.preventDefault();
    if (userIdByState) {
      const data = {
        id: userIdByState,
        hiring_recommendation: hiringRecommendation.value,
        communication_skills: communicationSkillsValue,
        technical_skills: technicalSkillsValue,
        problem_solving: problemSolvingSkillsValue,
        cultural_fit: culturalFitSkillsValue,
        overall_skills: candidateOverAllRating,
        comment: formData.comments,
        additional_note: formData.additional_notes,
        feedback: formData.area_for_improvement,
      }
      dispatch(setLoading(true));
      axios.post('/update_final_interview_fields', data).then((res) => {
        if (res.success) {
          toast.success(res.message);
          setIsEditable(false);
        }
        dispatch(setLoading(false))
      }).catch((error) => {
        dispatch(setLoading(false))
        console.log(error)
      })
    } else {
      const data = {
        token: otpVerifyToken,
        hiring_recommendation: hiringRecommendation.value,
        communication_skills: communicationSkillsValue,
        technical_skills: technicalSkillsValue,
        problem_solving: problemSolvingSkillsValue,
        cultural_fit: culturalFitSkillsValue,
        overall_skills: candidateOverAllRating,
        comment: formData.comments,
        additional_note: formData.additional_notes,
        feedback: formData.area_for_improvement,
      }
      dispatch(setLoading(true));
      axios.post('/update_final_interview_fields_token', data).then((res) => {
        if (res.success) {
          toast.success(res.message);
          setIsEditable(false);
        }
        dispatch(setLoading(false))
      }).catch((error) => {
        dispatch(setLoading(false))
        console.log(error)
      })
    }
  }

  return (
    <div className="final-interview-wrapper">
      <div className="heading-section">
        <div className='heading'>
          {/* <h2>Final Interview</h2> */}
          <p>We would appreciate your feedback regarding the following aspects of the candidate's performance.</p>
        </div>
        {!isEditable && (
          <button className='edit-btn' onClick={handleEdit}>Edit</button>
        )}
      </div>
      <form className="feedback-section" onSubmit={handleSave}>
        <h2 className="heading">Feedback:</h2>
        <div className="overall-evaluation">
          <h2>Overall Evaluation:</h2>
          <div className='fields'>
            <div className="rate-the-candidate field">
              <label htmlFor="candidate-ratings">Rate the Candidate: </label>
              <div className='slider-container'>
                <div className="slider-labels">
                  <span>Poor</span>
                  <span>Excellent</span>
                </div>
                <input type='range' className='form-range' id='candidate-ratings' min={0} max={5} value={candidateOverAllRating} onChange={handleCandidateOverallRating} disabled></input>
                <div className="slider-labels">
                  <span>0</span>
                  <span>1</span>
                  <span>2</span>
                  <span>3</span>
                  <span>4</span>
                  <span>5</span>
                </div>
              </div>
            </div>
            <div className='hiring-recommendation field'>
              <label htmlFor='hiring_recommendation'>Hiring Recommendation :</label>
              <Select
                id='hiring_recommendation'
                value={hiringRecommendation}
                onChange={setHiringRecommendation}
                options={hiringRecommendationOptions}
                isDisabled={!isEditable}
              />
            </div>
            <div className='comments field'>
              <label htmlFor='comments'>Comments :</label>
              <textarea id='comments' name='comments' value={formData.comments} onChange={handleFormChange} disabled={!isEditable}></textarea>
            </div>
          </div>
        </div>
        <div className="strengths">
          <h2>Strengths :</h2>
          <div className='fields'>
            <div className="communication-skills field">
              <label htmlFor="communication-skill-ratings">Communication Skills : </label>
              <div className='slider-container'>
                <div className="slider-labels">
                  <span>Poor</span>
                  <span>Excellent</span>
                </div>
                <input type='range' className='form-range' id='candidate-ratings' min={0} max={5} value={communicationSkillsValue} onChange={handleCommunicationSkillsSliderChange} disabled={!isEditable}></input>
                <div className="slider-labels">
                  <span>0</span>
                  <span>1</span>
                  <span>2</span>
                  <span>3</span>
                  <span>4</span>
                  <span>5</span>
                </div>
              </div>
            </div>
            <div className="technical-skill-ratings field">
              <label htmlFor="technical-skill-ratings">Technical Skills: </label>
              <div className='slider-container'>
                <div className="slider-labels">
                  <span>Poor</span>
                  <span>Excellent</span>
                </div>
                <input type='range' className='form-range' id='candidate-ratings' min={0} max={5} value={technicalSkillsValue} onChange={handleTechnicalSkillsSliderChange} disabled={!isEditable}></input>
                <div className="slider-labels">
                  <span>0</span>
                  <span>1</span>
                  <span>2</span>
                  <span>3</span>
                  <span>4</span>
                  <span>5</span>
                </div>
              </div>
            </div>
            <div className="problem-solving-ratings field">
              <label htmlFor="problem-solving-ratings">Problem Solving Ability : </label>
              <div className='slider-container'>
                <div className="slider-labels">
                  <span>Poor</span>
                  <span>Excellent</span>
                </div>
                <input type='range' className='form-range' id='candidate-ratings' min={0} max={5} value={problemSolvingSkillsValue} onChange={handleProblemSolvingSkillsSliderChange} disabled={!isEditable}></input>
                <div className="slider-labels">
                  <span>0</span>
                  <span>1</span>
                  <span>2</span>
                  <span>3</span>
                  <span>4</span>
                  <span>5</span>
                </div>
              </div>
            </div>
            <div className="cultural-fit-ratings field">
              <label htmlFor="cultural-fit-ratings">Cultural Fit : </label>
              <div className='slider-container'>
                <div className="slider-labels">
                  <span>Poor</span>
                  <span>Excellent</span>
                </div>
                <input type='range' className='form-range' id='candidate-ratings' min={0} max={5} value={culturalFitSkillsValue} onChange={handleCulturalFitSliderChange} disabled={!isEditable}></input>
                <div className="slider-labels">
                  <span>0</span>
                  <span>1</span>
                  <span>2</span>
                  <span>3</span>
                  <span>4</span>
                  <span>5</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="further-feedback">
          <h2>Further Feedback :</h2>
          <div className='fields'>
            <div className='area-for-improvement field'>
              <label htmlFor='area-for-improvement'>Areas For Improvement :</label>
              <input type='text' id='area-for-improvement' name='area_for_improvement' value={formData.area_for_improvement} onChange={handleFormChange} disabled={!isEditable}></input>
            </div>
            <div className='additional-notes field'>
              <label htmlFor='additional-notes'>Additional Notes :</label>
              <textarea id='additional-notes' name='additional_notes' value={formData.additional_notes} onChange={handleFormChange} disabled={!isEditable}></textarea>
            </div>
          </div>
        </div>
        <div className='save-btn'>
          {isEditable && (
            <button type='submit'>Save</button>
          )}
        </div>
      </form>
      <Modal
        className='validate-final-interview-summary'
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={modalShow}
        onHide={() => setModalShow(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Validate your {isOTPVisible ? 'OTP' : 'Email'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            {isOTPVisible ? (
              <div className='otp-section field'>
                <label htmlFor='otp'>OTP :</label>
                <input type='text' name='otp' value={validateFormData.otp} onChange={handleValidateFormChange} id='otp'></input>
              </div>
            ) : (
              <div className='email-section field'>
                <label htmlFor='email'>Email :</label>
                <input type='email' name='email' value={validateFormData.email} onChange={handleValidateFormChange} id='email'></input>
              </div>
            )}
            <div className='button'>
              {isOTPVisible ? (
                <button onClick={handleValidateOTP}>Validate OTP</button>
              ) : (
                <button onClick={handleValidateEmail}>Validate Email</button>
              )}
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default FinalInterviewSummary;
