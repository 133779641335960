import axios from '../../util/api';
import React, { useState } from 'react';
import { BiHide, BiShow } from 'react-icons/bi';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { setLoading } from '../../redux/userSlice';
import toast from 'react-hot-toast';

function ConfirmPassword() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [formData, setFormData] = useState({
    Password: '',
    confirmPassword: ''
  })
  const [formDataError, setFormDataError] = useState({
    PasswordError: '',
    confirmPasswordError: ''
  })

  const { email } = location.state || { email: '' };

  function handleType(e) {
    e.preventDefault();
    setShowPassword(!showPassword);
  }

  function handleConfirmPasswordType(e) {
    e.preventDefault();
    setShowConfirmPassword(!showConfirmPassword);
  }

  const validateFormData = () => {
    const errors = {
      PasswordError: '',
      confirmPasswordError: ''
    }

    let isError = false;

    if (!formData.Password) {
      errors.PasswordError = "Please enter new Password";
      isError = true;
    }

    if (!formData.confirmPassword) {
      errors.confirmPasswordError = 'Please enter confirm Password';
      isError = true;
    }

    if (formData.Password !== formData.confirmPassword) {
      errors.confirmPasswordError = 'New password and confirm password should match';
      isError = true;
    }

    setFormDataError(errors);
    return isError;
  }

  const handleChange = (e) => {
    const { value, name } = e.target;
    setFormData((prevData) => {
      return (
        {
          ...prevData,
          [name]: value
        }
      )
    })
  }

  const handleSetPassword = (e) => {
    e.preventDefault();

    const data = {
      email,
      new_password: formData.confirmPassword
    }
    if (!validateFormData()) {
      dispatch(setLoading(true));
      axios.post('/update_password', data).then((res) => {
        if (res.success) {
          navigate('/');
          toast.dismiss();toast.success(res.message)
        } else {
          toast.dismiss();toast.error(res.message)
        }
        dispatch(setLoading(false))
      }).catch((error) => {
        console.log(error);
        toast.dismiss();toast.error('Something went wrong');
        dispatch(setLoading(false));
      })
    }
  }
  return (
    <div className='confirm-password-wrapper'>
      <div className='heading-container'>
        <h2>Confirm Password</h2>
        <div className='password-description'>
          <p>Please choose the password that hasn't been used before.</p>
          <p className='warning'>Must be at least 8 characters.</p>
        </div>
      </div>
      <form className='confirm-password-form-container' onSubmit={handleSetPassword}>
        <div className='form'>
          <div className='form-fields'>
            <div className='password_section fields'>
              <label htmlFor="password">New Password</label>
              <div className='input-field'>
                <input type={showPassword ? 'text' : 'password'} id='password' name='Password' onChange={handleChange} value={formData.Password} placeholder='Enter your password'></input>
                {!showPassword ? (<BiHide className='hide_show' onClick={handleType} />) : (<BiShow className='hide_show' onClick={handleType} />)}
              </div>
              <p className='formError'>{formDataError.PasswordError}</p>
            </div>
            <div className='confirm_password_section fields'>
              <label htmlFor="confirm_password">Confirm Password</label>
              <div className='input-field'>
                <input type={showConfirmPassword ? 'text' : 'password'} id='confirm_password' name='confirmPassword' onChange={handleChange} value={formData.confirmPassword} placeholder='confirm your password'></input>
                {!showConfirmPassword ? (<BiHide className='hide_show' onClick={handleConfirmPasswordType} />) : (<BiShow className='hide_show' onClick={handleConfirmPasswordType} />)}
              </div>
              <p className='formError'>{formDataError.confirmPasswordError}</p>
            </div>
            <div className='set-password-btn'>
              <button type='submit'>Set Password</button>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

export default ConfirmPassword