import React, { useEffect, useRef, useState } from 'react'
import Header from '../../components/Header'
import '../../scss/AddResume.scss'
import { IoIosAddCircleOutline } from "react-icons/io";
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import axios from '../../util/api';
import { setLoading } from '../../redux/userSlice';
import toast from 'react-hot-toast';

export default function AddResume() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const job_data = location?.state ? location.state?.data : null;
    const [resumeCount, setResumeCount] = useState(1);
    const [files, setFiles] = useState([]);
    const [job, setJob] = useState({});
    const [formError, setFormError] = useState({
        totalResumeForConsiderationError: '',
        uploadResumeError: ''
    });

    const fileInputRef = useRef(null);

    const handleFileSelect = (e) => {
        const isValidFile = Array.from(e.target.files).filter((file) => {
            let fileName = file.name.split('.')
            if (fileName[fileName.length - 1] === 'pdf') {
                return true
            }
            return false;
        });

        if (isValidFile.length > 0) {
            setFiles((prevData) => ([...prevData, ...isValidFile]))
        } else {
            toast.error('Upload a valid file format')
        }
    }

    useEffect(() => {
        if (job_data && job_data.job_id) {
            const data = {
                job_id: job_data.job_id
            }
            dispatch(setLoading(true))
            axios.post('/get_job_details', data).then((res) => {
                if (res.success) {
                    setJob(res.job);
                }
                dispatch(setLoading(false))
            }).catch((error) => {
                console.log(error);
                toast.dismiss(); toast.error('Internal error');
                dispatch(setLoading(false))
            })
        }
    }, [])

    const handleKeyDown = (e) => {
        if (['-', '+', 'e', 'E'].includes(e.key)) {
            e.preventDefault();
        }
    }

    const handleResumeConsiderationCountChange = (e) => {
        let { value } = e.target;
        value = value.slice(0, 3);
        setResumeCount(value)
    }

    const validateForm = () => {
        let isError = false;
        const error = {
            totalResumeForConsiderationError: '',
            uploadResumeError: ''
        }

        if (files.length === 0) {
            error.uploadResumeError = 'Please upload resume'
            isError = true;
        }

        if (resumeCount > files?.length || Number(resumeCount) <= 0) {
            error.totalResumeForConsiderationError = 'Total resumes for consideration cannot be more than uploaded resumes or zero';
            isError = true;
        }

        setFormError(error);
        return isError;
    }


    const handleResumeSubmit = (e) => {
        e.preventDefault();
        if (!validateForm()) {
            const data = new FormData();
            data.append('job_description', job.job_description);
            data.append('document_count', resumeCount);
            data.append('job_id', job_data.job_id);

            files.forEach((file, index) => {
                data.append('pdf_files', file, `file${index}.pdf`);
            });

            if (files.length) {
                dispatch(setLoading(true));
                axios.post('/api_analyze', data).then((res) => {
                    if (res.success) {
                        navigate('/corporateDashboard/jobs/candidates', { state: { job_id: job_data.job_id } })
                    }
                    dispatch(setLoading(false));
                }).catch((error) => {
                    dispatch(setLoading(false));
                    console.log(error);
                });
            } else {
                toast.dismiss(); toast.error('Please upload resumes')
            }
        }
    }
    return (
        <div className='add-resume-wrapper'>
            <Header heading={'Add Resume'} description={'Add Resume To complete the hiring process.'} />
            <div className="resume-container">
                <form onSubmit={handleResumeSubmit}>
                    <div className="first-section">
                        <div className="form-fields">
                            <label htmlFor="jobTitle">Job Title:</label>
                            <input type="text" defaultValue={job?.job_title} name="jobTitle" id="jobTitle" autoComplete='on' disabled />
                            <span></span>
                        </div>
                        <div className="form-fields">
                            <label htmlFor="location">Location:</label>
                            <input type="text" defaultValue={job?.job_location} name="location" id="location" autoComplete='on' disabled />
                            <span></span>
                        </div>
                    </div>

                    <div className="form-fields">
                        <label htmlFor="jobDesciption">Job Description:</label>
                        <textarea type="text" rows={5} defaultValue={job?.job_description} name="jobDesciption" disabled id="jobDesciption" autoComplete='on' />
                        <span></span>
                    </div>
                    <div className="form-fields">
                        <label htmlFor="noOfResumes">Total resumes for consideration:</label>
                        <input type="number" min={1} max={999} value={resumeCount} onChange={handleResumeConsiderationCountChange} name="noOfResumes" id="noOfResumes" onKeyDown={handleKeyDown} autoComplete='on' />
                        <span className='form-error'>{formError.totalResumeForConsiderationError}</span>
                    </div>
                    <div className="form-fields">
                        <label>Upload Resumes:</label>
                        <div className='upload-resume-container'>
                            <input name='logo' hidden={true} id='logo' accept=".pdf" ref={fileInputRef} type='file' multiple={true} onChange={handleFileSelect}></input>
                            <div className='logo-input-div' onClick={() => fileInputRef.current.click()} >
                                <div className='logo-with-text text-center'>
                                    <IoIosAddCircleOutline size={22} />
                                    <p>Drag and drop single of bulk resume here or browse</p>
                                </div>
                                <div className='text-center'>
                                    {files?.map((file, index) => {
                                        return <p key={index}>{file.name}</p>
                                    })}
                                </div>
                            </div>
                        </div>
                        <span className='form-error'>{formError.uploadResumeError}</span>
                    </div>

                    <div className='resume-buttons'>
                        {/* <button className='back' type='button' onClick={() => navigate('/corporateDashboard/jobs')}>Back</button> */}
                        <button className='submit' type='submit'>Submit</button>
                    </div>
                </form>
            </div>
        </div>
    )
}
