import axios from "axios";
import { backend_url } from "./config";
import TokenService from "./TokenService";
const instance = axios.create({
    baseURL: backend_url,
    headers: {
        "Content-Type": 'multipart/form-data'
    }
});

instance.interceptors.request.use(
    (config) => {
        const token = TokenService.getAccessToken();
        if (token) {
            config.headers["Authorization"] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    },
);

instance.interceptors.response.use(
    (res) => res.data
)

export default instance;