import React, { useRef } from 'react'
import Header from '../../components/Header'
import { TiArrowBackOutline } from "react-icons/ti";
import '../../scss/ViewTemplate.scss'
// import companyLogo from '../../assests/images/company-logo.png';
import { CiGlobe } from "react-icons/ci";
import { CiLocationOn } from "react-icons/ci";
import { useLocation, useNavigate } from 'react-router-dom';
import axios from '../../util/api';
import { toPng } from 'html-to-image';

export default function ViewTemplate() {
    const navigate = useNavigate();
    const location = useLocation();
    const componentRef = useRef();
    const data = location?.state ? location.state.data : {};
    const jobType = location?.state ? location.state.jobType : { value: '' };
    const remoteOption = location?.state ? location.state.remoteOption : {};
    const primarySkills = location?.state ? location.state.primarySkills : [];
    const secondarySkills = location?.state ? location.state.secondarySkills : [];
    const jobResponsibilities = location?.state ? location.state.jobResponsibilities : [];
    const additional_requirements = location?.state ? location.state.additionalRequirements : [];
    const companyLogo = location?.state ? location.state.companyLogo : null;
    const minSalaryRange = location?.state ? location.state.minSalaryValue : '';
    const maxSalaryRange = location?.state ? location.state.maxSalaryValue : '';

    const downloadTemplate = () => {
        if (true) {
            const data1 = {
                job_title: data.jobTitle,
                location: data.location,
                email: data.email,
                number_of_opening: data.noofOpening,
                job_type: jobType?.value,
                remote_option: remoteOption?.value,
                primary_skill: JSON.stringify(primarySkills),
                secondary_skill: JSON.stringify(secondarySkills),
                job_responsibility: JSON.stringify(jobResponsibilities),
                salary_min_range: minSalaryRange,
                salary_max_range: maxSalaryRange,
                candidate_exp: data.experience,
                additional_requirements: JSON.stringify(additional_requirements),
                job_description: data.jobDescription,
                company_name: data.companyName,
                company_email: data.companyEmail
            }

            axios.post('/create_job', data1).then(res => {
                if (res.success) {
                    handleDownloadImage();
                }
            }).catch(error => {
                console.log(error);

            })
        }
    }

    const handleDownloadImage = () => {
        if (componentRef.current === null) {
            return;
        }

        toPng(componentRef.current)
            .then((dataUrl) => {
                const link = document.createElement('a');
                link.href = dataUrl;
                link.download = 'post-template.png';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                navigate('/corporateDashboard/jobs')
            })
            .catch((error) => {
                console.error('Error exporting image:', error);
            });
    };
    return (
        <div className="view-template-wrapper">
            <Header heading={'Job Posting Template'} />
            <div className="back-button">
                <span onClick={() => navigate('/corporateDashboard/jobs/createJob')}><TiArrowBackOutline /></span>
            </div>
            <div className="view-template-content" ref={componentRef}>
                <div className="view-template-data">
                    <div className="company-logo">
                        <img src={companyLogo} alt="img" />
                    </div>
                    <div className="hiring-content">
                        <div className="sub-heading">IS</div>
                        <div className="heading">HIRING!</div>
                        <div className="job-title">{data.jobTitle}</div>
                        <div className='experience'>Experience: {data.experience} Year</div>
                        <div className='type'>Immediate Joiner</div>
                    </div>

                    <div className='hiring-info'>
                        <div className="details">
                            <div className='email'>
                                <div className="icon">
                                    <CiGlobe />
                                </div>
                                <div className="info">
                                    <div className='heading'>Email your cv</div>
                                    <div className='detail'>{data.email}</div>
                                </div>
                            </div>
                            <div className='location'>
                                <div className="icon">
                                    <CiLocationOn />
                                </div>
                                <div className="info">
                                    <div className='heading'>Location</div>
                                    <div className='detail'>{data.location}</div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="apply-button">
                            <button>Apply now</button>
                        </div> */}
                    </div>
                </div>
            </div>
            <div className='download-template-button'>
                <button onClick={downloadTemplate}>Download Template</button>
            </div>
        </div>
    )
}
