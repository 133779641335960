import React from 'react'
import '../../scss/Auth.scss';
import HireNowXLogo from '../../assests/images/HireNowXLogo.png';
import loginPageLogo from '../../assests/images/loginBackground.png'
import verifyEmailLogo from '../../assests/images/otpVerifyLogo.png'
import confirmPasswordLogo from '../../assests/images/ResetPasswordLogo.png'
import { Link, useNavigate } from 'react-router-dom';
import Login from './Login';
import Signup from './Signup';
import ForgotPassword from './ForgotPassword';
import ResetPassword from './ResetPassword';
import VerifyEmail from './VerifyEmail';
import ConfirmEmail from './CofirmPassword';

export default function Auth({ page, type }) {
    const navigate = useNavigate();
    return (
        <>
            <div className='auth-page'>
                <div className='auth-logo-section'>
                    <img onClick={()=>navigate('/')} src={HireNowXLogo} alt='Logo'></img>
                </div>
                <div className="auth-container">
                    <div className='auth-left'>
                        {(page === 'login' || page === 'signup') && (<img src={loginPageLogo} alt='img'></img>)}
                        {(page === 'verifyEmail') && (<img src={verifyEmailLogo} alt='img'></img>)}
                        {(page === 'confirmPassword') && (<img src={confirmPasswordLogo} alt='img'></img>)}
                    </div>
                    <div className="auth-right">
                        <div className="auth-right-content">
                            {page === 'login' && <Login type={type} />}
                            {page === 'signup' && <Signup />}
                            {page === 'forgotpassword' && <ForgotPassword />}
                            {page === 'resetpassword' && <ResetPassword />}
                            {page === 'verifyEmail' && <VerifyEmail />}
                            {page === 'confirmPassword' && <ConfirmEmail />}
                        </div>
                        {/* <div className='sign-up-guide'>
                            <p>
                                {page === 'login'
                                    ? <>Don't have an account yet? <Link to="/signup">Sign Up</Link></>
                                    : <>Already have an account? <Link to="/">Log In</Link></>
                                }
                            </p>
                        </div> */}
                    </div>
                </div>
            </div>
        </>
    )
}
