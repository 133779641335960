import React, { useState } from 'react';
import { BiHide, BiShow } from 'react-icons/bi';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { setLoading, setUser } from '../../redux/userSlice';
import { toast } from 'react-hot-toast';
import axios from '../../util/api';
import { Link } from 'react-router-dom';
import TokenService from '../../util/TokenService';

export default function Login({ type }) {
    const [userType, setUserType] = useState(type);
    const [formData, setFormData] = useState({ email: '', Password: '' });
    const [formErrors, setFormErrors] = useState({ emailError: '', passwordError: '' });
    const [showPassword, setShowPassword] = useState(false);
    const [showCorporatePassword, setShowCorporatePassword] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [corporateEmail, setCorporateEmail] = useState('');
    const [corporateEmailError, setCorporateEmailError] = useState('');
    const [corporatePasswordError, setCorporatePasswordError] = useState('');
    const [corporatePassword, setCorporatePassword] = useState('');
    const [isNewAdmin, setIsNewAdmin] = useState(true);
    const [isEmailVerified, setIsEmailVerified] = useState(false);

    const validateLoginForm = () => {
        var errors = { emailError: '', passwordError: '' };
        let { email, Password } = formData;
        var isError = false;
        if (!email.match(/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i)) {
            errors.emailError = 'Enter Valid email';
            isError = true;
        }

        if (Password.length < 4) {
            errors.passwordError = 'Enter valid password';
            isError = true;
        }

        setFormErrors(errors);
        return isError;
    }

    function handleChange(e) {
        const { name, value } = e.target;
        setFormData((prevData) => {
            return ({
                ...prevData,
                [name]: value
            });
        });
    }

    function handleType(e) {
        e.preventDefault();
        setShowPassword(!showPassword);
    }

    function handleCorporatePasswordType(e) {
        e.preventDefault();
        setShowCorporatePassword(!showCorporatePassword);
    }

    const handleUserType = (userType) => {
        setUserType(userType);
    }

    const handleLoginApi = (data) => {
        dispatch(setLoading(true));
        axios.post('/login', data).then((res) => {
            dispatch(setLoading(false));
            if (res.success) {
                const nameArray = res.name?.split(' ');
                let user = {
                    first_name: nameArray[0] || '',
                    last_name: nameArray[1] || '',
                    email: res.email,
                    user_id: res.user_id,
                    role: res.role,
                    org_domain: res.org_domain
                };
                TokenService.setUser(user);
                TokenService.setAccessToken(res.token);
                // dispatch(setUser(user));
                if (res.role === 'generalUser') {
                    navigate('/dashboard');
                } else if (res.role === 'superAdmin') {
                    navigate('/superAdmin');
                } else if (res.role === 'corporateAdmin') {
                    navigate('/corporateDashboard')
                }
                toast.dismiss(); toast.success(res.message);
            }
        }).catch((error) => {
            if (error?.response) {
                toast.dismiss(); toast.error(error?.response?.data?.error);
            }
            else {
                toast.dismiss(); toast.error('Something went wrong')
            }
            dispatch(setLoading(false));
        });
    }

    function handleGeneralUserSubmit(e) {
        e.preventDefault();
        if (!validateLoginForm()) {
            const data = {
                email: formData.email,
                password: formData.Password,
                type: userType
            };
            handleLoginApi(data);
        }
    }

    const handleCorporateUserSubmit = (e) => {
        e.preventDefault();
        const regex = /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;
        if (!regex.test(corporateEmail)) {
            setCorporateEmailError('Please enter valid email')
        } else {
            setCorporateEmailError('')
            if (isNewAdmin && !isEmailVerified) {
                const data = {
                    email: corporateEmail
                }
                setCorporateEmailError('');
                dispatch(setLoading(true))
                axios.post('/check_email', data).then((res) => {
                    if (res.success) {
                        setIsEmailVerified(true);
                        setIsNewAdmin(res.is_new_admin);
                        if (res.is_new_admin) {
                            navigate('/verifyEmail', { state: { email: corporateEmail } })
                        } else if (res.role === 'corporateUser') {
                            navigate('/verifyEmail', { state: { email: corporateEmail, userType: res.role } })
                        }
                    } else {
                        toast.dismiss(); toast.error('Invalid Email ID')
                    }
                    dispatch(setLoading(false))
                }).catch((error) => {
                    dispatch(setLoading(false))
                    console.log(error);
                    toast.dismiss(); toast.error('Something went wrong!')
                })
            } else {
                if (corporatePassword.length < 4) {
                    setCorporatePasswordError('Please enter valid password')
                } else {
                    setCorporatePasswordError('');
                    const data = {
                        email: corporateEmail,
                        password: corporatePassword,
                        type: userType
                    };
                    handleLoginApi(data)
                }
            }
        }
    }

    return (
        <>
            <div className="login-form-wrapper">
                {userType === 'general' && (
                    <form className='general-login-form-container' onSubmit={handleGeneralUserSubmit}>
                        <div className='form'>
                            <div className='login-heading'>
                                <h2>Welcome!</h2>
                                <p className='login-heading'>Login with your credentials</p>
                            </div>
                            <div className='form-fields'>
                                <div className='email_section fields'>
                                    <label htmlFor="email">Email</label>
                                    <input type='email' id='email' name='email' onChange={handleChange} value={formData.email} autoComplete="off" placeholder='Enter your Email Address'></input>
                                    <span className='form-error'>{formErrors.emailError}</span>
                                </div>

                                <div className='password_section fields'>
                                    <label htmlFor="password">Password</label>
                                    <input type={showPassword ? 'text' : 'password'} id='password' name='Password' onChange={handleChange} value={formData.Password} placeholder='Enter your password'></input>
                                    {!showPassword ? (<BiHide className='hide_show' onClick={handleType} />) : (<BiShow className='hide_show' onClick={handleType} />)}
                                    <div className='d-flex justify-content-between'>
                                        <span className='form-error'>{formErrors.passwordError}</span>
                                        <p className='forgot-password'><Link to='/forgotpassword'>Forgot Password?</Link></p>
                                    </div>
                                </div>
                                <div className='login-btn'>
                                    <button type='submit'>Login</button>
                                </div>
                            </div>
                        </div>
                    </form>
                )}

                {userType === 'corporate' && (
                    <form className='corporate-login-form-container' onSubmit={handleCorporateUserSubmit}>
                        <div className='form'>
                            <div className='login-heading'>
                                <h2>Welcome!</h2>
                                <p className='login-heading'>Login with your credentials</p>
                            </div>
                            <div className='form-fields'>
                                <div className='email_section fields'>
                                    <label htmlFor="email">Email</label>
                                    <input type='email' id='email' name='corporateLoginEmail' onChange={(e) => setCorporateEmail(e.target.value)} value={corporateEmail} autoComplete="off" placeholder='Enter your Email Address'></input>
                                    <span className='form-error'>{corporateEmailError}</span>
                                </div>
                                {!isNewAdmin && (
                                    <div className='password_section fields'>
                                        <label htmlFor="password">Password</label>
                                        <input type={showCorporatePassword ? 'text' : 'password'} id='password' name='Password' onChange={(e) => setCorporatePassword(e.target.value)} value={corporatePassword} placeholder='Enter your password'></input>
                                        {!showCorporatePassword ? (<BiHide className='hide_show' onClick={handleCorporatePasswordType} />) : (<BiShow className='hide_show' onClick={handleCorporatePasswordType} />)}
                                        <div className='d-flex justify-content-between'>
                                            <span className='form-error'>{corporatePasswordError}</span>
                                            {/* <p className='forgot-password'><Link to='/forgotpassword'>Forgot Password?</Link></p> */}
                                        </div>
                                    </div>
                                )}
                                <div className='verify-email-btn'>
                                    <button type='submit'>Verify Your Email</button>
                                </div>
                            </div>
                        </div>
                    </form>
                )}
            </div>
        </>
    );
}
