import React from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

function BarChart({barData}) {
    const data = {
        labels: ['Answered', 'Correct', 'Wrong', 'Skipped'],
        datasets: [
          {
            label: ['Answered', 'Correct', 'Wrong', 'Skipped'],
            data: barData,
            backgroundColor: [
                '#3290ED',
                '#49AF4F',
                '#FE1B06',
                '#FEC006'
              ],
              borderColor: [
                '#3290ED',
                '#49AF4F',
                '#FE1B06',
                '#FEC006'
              ],
            borderWidth: 1,
            barThickness: 50
          },
        ],
      };
    
      const options = {
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          x: {
            grid: {
              display: false,
            },
          },
          y: {
            grid: {
              display: true,
            },
            ticks: {
                stepSize: 10
            }
          },
        },
      };
  return (
    <div className='bar-chart-wrapper'>
        <Bar data={data} options={options}></Bar>
    </div>
  )
}

export default BarChart