import React, { useState, useEffect } from 'react';
import '../../scss/UpcomingInterviews.scss';
import Header from '../../components/Header';
import { Table } from 'react-bootstrap';
import axios from '../../util/api';
import Select from "react-select";
import { useDispatch } from 'react-redux';
import { setLoading } from '../../redux/userSlice';
import toast from 'react-hot-toast';

const interviewType = [
    { value: "all", label: "All" },
    { value: "ai_interview", label: "AI Interview" },
    { value: "final_interview", label: "Final Interview" }
];

function UpcomingInterviews() {
    const dispatch = useDispatch();
    const [upcomingInterviewType, setUpcomingInterviewType] = useState(interviewType[0]);
    const [upcomingInterviews, setUpcomingInterviews] = useState([]);
    const [selectAll, setSelectAll] = useState(false);

    useEffect(() => {
        const data = {
            type: upcomingInterviewType.value
        }
        dispatch(setLoading(true));
        axios.post('get_upcoming_interviews', data).then((res) => {
            if (res.success) {
                setUpcomingInterviews(res.upcoming_interviews)
            }
            dispatch(setLoading(false))
        }).catch((error) => {
            dispatch(setLoading(false))
            console.log(error)
        })
    }, [upcomingInterviewType]);

    const handleSelectAll = (e) => {
        setSelectAll(e.target.checked);
        let updatedList = [...upcomingInterviews];
        setUpcomingInterviews(updatedList.map(item => ({ ...item, selected: e.target.checked })))
    }

    const handleSingleSelect = (e, index) => {
        let updatedList = [...upcomingInterviews];
        updatedList[index].selected = e.target.checked;
        setSelectAll(updatedList.every(item => item.selected))
        setUpcomingInterviews(updatedList);
    }

    const handleCancelMeeting = () => {
        const selectedCandidates = upcomingInterviews.filter((candidate) => candidate.selected);
        if (selectedCandidates.length > 0) {
            const data = selectedCandidates.map((candidate) => ({
                id: candidate.id,
                type: candidate.status
            }));

            dispatch(setLoading(true))
            axios.post('/cancel_interview', { cancel: JSON.stringify(data) }).then((res) => {
                if (res.success) {
                    toast.success(res.message);
                    const updatedData = upcomingInterviews.filter(candidate => !candidate.selected);
                    setUpcomingInterviews(updatedData)
                }
                dispatch(setLoading(false))
            }).catch((error) => {
                dispatch(setLoading(false))
                console.log(error)
            })
        } else {
            toast.dismiss();
            toast.error('Select candidate');
        }
    }

    return (
        <div className='upcoming-interviews-wrapper'>
            <Header heading={'Upcoming Interviews'} description={'Stay informed and prepared with our lineup of upcoming interviews.'} />
            <div className='select-box d-flex justify-content-end'>
                <Select
                    value={upcomingInterviewType}
                    onChange={setUpcomingInterviewType}
                    options={interviewType}
                    name='upcoming-interview-type'
                    className='pt-4 w-25'
                />
            </div>
            <Table responsive="md" style={{ marginTop: '24px' }}>
                <thead>
                    <tr>
                        <th><input type="checkbox" checked={selectAll} onChange={handleSelectAll} /> Select All</th>
                        <th className='job_id'>Job ID</th>
                        <th className='job_title'>Job Title</th>
                        <th className='name'>Name</th>
                        <th className='email'>Email</th>
                        <th className='date'>Date</th>
                        <th className='time'>Time</th>
                        <th className='status'>Status</th>
                        <th className='resume'>Resume</th>
                    </tr>
                </thead>
                <tbody>
                    {upcomingInterviews.map((upcomingInterview, index) => {
                        return (
                            <tr key={index}>
                                <td><input type="checkbox" onChange={(e) => handleSingleSelect(e, index)} checked={upcomingInterview.selected} ></input></td>
                                <td>{upcomingInterview.job_id}</td>
                                <td>{upcomingInterview.job_title}</td>
                                <td>{upcomingInterview.username}</td>
                                <td>{upcomingInterview.email_id}</td>
                                <td>{upcomingInterview.date}</td>
                                <td>{upcomingInterview.time}</td>
                                <td>{upcomingInterview.status}</td>
                                {upcomingInterview.resume ? (
                                    <td><a href={upcomingInterview.resume} target="_blank" rel="noopener noreferrer">
                                        view resume
                                    </a></td>
                                ) : (
                                    <td>Not Found</td>
                                )}
                            </tr>
                        )
                    })}
                </tbody>
            </Table>
            <div className='cancel-meeting-btn d-flex justify-content-center'>
                <button onClick={handleCancelMeeting}>Cancel Meeting</button>
            </div>
        </div>
    )
}

export default UpcomingInterviews