import React, { useEffect, useState } from 'react';
import GaugeChart1 from 'react-gauge-chart';
import '../scss/GaugeChart.scss'

function GaugeChart(props) {
  const [percentage, setpercentage] = useState(0);
  useEffect(() => {
    if (props?.data) {
      setpercentage(props.data / 100);
    }
  }, [props])

  return (
    <div className="gauge-chart-wrapper" style={{ width: '100%', maxWidth: '500px' }}>
      <GaugeChart1
        id="gauge-chart5"
        nrOfLevels={200}
        arcsLength={[1]}
        colors={['#0A84ED']}
        percent={percentage}
        arcPadding={0.02}
        style={{ width: '100%' }}
      />
    </div>
  );
}

export default GaugeChart;
