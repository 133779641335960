import * as CryptoJS from 'crypto-js';

let key = process.env.REACT_APP_CRYPTO_KEY;
class TokenService {

    encryptData(data) {
        return CryptoJS.AES.encrypt(JSON.stringify(data), key).toString();
    }

    decryptData(encryptedData) {
        if(encryptedData){
            const bytes = CryptoJS.AES.decrypt(encryptedData, key);
            return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        }
        return null;
    }

    getAccessToken() {
        const encryptedData = localStorage.getItem("token");
        if (encryptedData) {
            return encryptedData;
        }
        return null;
    }

    setAccessToken(token) {
        localStorage.setItem("token", token);
    }

    getUser() {
        const encryptedData = localStorage.getItem("user");
        if (encryptedData) {
            return this.decryptData(encryptedData);
        }
        return null;
    }
    setUser(user) {
        const encryptedData = this.encryptData(user);
        localStorage.setItem("user", encryptedData);
    }

    removeUser() {
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        sessionStorage.removeItem('questions');
    }

    decryptToken(encryptedDataBase64) {
        let newkey = CryptoJS.enc.Utf8.parse(key.padEnd(32).substring(0, 32));  // Pad or truncate key to 32 bytes

        const encryptedBytes = CryptoJS.enc.Base64.parse(encryptedDataBase64);

        const decryptedBytes = CryptoJS.AES.decrypt({ ciphertext: encryptedBytes }, newkey, {
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7,
        });

        const decryptedData = decryptedBytes.toString(CryptoJS.enc.Utf8);
        return JSON.parse(decryptedData);
    }

}
const tokenService = new TokenService();
export default tokenService;
