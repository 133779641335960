import React, { useState } from 'react';
import previousSignLogo from '../../assests/images/previousSignLogo.png'
import { useLocation, useNavigate, Link } from 'react-router-dom';
import axios from '../../util/api';
import toast from 'react-hot-toast';
import { setLoading } from '../../redux/userSlice';
import { useDispatch } from 'react-redux';
import tokenService from '../../util/TokenService';

function VerifyEmail() {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const { email, userType } = location.state || { email: '', userType: '' };
    const [otpInputChanges, setOtpInputChanges] = useState({
        0: '', 1: '', 2: '', 3: '', 4: '', 5: ''
    });

    const handleOTPChange = (e, index) => {
        const { value } = e.target;
        if (/^[0-9]?$/.test(value)) {
            setOtpInputChanges({
                ...otpInputChanges,
                [index]: value
            });
            if (value && index < 5) {
                document.getElementById(`otp-input-${index + 1}`).focus();
            }
        }
    };

    const handleResendOTP = () => {
        dispatch(setLoading(true));
        axios.post('resend_otp', { email }).then(res => {
            dispatch(setLoading(false));
            if (res.success) {
                toast.dismiss();toast.success('Otp sent successfully!');
            }
        }).catch(error => {
            dispatch(setLoading(false));
            console.log(error);
        })
    }

    const getOtpString = () => {
        return Object.values(otpInputChanges).join('');
    };

    const handleSubmitOtp = () => {
        const otp = getOtpString();
        const data = {
            otp,
            email
        }

        dispatch(setLoading(true));
        axios.post('/authenticate_otp', data).then((res) => {
            if (res.success) {
                if (userType === 'corporateUser') {
                    const nameArray = res.name?.split(' ');
                    let user = {
                        first_name: nameArray[0] || '',
                        last_name: nameArray[1] || '',
                        email: res.email,
                        user_id: res.user_id,
                        role: res.role,
                        org_domain: res.org_domain
                    };
                    tokenService.setUser(user);
                    tokenService.setAccessToken(res.token);
                    navigate('/corporateDashboard', { replace: true })
                    toast.dismiss();toast.success('Logged In Successfully!')
                } else {
                    navigate('/confirmPassword', { state: { email: email }, replace: true });
                    toast.dismiss();toast.success('OTP verified')
                }

            } else {
                toast.dismiss();toast.error(res.message)
            }
            dispatch(setLoading(false))
        }).catch((error) => {
            console.log(error);
            toast.dismiss();toast.error('Something went wrong');
            dispatch(setLoading(false))
        })
    }
    return (
        <div className='verify-email-wrapper'>
            <div className='previous-sign-logo-container' onClick={() => navigate('/')}>
                <img src={previousSignLogo} alt="logo"></img>
            </div>
            <div className='verify-email-container'>
                <div className='verify-email-heading'>
                    <h2>Verify you email!</h2>
                    <p>we sent a code to {email}</p>
                </div>
                <div className='otp-input-section'>
                    <div className='otp-inputs'>
                        {[0, 1, 2, 3, 4, 5].map(index => (
                            <input
                                key={index}
                                id={`otp-input-${index}`}
                                type='text'
                                maxLength={1}
                                value={otpInputChanges[index]}
                                onChange={(e) => handleOTPChange(e, index)}
                                onFocus={(e) => e.target.select()}
                                autoComplete='off'

                            />
                        ))}
                    </div>
                    <p className='resend-text'>Didn't get a code? <button onClick={handleResendOTP}>Click to resend</button></p>
                    <button className='continue-btn' onClick={handleSubmitOtp}>Continue</button>
                </div>
            </div>
        </div>
    )
}

export default VerifyEmail