import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { Modal } from 'react-bootstrap';
import { Accordion } from 'react-bootstrap';
import Select from "react-select";
import toast from 'react-hot-toast';
import axios from '../../../util/api';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../../redux/userSlice';
import { useNavigate } from 'react-router-dom';

const gender = [
    { label: 'Male', value: 'Male' },
    { label: 'Female', value: 'Female' },
]

export default function MCQCandidates({ candidates, job_id }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [selectAll, setSelectAll] = useState(false);
    const [users, setUsers] = useState([]);
    const [modalShow, setModalShow] = useState(false);
    const [interviewerGender, setInterviewerGender] = useState(gender[0]);
    const [formData, setFormData] = useState({ ai_link_validity: '', ai_duration: '' });
    const [formError, setFormError] = useState({ linkValidityError: '', ai_durationError: '' });

    const handleSelectAll = (e) => {
        setSelectAll(e.target.checked);
        let updatedList = [...users];
        setUsers(updatedList.map(item => ({ ...item, selected: e.target.checked })))
    }
    const handleSingleSelect = (e, index) => {
        let updatedList = [...users];
        updatedList[index].selected = e.target.checked;
        setSelectAll(updatedList.every(item => item.selected))
        setUsers(updatedList);
    }

    const handleFormChange = (e) => {
        let { value, name } = e.target;
        if (name === 'ai_link_validity' || 'ai_duration') {
            value = value.slice(0, 2)
        }
        setFormData({ ...formData, [name]: value })
    }

    const handleKeyDown = (e) => {
        if (['-', '+', 'e', 'E'].includes(e.key)) {
            e.preventDefault();
        }
    }

    const validateForm = () => {
        const error = {
            ai_durationError: '',
            linkValidityError: ''
        }

        let isError = false;
        if (!formData.ai_duration) {
            error.ai_durationError = 'Please enter duration';
            isError = true;
        }

        if (!formData.ai_link_validity) {
            error.linkValidityError = "Please enter link Validity";
            isError = true;
        }

        setFormError(error);
        return isError;
    }

    const handleSendLink = (e) => {
        e.preventDefault();
        if (!validateForm()) {
            let selectedEmails = users.filter(candidate => candidate.selected).map((data) => data.email_id);
            if (selectedEmails.length) {
                const data = {
                    candidates: JSON.stringify(selectedEmails),
                    job_id: job_id,
                    testDuration: formData.ai_duration,
                    linkValidity: formData.ai_link_validity * 60,
                    interviewerGender: interviewerGender.value
                }
                dispatch(setLoading(true));
                axios.post('/generate_interview_link', data).then((res) => {
                    if (res.success) {
                        toast.dismiss();toast.success('Interview link send successfully')
                        setModalShow(false);
                    } else {
                        toast.dismiss();toast.error('Error in generating link')
                    }
                    dispatch(setLoading(false))
                }).catch((error) => {
                    toast.dismiss();toast.error('Internal error')
                    dispatch(setLoading(false))
                    console.log(error)
                })
            }
        }
    }

    const openSendLink = () => {
        let selectedEmails = users.filter(candidate => candidate.selected);
        if (selectedEmails.length > 0) {
            setModalShow(true);
        } else {
            toast.dismiss();toast.error('Select atleast One Employye')
        }
    }

    useEffect(() => {
        setUsers(candidates.map(item => ({ ...item, selected: false })))
    }, [candidates])

    const handleReject = () => {
        console.log('hello');
        let selectedIds = users.filter(candidate => candidate.selected).map((data) => data.id);
        if (selectedIds.length > 0) {
            dispatch(setLoading(true));
            axios.post('/send_rejection_emails', { candidate_ids: JSON.stringify(selectedIds) }).then(res => {
                console.log(res);
                dispatch(setLoading(false));
                if (res.success) {
                    toast.dismiss();
                    toast.success('Mail sent successfully');
                }
            }).catch(error => {
                dispatch(setLoading(false));
                toast.dismiss();
                toast.error('Something went wrong');
            })
        } else {
            toast.dismiss();
            toast.error('Select Candidate');
        }
    }
    return (
        <div className='mcq-candidates-wrapper'>
            <div className="user-table">
                <Table responsive="md">
                    <thead>
                        <tr>
                            <th className='text-center'><input type="checkbox" checked={selectAll} onChange={handleSelectAll} /></th>
                            <th className='text-center'>S.No</th>
                            <th className='text-center'>Name</th>
                            <th className='text-center'>Email</th>
                            <th className='text-center'>Contact Number</th>
                            <th className='text-center'>MCQ Score</th>
                        </tr>
                    </thead>
                    <tbody>
                        {users.map((item, index) => {
                            return (
                                <tr key={index}>
                                    <td className='text-center'><input type="checkbox" onChange={(e) => handleSingleSelect(e, index)} checked={item.selected} /></td>
                                    <td className='text-center'>{index + 1}</td>
                                    <td className='text-center' style={{color: '#1875fe', textDecoration: 'underline', cursor: 'pointer'}} onClick={() => navigate('/candidateSummary', { state : {userId: item.id, job_id}})}>{item.username}</td>
                                    <td className='text-center'>{item.email_id}</td>
                                    <td className='text-center'>{item.whatsapp_number}</td>
                                    <td className='text-center'>{item.mcq_score}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            </div>
            <div className="bottom">
                <button onClick={openSendLink}>Send Avatar Link</button>
                <button onClick={handleReject}>Rejected Candidates</button>
            </div>
            <Modal
                className='send_link_modal'
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={modalShow}
                onHide={() => setModalShow(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Send AI Avatar Interview Link</Modal.Title>
                </Modal.Header>
                <Modal.Body className='d-flex flex-column gap-4'>
                    <Accordion defaultActiveKey="0">
                        <form>
                            <Accordion.Item eventKey="0">
                                <Accordion.Body>
                                    <div className='form-fields'>
                                        <div className='mcq-duration field'>
                                            <div>
                                                <label htmlFor='ai_duration'>Duration (In Minutes)<span>*</span></label>
                                                <input type='number' onChange={handleFormChange} id='ai_duration' name='ai_duration' value={formData.ai_duration} onKeyDown={handleKeyDown}></input>
                                                <p className='form-error'>{formError.ai_durationError}</p>
                                            </div>
                                            {/* <Select
                                                value={timeType}
                                                onChange={setTimeType}
                                                options={timeOptions}
                                            /> */}
                                        </div>
                                        <div className=' field'>
                                            <label htmlFor='ai_link_validity'>Link Validity (In Hours)<span>*</span></label>
                                            <input type='number' onChange={handleFormChange} min={1} max={99} id='ai_link_validity' name='ai_link_validity' value={formData.ai_link_validity} onKeyDown={handleKeyDown}></input>
                                            <p className='form-error'>{formError.linkValidityError}</p>
                                        </div>
                                        <div className=' field'>
                                            <label htmlFor='ai_link_validity'>Gender<span>*</span></label>
                                            <Select
                                                value={interviewerGender}
                                                onChange={setInterviewerGender}
                                                options={gender}
                                            />
                                        </div>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                        </form>
                    </Accordion>
                    <div className='send-test-link-btn'>
                        <button className='p-2' onClick={handleSendLink}>Send Link</button>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}
