import axios from './../util/api';
import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { setLoading } from '../redux/userSlice';
import toast from 'react-hot-toast';

function FormShortlisted() {
    const location = useLocation();
    const dispatch = useDispatch();
    const [selectAll, setSelectAll] = useState(false);
    const [formShortlisted, setFormShortlisted] = useState([]);
    const job_id = location?.state ? location.state?.job_id : null;

    useEffect(() => {
        const data = {
            type: 'form',
            job_id: job_id
        }

        dispatch(setLoading(true));
        axios.post('/get_candidates_by_lead_type', data).then((res) => {
            if (res.success) {
                setFormShortlisted(res.candidates);
            }
            dispatch(setLoading(false));
        }).catch((error) => {
            console.log(error);
            dispatch(setLoading(false));
        })
    }, [job_id, dispatch])

    const handleSelectAll = (e) => {
        setSelectAll(e.target.checked);
        let updatedList = [...formShortlisted];
        setFormShortlisted(updatedList.map(item => ({ ...item, selected: e.target.checked })))
    }

    const handleSingleSelect = (e, index) => {
        let updatedList = [...formShortlisted];
        updatedList[index].selected = e.target.checked;
        setSelectAll(updatedList.every(item => item.selected));
        setFormShortlisted(updatedList);
    }

    const handleMoveToShortlisted = () => {
        let selectedEmails = formShortlisted.filter(candidate => candidate.selected).map((candidate) => candidate.email);
        if (selectedEmails.length > 0) {
            const data = {
                candidates: JSON.stringify(selectedEmails),
                job_id: job_id
            }
            dispatch(setLoading(true));
            axios.post('/add_candidates', data).then((res) => {
                if(res.success){
                    toast.success(res.message);
                }
                dispatch(setLoading(false));
            }).catch((error) => {
                console.log(error);
                dispatch(setLoading(false));
            })
        } else {
            toast.dismiss(); toast.error('Select atleast One Employee')
        }
    }
    return (
        <div className='formshortlisted-wrapper'>
            <Table responsive="md">
                <thead>
                    <tr>
                        <th className='text-center'><input type="checkbox" checked={selectAll} onChange={handleSelectAll} /></th>
                        <th className='text-center'>S.No</th>
                        <th className='text-center'>Name</th>
                        <th className='text-center'>Email</th>
                        <th className='text-center'>Contact Number</th>
                        <th className='text-center'>Resume Score</th>
                    </tr>
                </thead>
                <tbody>
                    {formShortlisted.map((item, index) => (
                        <tr key={index}>
                            <td className='text-center'>
                                <input
                                    type='checkbox'
                                    onChange={(e) => handleSingleSelect(e, index)}
                                    checked={item.selected || false}
                                />
                            </td>
                            <td className='text-center'>{index + 1}</td>
                            <td className='text-center'>{item.username}</td>
                            <td className='text-center'>{item.email}</td>
                            <td className='text-center'>{item.whatsapp_number}</td>
                            <td className='text-center'>{item.resume_score}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <div className='button'>
                <button onClick={handleMoveToShortlisted}>Move to shortlisted</button>
            </div>
        </div>
    )
}

export default FormShortlisted