import React, { useEffect, useState } from 'react'
import Header from '../../components/Header';
import { Table } from 'react-bootstrap';
import Pagination from '../../components/Pagination';
import '../../scss/Pool.scss'
import { useDispatch } from 'react-redux';
import axios from '../../util/api';
import { setLoading } from '../../redux/userSlice';
import toast from 'react-hot-toast';

function Pool() {
    const dispatch = useDispatch();
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [candidates, setCandidates] = useState([]);
    const [search, setSearch] = useState('')
    const getPoolData = () => {
        dispatch(setLoading(true));
        const data = {
            page,
            search
        }
        axios.post('get_candidate_pool', data).then(res => {
            console.log(res);
            dispatch(setLoading(false));
            if (res.success) {
                setCandidates(res.candidates);
                setTotalPages(res.num_pages);
            }
        }).catch(error => {
            console.log(error);
            dispatch(setLoading(false));
            toast.dismiss();
            toast.error('Something went wrong');
        })
    }
    useEffect(() => {
        getPoolData();
    }, [page])

    const handleSearch = (e) => {
        e.preventDefault();
        getPoolData();
    }

    return (
        <div className='pool-wrapper'>
            <Header heading='Resume Pool' description='List of all resumes' />
            <div className='search-box d-flex justify-content-end mt-4'>
                <form className='d-flex gap-2' onSubmit={handleSearch}>
                    <input type='text' id='search' onChange={(e) => setSearch(e.target.value)} placeholder='Search by job title'></input>
                    <button>Search</button>
                </form>
            </div>
            <Table responsive="md" style={{ marginTop: '24px' }}>
                <thead>
                    <tr>
                        <th className='text-center'>S.No</th>
                        <th className='job_id text-center'>Job ID</th>
                        <th className='job_title text-center'>Job Title</th>
                        <th className='name text-center'>Name</th>
                        <th className='email text-center'>Email</th>
                        <th className='email text-center'>Resume Score</th>
                    </tr>
                </thead>
                <tbody>
                    {candidates?.map((item, index) => {
                        return (
                            <tr key={index}>
                                <td className='text-center'>{index + 1}</td>
                                <td className='text-center'>{item.job_id}</td>
                                <td className='text-center'>{item.job_title}</td>
                                <td className='text-center'>{item.username}</td>
                                <td className='text-center'>{item.email}</td>
                                <td className='text-center'>{item.resume_score}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </Table>
            <Pagination page={page} setPage={setPage} total={totalPages} />
        </div>
    )
}

export default Pool