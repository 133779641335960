import { Route, Routes } from 'react-router-dom';
import './App.scss';
import Loader from './components/Spinner/Loader';
import Auth from './pages/auth/Auth';
import SuperAdminDashBoard from './pages/superAdmin/SuperAdminDashBoard';
import CorporateAdminDashboard from './pages/corporateAdmin/CorporateAdminDashboard';
import PrivateRoutes from './routes/PrivateRoutes';
import UserVerify from './pages/auth/UserVerify';
import MCQAssessment from './pages/assessment/MCQAssessment';
import Interview from './pages/interview/Interview';
import BookInterviewSlot from './pages/bookInterviewSlot/BookInterviewSlot';
import AssessmentSuccess from './components/AssessmentSuccess';
import Dashboard from './pages/corporate/Dashboard';
import Jobs from './pages/corporate/Jobs';
import Teams from './pages/corporate/Teams';
import Notifications from './pages/corporate/Notifications';
import Profile from './pages/corporate/Profile';
import CreateJob from './pages/corporate/CreateJob';
import Job from './pages/corporate/Job';
import AddTeam from './pages/corporate/AddTeam';
import ViewTemplate from './pages/corporate/ViewTemplate';
import AddResume from './pages/corporate/AddResume';
import CandidateList from './pages/corporate/candidateList/CandidateList';
import UserProfile from './pages/corporate/UserProfile';
import Questions from './pages/corporate/questions/Questions';
import UpcomingInterviews from './pages/upcomingInterviews/UpcomingInterviews';
import CandidateSummary from './pages/candidateSummary/CandidateSummary';
import Pool from './pages/corporate/Pool';
import JobDetails from './pages/jobDetails/JobDetails';

function App() {
  return (
    <>
      <Loader />
      <Routes>
        <Route path='/superAdmin' element={<PrivateRoutes allowedRoles={['superAdmin']}><SuperAdminDashBoard /></PrivateRoutes>} />
        <Route path='/' element={<Auth page="login" type="corporate" />} />
        <Route path='/superadminlogin' element={<Auth page="login" type="general" />} />
        <Route path='/signup' element={<Auth page="signup" />} />
        <Route path='/forgotpassword' element={<Auth page="forgotpassword" />} />
        <Route path='/resetpassword' element={<Auth page="resetpassword" />} />
        <Route path='/verifyEmail' element={<Auth page="verifyEmail" />} />
        <Route path='/confirmPassword' element={<Auth page="confirmPassword" />} />
        <Route path='/userVerify' element={<UserVerify />} />
        {/* <Route path='/dashboard' element={<UserVerify />} /> */}
        <Route path='/assessment' element={<MCQAssessment />} />
        <Route path='/interview' element={<Interview />} />
        <Route path='/bookInterviewSlot' element={<BookInterviewSlot />} />
        <Route path='/thankyou' element={<AssessmentSuccess />} />
        <Route path='/corporateDashboard' element={<PrivateRoutes allowedRoles={['corporateAdmin', 'corporateUser']}><CorporateAdminDashboard /></PrivateRoutes>} >
          <Route index element={<Dashboard />} />
          <Route path='/corporateDashboard/jobs' element={<Jobs />} />
          <Route path='/corporateDashboard/teams' element={<Teams />} />
          <Route path='/corporateDashboard/pool' element={<Pool />} />
          <Route path='/corporateDashboard/teams/addTeam' element={<AddTeam />} />
          <Route path='/corporateDashboard/teams/userProfile' element={<UserProfile />} />
          <Route path='/corporateDashboard/notifications' element={<Notifications />} />
          <Route path='/corporateDashboard/profile' element={<Profile />} />
          <Route path='/corporateDashboard/jobs/upcomingInterviews' element={<UpcomingInterviews />} />
          <Route path='/corporateDashboard/jobs/createJob' element={<CreateJob />} />
          <Route path='/corporateDashboard/jobs/viewTemplate' element={<ViewTemplate />} />
          <Route path='/corporateDashboard/jobs/addResume' element={<AddResume />} />
          <Route path='/corporateDashboard/jobs/candidates' element={<CandidateList />} />
          <Route path='/corporateDashboard/jobs/:jobId' element={<Job />} />
          <Route path='/corporateDashboard/jobs/questions' element={<Questions />} />
        </Route>
        <Route path='/candidateSummary' element={<CandidateSummary />} />
        <Route path='/jobDetails' element={<JobDetails />} />
      </Routes>
    </>
  );
}

export default App;
