import React from 'react';
import '../scss/Header.scss';
import { useNavigate } from 'react-router-dom';
import tokenService from '../util/TokenService';
import { MdLogout } from "react-icons/md";
import { Tooltip } from 'react-tooltip';

function Header({ heading, description }) {
    const navigate = useNavigate();
    const user = tokenService.getUser();

    const handleLogout = () => {
        tokenService.removeUser();
        navigate('/');
    };

    const getInitials = (name) => {
        const names = name.split(' ');
        let initials = names[0].charAt(0).toUpperCase();
        if (names.length > 1) {
            initials += names[1].charAt(0).toUpperCase();
        }
        return initials;
    };

    return (
        <div className='header-wrapper'>
            <div className='heading-description'>
                <h2 className='heading'>{heading}</h2>
                {description && (
                    <p className='description'>{description}</p>
                )}
            </div>
            <div className='profile'>
                <MdLogout
                    size={27}
                    className='logout-icon'
                    onClick={handleLogout}
                    data-tooltip-id="logout-tooltip"
                    data-tooltip-content="Logout"
                />
                <Tooltip id="logout-tooltip" place="top" style={{ backgroundColor: '#0055ad', color: '#ffffff' }} />
                <span>{getInitials(`${user.first_name} ${user.last_name}`)}</span>
            </div>
        </div>
    );
}

export default Header;
