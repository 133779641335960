import React, { useState, useEffect } from 'react';
// import LeftSectionImg from '../../assests/images/LoginPageImg.png';
import '../../scss/UserVerify.scss';
import axios from '../../util/api';
import { useLocation, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../redux/userSlice';
import TokenService from '../../util/TokenService';
import HireNowXLogo from '../../assests/images/HireNowXLogo.png';
import loginPageLogo from '../../assests/images/loginBackground.png';
import otpVerifyLogo from '../../assests/images/otpVerifyLogo.png';
import timerClock from './../../assests/images/timerClock.png';

function UserVerify() {
    const [email, setEmail] = useState('');
    const [mailError, setMailError] = useState('');
    const [otp, setOTP] = useState('');
    const [otpError, setOtpError] = useState('');
    const [displayOtpPage, setDisplayOtpPage] = useState(false);
    const [isLoginPossible, setIsLoginPossible] = useState(true);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');

    useEffect(() => {
        if (token) {
            TokenService.setAccessToken(token);
            const data = TokenService.decryptToken(token);
            console.log(data);
            
            const now = new Date();
            const timeSlotDate = new Date(data.time);

            if (now < timeSlotDate) {
                setIsLoginPossible(false);
                return;
            }

            if (data) {
                setEmail(data.email);
            }
        } else {
            toast.dismiss(); toast.error('Token not found');
        }
    }, []);


    const handleOtpChange = (e) => {
        const value = e.target.value.replace(/\D/g, '');
        setOTP(value.slice(0, 6))
    }

    const validateEmail = () => {
        if (!email.trim()) {
            setMailError('Please enter your mail id');
            return false;
        } else {
            setMailError('');
            return true;
        }
    };

    const validateOTP = () => {
        if (!otp.trim()) {
            setOtpError('Enter OTP');
            return false;
        } else if (otp.length < 6) {
            setOtpError('Enter Valid OTP')
            return false;
        } else {
            setOtpError('');
            return true;
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateEmail()) {
            dispatch(setLoading(true));
            axios.post('/check_org_and_send_otp').then((res) => {
                if (res.success) {
                    setDisplayOtpPage(true);
                    toast.dismiss(); toast.success('Otp Sent Successfully');
                } else {
                    toast.dismiss(); toast.error(res.message)
                }
                dispatch(setLoading(false))
            }).catch((error) => {
                dispatch(setLoading(false));
                toast.dismiss(); toast.error('An error occurred while sending OTP')
                console.log('error', error)
            })
        }
    };

    const handleResendOTP = (e) => {
        e.preventDefault();

        if (validateEmail()) {
            dispatch(setLoading(true))
            axios.post('/check_org_and_send_otp').then((res) => {
                if (res.success) {
                    toast.dismiss(); toast.success('Otp Sent Successfully');
                } else {
                    toast.dismiss(); toast.error('Failed to send OTP')
                }
                dispatch(setLoading(false))
            }).catch((error) => {
                dispatch(setLoading(false));
                toast.dismiss(); toast.error('An error occurred while sending OTP')
                console.log('error-->', error)
            })
        }
    }

    const handleOtpVerify = (e) => {
        e.preventDefault();
        if (validateOTP()) {
            let data = {
                otp: otp,
                token
            }

            dispatch(setLoading(true))
            axios.post('/verify_otp_and_login', data).then((res) => {
                if (res.success) {
                    TokenService.setAccessToken(res.token);
                    const decryptedData = TokenService.decryptToken(res.token);
                    console.log('hello', decryptedData)
                    if (decryptedData?.testType === 'interview') {
                        navigate('/interview')
                    } else {
                        navigate('/assessment', { state: { token: token } });
                    }
                } else {
                    toast.dismiss(); toast.error(res.message)
                }
                dispatch(setLoading(false))
            }).catch((error) => {
                console.log(error);
                dispatch(setLoading(false));
                toast.dismiss(); toast.error('Internal Error')
            })
        }
    }

    return (
        <div className='user-verify-wrapper'>
            <div className='header'>
                <img src={HireNowXLogo} alt='Logo'></img>
            </div>
            {isLoginPossible ? (<div className='login-form-container'>
                <div className='left-section'>
                    {displayOtpPage ? (
                        <img src={otpVerifyLogo} alt='logo'></img>
                    ) : (
                        <img src={loginPageLogo} alt='Logo'></img>
                    )}
                </div>
                <div className='right-section'>
                    <div className='login-form-section'>
                        {!displayOtpPage ? (
                            <form className='corporate-login-form-container' onSubmit={handleSubmit}>
                                <div className='form'>
                                    <div className='login-heading'>
                                        <h2>Welcome!</h2>
                                        <p className='login-heading'>Login with your credentials</p>
                                    </div>
                                    <div className='form-fields'>
                                        <div className='email_section fields'>
                                            <label htmlFor="email">Email</label>
                                            <input type='email' id='email' name='email' value={email} disabled autoComplete="off" placeholder='Enter your Email Address' />
                                            {mailError && <span className='form-error'>{mailError}</span>}
                                        </div>
                                    </div>
                                    <div className='verify-email-btn'>
                                        <button type='submit' className='verify-btn'>Verify your email</button>
                                    </div>
                                </div>
                            </form>
                        ) : (
                            <form className='otp-form' onSubmit={handleOtpVerify}>
                                <div className='form'>
                                    <div className='otp-heading'>
                                        <h2 className='otp-text'>Verify OTP!</h2>
                                    </div>
                                    <div className='otp_section fields'>
                                        <label htmlFor="OTP">OTP</label>
                                        <input type='text' id='OTP' name='OTP' value={otp} onChange={handleOtpChange} placeholder='Enter OTP' />
                                        <div className='d-flex justify-content-between'>
                                            <span className='form-error'>{otpError}</span>
                                            <button type='button' onClick={handleResendOTP} style={{ border: 'none', backgroundColor: 'transparent', color: 'white' }}>Resend OTP</button>
                                        </div>
                                    </div>
                                    <div className='verify-btn'>
                                        <button type='submit'>Verify OTP</button>
                                    </div>
                                </div>
                            </form>
                        )}

                    </div>
                </div>
            </div>) : (
                <div className='before-time-validation-error'>
                    <img src={timerClock} alt='img'></img>
                    <h2>Access to this page is only available during your designated time slot.</h2>
                </div>
            )}

        </div>
    );
}

export default UserVerify;
