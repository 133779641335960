// export const backend_url = 'https://e6e7-122-180-250-47.ngrok-free.app/api'; //navjeet
export const backend_url = 'https://mysmsconnect.com/api'; //test server

const cogSvcSubKey = process.env.REACT_APP_AZURE_cogSvcSubKey;
const iceUrl = process.env.REACT_APP_AZURE_iceUrl;
const iceUsername = process.env.REACT_APP_AZURE_iceUsername;
const iceCredential = process.env.REACT_APP_AZURE_iceCredential;

export const FemaleAvatarAppConfig = {
    cogSvcRegion: "westus2",
    cogSvcSubKey,
    voiceName: "en-US-EmmaNeural",
    avatarCharacter: "Lori",
    avatarStyle: "graceful",
    avatarBackgroundColor: "#000",
    iceUrl,
    iceUsername,
    iceCredential
}

export const MaleAvatarAppConfig = {
    cogSvcRegion: "westus2",
    cogSvcSubKey,
    voiceName: "en-US-JacobNeural",
    avatarCharacter: "Max",
    avatarStyle: "formal",
    avatarBackgroundColor: "#000",
    iceUrl,
    iceUsername,
    iceCredential
}