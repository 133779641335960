import React from 'react';
import BarChart from './BarChart';
import GaugeChart from './GaugeChart';
import ReactPlayer from 'react-player';

function AssessmentSummary({ mcqSummaryData }) {
  const barData = new Array(4);
  barData[0] = mcqSummaryData?.quiz?.answered;
  barData[1] = mcqSummaryData?.quiz?.correct_answer;
  barData[2] = mcqSummaryData?.quiz?.wrong_answer;
  barData[3] = mcqSummaryData?.quiz?.skipped;

  return (
    <div className='assessment-wrapper'>
      <div className='mcq-test-score'>
        {/* <h2>MCQ Test Score</h2> */}
        <div className='charts'>
          <div className='left'>
            <GaugeChart data={mcqSummaryData?.mcq_score} />
          </div>
          <div className='right'>
            <BarChart barData={barData} />
          </div>
        </div>
      </div>
      <div className='video-remark-section'>
        <div className='remarks'>
          <h2>Remarks</h2>
          <p>{mcqSummaryData?.remark}</p>
        </div>
        <div className='video-section'>
          <h2>MCQ Recording</h2>
          <ReactPlayer
            url={mcqSummaryData?.mcq_link}
            controls={true}
            width='100%'
            height='250px'
          />
        </div>
      </div>
      <div className='mcq-test-question'>
        <h2>MCQ Test Questions</h2>
        <div className='questions-container'>
          {mcqSummaryData?.quiz?.questions?.map((question, index) => {
            return (
              <div key={index} className='question'>
                <h2 className='question-heading'>{index + 1}. {question.questionText}</h2>
                <ol>
                  {question?.options?.map((option, index) => {
                    return (
                      <li key={index}>{option.value}</li>
                    )
                  })}
                </ol>
                <p>Correct Answer: {question?.answer}</p>
                <p>Candidate Answer: {question?.selectedOption?.optionValue}</p>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default AssessmentSummary