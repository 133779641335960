import React, { useState } from 'react';
import { BiHide, BiShow } from 'react-icons/bi';
import { useDispatch } from 'react-redux';
import { setLoading, setUser } from '../../redux/userSlice';
import { toast } from 'react-hot-toast';
import axios from '../../util/api';
import Countdown from 'react-countdown';
import { useNavigate } from 'react-router-dom';

export default function Signup() {
    const [formData, setFormData] = useState({ name: '', email: '', create_password: '', confirm_password: '', agree_terms_conditions: false });
    const [formErrors, setFormErrors] = useState({ emailError: '', passwordError: '', nameError: '', agree_terms_conditionsError: '' });
    const [showCreatePassword, setShowCreatePassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [resendOTP, setResendOTP] = useState(false);
    const [timer, setTimer] = useState(null);
    const [otp, setOTP] = useState({ otpValue: '' });
    const [otpError, setOtpError] = useState({ otpValueError: '' });
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showOtpPage, setShowOtpPage] = useState(false);
    const time = new Date();
    time.setSeconds(time.getSeconds() + 20)

    const validateLoginForm = () => {
        var errors = { emailError: '', passwordError: '', nameError: '', agree_terms_conditionsError: '' };
        let { email, create_password, confirm_password, agree_terms_conditions } = formData;
        var isError = false;

        if (!email.match(/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i)) {
            errors.emailError = 'Enter Valid email'
            isError = true;
        }

        if (create_password.length < 4) {
            errors.passwordError = 'Enter valid password'
            isError = true;
        }

        if (create_password !== confirm_password) {
            errors.passwordError = 'create password and confirm password should match'
            isError = true;
        }

        if (!agree_terms_conditions) {
            errors.agree_terms_conditionsError = 'Please agree term & condition and privacy policy'
            isError = true;
        }
        setFormErrors(errors);
        return isError;
    }

    const validateOTP = () => {
        const errors = { otpValueError: '' };
        let isError = false;
        const { otpValue } = otp;

        if (otpValue.length < 6) {
            errors.otpValueError = 'Please enter valid OTP';
            isError = true;
        }

        if (!otpValue) {
            errors.otpValueError = 'Plese enter OTP';
            isError = true;
        }

        setOtpError(errors)
        return isError;

    }

    const handleOTPVerify = (e) => {
        e.preventDefault();
        if (!validateOTP() && !validateLoginForm()) {
            dispatch(setLoading(true));
            const data = {
                email: formData.email,
                password: formData.confirm_password,
                username: formData.name,
                otp: otp.otpValue
            }
            axios.post('/verify_otp', data).then((res) => {
                if (res.success) {
                    navigate('/');
                    toast.dismiss();toast.success('User Registered Successfully');
                    setShowOtpPage(false);
                    setOTP({otpValue: ''});
                } else {
                    toast.dismiss();toast.error(res.error);
                }
                dispatch(setLoading(false));
            }).catch((error) => {
                dispatch(setLoading(false));
                setShowOtpPage(false);
                setOTP({otpValue: ''});
                console.log(error)
            })
        } else {
            toast.dismiss();toast.error('Something went wrong!')
        }
    }

    const handleResendOTP = (e) => {
        e.preventDefault();
        if (!validateLoginForm()) {
            const data = {
                username: formData.name,
                email: formData.email,
                password: formData.confirm_password
            }
            dispatch(setLoading(true));
            axios.post('/register', data).then((res) => {
                if (res.success) {
                    toast.dismiss();toast.success(res.message);
                    setResendOTP(false);
                    setTimer(Date.now() + 0.5 * 60 * 1000);
                }
                dispatch(setLoading(false));
            }).catch((error) => {
                if (error?.response?.data?.error) {
                    toast.dismiss();toast.error(error?.response?.data?.error)
                } else {
                    toast.dismiss();toast.error('Something went wrong')
                }
                dispatch(setLoading(false));
            })
        }
    }

    function handleChange(e) {
        const { name, value, type, checked } = e.target;
        setFormData((prevData) => {
            return ({
                ...prevData,
                [name]: type === 'checkbox' ? checked : value
            })
        })
    }

    const handleOTPChange = (e) => {
        const value = e.target.value.replace(/\D/g, '');
        setOTP((prevData) => ({
            ...prevData,
            otpValue: value.slice(0, 6)
        }))
    }

    const renderer = ({ minutes, seconds, completed }) => {
        if (completed) {
            setResendOTP(true)
            return <span>0s</span>
        } else {
            return <span>{minutes}:{seconds}</span>;
        }
    };

    function handleCreatePasswordType() {
        setShowCreatePassword(!showCreatePassword)
    }

    function handleConfirmPasswordType() {
        setShowConfirmPassword(!showConfirmPassword)
    }

    function handleSubmit(e) {
        e.preventDefault();
        if (!validateLoginForm()) {
            const data = {
                username: formData.name,
                email: formData.email,
                password: formData.confirm_password
            }
            dispatch(setLoading(true));
            axios.post('/register', data).then((res) => {
                if (res.success) {
                    toast.dismiss();toast.success(res.message);
                    setShowOtpPage(true);
                    setTimer(Date.now() + 0.5 * 60 * 1000);
                }
                dispatch(setLoading(false));
            }).catch((error) => {
                if (error?.response?.data?.error) {
                    toast.dismiss();toast.error(error?.response?.data?.error)
                } else {
                    toast.dismiss();toast.error('Something went wrong')
                }
                dispatch(setLoading(false));
            })
        }
    }
    return (
        <>
            <div className="signup-form-container">
                <div className='signup-heading'>
                    <h5>Register Now!</h5>
                    <p>{!showOtpPage ? 'Create your account' : 'Verify the OTP sent on your email'}</p>
                </div>
                {!showOtpPage ? (
                    <form className='signup-form' onSubmit={handleSubmit}>
                        <div className='name_section fields'>
                            <label htmlFor="name">Name</label>
                            <input type='text' id='name' name='name' onChange={handleChange} value={formData.name} autoComplete='off' placeholder='Enter your Name' required></input>
                            <span className='form-error'>{formErrors.nameError}</span>
                        </div>
                        <div className='email_section fields'>
                            <label htmlFor="email">Email</label>
                            <input type='email' id='email' name='email' onChange={handleChange} value={formData.email} placeholder='Enter your Email Address' autoComplete='off'></input>
                            <span className='form-error'>{formErrors.emailError}</span>
                        </div>

                        <div className='create_password_section fields'>
                            <label htmlFor="create_password">Password</label>
                            <input type={showCreatePassword ? 'text' : 'password'} id='create_password' name='create_password' onChange={handleChange} value={formData.create_password} placeholder='Create Your Password'></input>
                            {!showCreatePassword ? (<BiHide className='hide_show' onClick={handleCreatePasswordType} />) : (<BiShow className='hide_show' onClick={handleCreatePasswordType} />)}
                            <span className='form-error'>{formErrors.passwordError}</span>
                        </div>
                        <div className='confirm_password_section fields'>
                            <label htmlFor="confirm_password">Confirm Password</label>
                            <input type={showConfirmPassword ? 'text' : 'password'} id='confirm_password' name='confirm_password' onChange={handleChange} value={formData.confirm_password} placeholder='Confirm Your Password'></input>
                            {!showConfirmPassword ? (<BiHide className='hide_show' onClick={handleConfirmPasswordType} />) : (<BiShow className='hide_show' onClick={handleConfirmPasswordType} />)}
                            <span className='form-error'>{formErrors.passwordError}</span>
                        </div>
                        <div className='agree-checkbox fields'>
                            <div className='checkbox-with-label'>
                                <input type='checkbox' id='agree_term_condition' name='agree_terms_conditions' checked={formData.agree_terms_conditions} onClick={handleChange}></input>
                                <label htmlFor='agree_term_condition'>I agree with <span>Term & Condition</span> And <span>Privacy policy</span></label>
                            </div>
                            <span className='form-error'>{formErrors.agree_terms_conditionsError}</span>
                        </div>
                        <button className='signup_btn'>Sent OTP</button>
                    </form>
                ) : (
                    <form className='otp-form'>
                        <div className='otp-field fields'>
                            <label htmlFor='otp'>Enter OTP</label>
                            <div className='input-countdown'>
                                <input type='text' id='otp' name='otp' onChange={handleOTPChange} placeholder='Enter your OTP here' value={otp.otpValue}></input>
                                <Countdown
                                    className='countdown-timer'
                                    date={timer}
                                    renderer={renderer}
                                    key={timer}
                                />
                            </div>
                            <div className='resend-otp'>
                                <div className='form-error'>{otpError.otpValueError}</div>
                                {resendOTP && (
                                    <button onClick={handleResendOTP}>Resend OTP</button>
                                )}
                            </div>
                        </div>
                        <div className='verify-otp-btn'>
                            <button onClick={handleOTPVerify}>Verify OTP</button>
                        </div>
                    </form>
                )}

            </div>
        </>
    )
}
