import React from 'react';
import '../../scss/SuperAdminDashboard.scss';
import { useState } from 'react';
import CorporateTable from '../../components/CorporateTable';
import UserTable from '../../components/UserTable';
import { useNavigate } from 'react-router-dom';
import tokenService from '../../util/TokenService';

function SuperAdminDashBoard() {

    const [activeTab, setActiveTab] = useState('corporate');
    const navigate = useNavigate();

    const handleTabClick = (tabClicked) => {
        setActiveTab(tabClicked);
    }

    const handleLogout = () => {
        tokenService.removeUser();
        navigate('/');
    }

    return (
        <div className='super-Admin-wrapper'>
            <div className='super-admin-container'>
                <div className='logout-button'>
                    <button onClick={handleLogout}>Logout</button>
                </div>
                <div className='tabs'>
                    <button className={`${activeTab === 'user' ? 'active' : ''} user`} onClick={() => handleTabClick('user')}>User</button>
                    <button className={`${activeTab === 'corporate' ? 'active' : ''} corporate`} onClick={() => handleTabClick('corporate')}>Corporate</button>
                </div>
                {activeTab === 'user' && (
                    <UserTable />
                )}
                {activeTab === 'corporate' && (
                    <CorporateTable />
                )}
            </div>
        </div>
    )
}

export default SuperAdminDashBoard