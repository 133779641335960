import React, { useState, useEffect } from 'react';
import { Outlet, useLocation, useSearchParams } from 'react-router-dom';
import '../../scss/CandidateSummary.scss';
import { Tab, Tabs } from 'react-bootstrap';
import ResumeSummary from '../../components/ResumeSummary';
import AssessmentSummary from '../../components/AssessmentSummary';
import AiInterviewSummary from '../../components/AiInterviewSummary';
import FinalInterviewSummary from '../../components/FinalInterviewSummary';
import { MdOutlineEmail } from "react-icons/md";
import { LuPhone } from "react-icons/lu";
import { FaRegUser } from "react-icons/fa6";
import { IoShareSocialOutline } from "react-icons/io5";
import Modal from 'react-bootstrap/Modal';
import { FaLink } from "react-icons/fa6";
import toast from 'react-hot-toast';
import axios from '../../util/api';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../redux/userSlice';


function CandidateSummary() {
    const [key, setKey] = useState('resume');
    const [params, setParams] = useSearchParams();
    const [shareProfileModalShow, setShareProfileModalShow] = useState(false);
    const [viewResumeModalShow, setViewResumeModalShow] = useState(false);
    const [profileData, setProfileData] = useState(null);
    const [resumeSummaryData, setResumeSummaryData] = useState(null);
    const [mcqSummaryData, setMcqSummaryData] = useState(null);
    const [aiSummaryData, setAiSummaryData] = useState(null);
    const [finalInterviewSummaryData, setFinalInterviewSummaryData] = useState(null)
    const [link, setLink] = useState('')
    const location = useLocation();
    const userIdByState = location?.state?.userId;
    const job_id = location?.state?.job_id;
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setLoading(true));
        if (job_id) {
            axios.post('/get_candidate_data', { id: userIdByState }).then((res) => {
                if (res.success) {
                    setProfileData(res?.candidate_data?.profile);
                    setResumeSummaryData(res?.candidate_data?.resume_summary);
                    setMcqSummaryData(res?.candidate_data?.mcq);
                    setAiSummaryData(res?.candidate_data?.ai_interview);
                    setFinalInterviewSummaryData(res?.candidate_data?.main_interview);
                    dispatch(setLoading(false))
                }
            }).catch((error) => {
                console.log(error);
                dispatch(setLoading(false))
            })
        } else {
            axios.post('/get_candidate_data_token', { token: params.get('token') }).then((res) => {
                if (res.success) {
                    setProfileData(res?.candidate_data?.profile);
                    setResumeSummaryData(res?.candidate_data?.resume_summary);
                    setMcqSummaryData(res?.candidate_data?.mcq);
                    setAiSummaryData(res?.candidate_data?.ai_interview);
                    setFinalInterviewSummaryData(res?.candidate_data?.main_interview);
                    dispatch(setLoading(false))
                }
            }).catch((error) => {
                console.log(error);
                dispatch(setLoading(false))
            })
        }
    }, [])


    const handleCopyLink = () => {
        navigator.clipboard.writeText(link).then(() => {
            toast.success('Link copied successfully')
        })
    }

    const handleShareProfile = () => {
        dispatch(setLoading(true));
        axios.post('share_link_candidate', { id: userIdByState, job_id }).then(res => {
            dispatch(setLoading(false));
            if (res.success) {
                setLink(res.share_link);
                setShareProfileModalShow(true);
            }
        }).catch(error => {
            console.log(error);
            dispatch(setLoading(false));
            toast.dismiss();
            toast.error('Something went wrong');
        })
    }

    return (
        <div className='candidate-summary-wrapper'>
            <div className='left-container'>
                <div className="sidebar">
                    <div className='profile-img-section'>
                        <span className='profile-img'>AS</span>
                        <div className='profile-name-designation'>
                            <h2 className='name'>{profileData?.username}</h2>
                            <p className='designation'>{profileData?.job_title}</p>
                        </div>
                    </div>
                    <div className='profile-details'>
                        <div className='email'>
                            <MdOutlineEmail />
                            {profileData?.email_id}
                        </div>
                        <div className='phone'>
                            <LuPhone />
                            {profileData?.whatsapp_number}
                        </div>
                        <div className='btn-section'>
                            <button onClick={() => setViewResumeModalShow(true)}><FaRegUser />View Resume</button>
                            {job_id && (
                                <button className='share-btn' onClick={handleShareProfile}><IoShareSocialOutline />Share Profile</button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className='right-container'>
                <div className='tabs'>
                    <Tabs
                        id="controlled-tab-example"
                        activeKey={key}
                        onSelect={(k) => setKey(k)}
                    >
                        <Tab eventKey="resume" title="Resume Summary" >
                            {key === 'resume' && <ResumeSummary resumeSummaryData={resumeSummaryData} />}
                        </Tab>
                        <Tab eventKey="mcq" title="MCQ Assessment">
                            {key === 'mcq' && <AssessmentSummary mcqSummaryData={mcqSummaryData} />}
                        </Tab>
                        <Tab eventKey="ai" title="AI Interview">
                            {key === 'ai' && <AiInterviewSummary aiSummaryData={aiSummaryData} />}
                        </Tab>
                        <Tab eventKey="final" title="Final Interview">
                            <FinalInterviewSummary finalInterviewSummaryData={finalInterviewSummaryData} job_id={job_id} token={params.get('token')} />
                        </Tab>
                    </Tabs>
                </div>
                <Outlet />
            </div>
            <Modal
                className='share_profile_modal'
                size='md'
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={shareProfileModalShow}
                onHide={() => setShareProfileModalShow(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Share Profile</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='sharable-link-wrapper'>
                        <p className='link'><FaLink size={18} />{link}</p>
                        <button onClick={handleCopyLink}>Copy</button>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal
                className='view_resume_modal'
                size='lg'
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={viewResumeModalShow}
                onHide={() => setViewResumeModalShow(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Resume</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <iframe
                        src={resumeSummaryData?.resume_link}
                        title="PDF Document"
                        width="100%"
                        height="100%"
                        style={{ border: 'none' }}
                    ></iframe>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default CandidateSummary