import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { Modal } from 'react-bootstrap';
import toast from 'react-hot-toast';
import axios from '../../../util/api';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../../redux/userSlice';
import { backend_url } from '../../../util/config';
import { useNavigate } from 'react-router-dom';

export default function MainInterviewCandicates({ candidates, job_id }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [selectAll, setSelectAll] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [formData, setFormData] = useState({
        job_title: '',
        job_location: '',
        gross_ctc: '',
        fixed_ctc: '',
    })

    const [formDataError, setFormDataError] = useState({
        job_titleError: '',
        job_locationError: '',
        gross_ctcError: '',
        fixed_ctcError: ''
    })
    const [users, setUsers] = useState([]);

    function get_job_details() {
        if (job_id) {
            const payload = {
                job_id
            }
            dispatch(setLoading(true));
            axios.post('/get_job_details', payload).then((res) => {
                if (res.success) {
                    setFormData((prevData) => ({ ...prevData, job_title: res?.job?.job_title, job_location: res?.job?.job_location }))
                }
                dispatch(setLoading(false));
            }).catch((error) => {
                dispatch(setLoading(false));
                console.log(error)
            })
        }
    }

    const validateForm = () => {
        const errors = {
            job_titleError: '',
            job_locationError: '',
            gross_ctcError: '',
            fixed_ctcError: ''
        }

        let isError = false;

        if (!formData.job_title.trim()) {
            errors.job_titleError = 'Job Title is mandatory';
            isError = true;
        }

        if (!formData.job_location.trim()) {
            errors.job_locationError = 'Job Location is mandatory';
            isError = true;
        }

        if (!formData.gross_ctc.trim()) {
            errors.gross_ctcError = 'Please enter gross CTC';
            isError = true;
        }

        if (!formData.fixed_ctc.trim()) {
            errors.fixed_ctcError = 'Please enter fixed CTC';
            isError = true;
        }

        setFormDataError(errors);
        return isError;
    }

    const handleSelectAll = (e) => {
        setSelectAll(e.target.checked);
        let updatedList = [...users];
        setUsers(updatedList.map(item => ({ ...item, selected: e.target.checked })))
    }
    const handleSingleSelect = (e, index) => {
        let updatedList = [...users];
        updatedList[index].selected = e.target.checked;
        setSelectAll(updatedList.every(item => item.selected))
        setUsers(updatedList);
    }

    const handleSendOfferFormChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => {
            return {
                ...prevData,
                [name]: value
            }
        })
    }

    const handleSendOfferLetter = (e) => {
        e.preventDefault();
        if (!validateForm()) {
            const selectedCandidatesData = users.filter(candidate => candidate.selected).map((candidate) => ({ candidateEmail: candidate.email_id, candidateName: candidate.username }));
            let data = {
                selectedCandidates: selectedCandidatesData,
                job_title: formData.job_title,
                job_location: formData.job_location,
                gross_ctc: formData.gross_ctc,
                fixed_ctc: formData.fixed_ctc,
                job_id
            }

            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${backend_url}/generate-offer-letter`,
                headers: {
                    'Content-Type': 'application/json',
                },
                data: data
            };

            if (selectedCandidatesData) {
                dispatch(setLoading(true));
                axios.request(config).then((res) => {
                    if (res.success) {
                        toast.dismiss(); toast.success('Offer letter sent successfully')
                        setModalShow(false);
                    } else {
                        toast.dismiss(); toast.error('Not able to sent offer letter')
                    }
                    dispatch(setLoading(false))
                }).catch((error) => {
                    dispatch(setLoading(false));
                    console.log(error);
                });
            }
        }
    }

    const openSendLink = () => {
        let selectedEmails = users.filter(candidate => candidate.selected);
        if (selectedEmails.length > 0) {
            setModalShow(true);
            get_job_details();
        } else {
            toast.dismiss(); toast.error('Select atleast One Employye')
        }
    }

    useEffect(() => {
        setUsers(candidates.map(item => ({ ...item, selected: false })))
    }, [candidates])

    const handleReject = () => {
        console.log('hello');
        let selectedIds = users.filter(candidate => candidate.selected).map((data) => data.id);
        if (selectedIds.length > 0) {
            dispatch(setLoading(true));
            axios.post('/send_rejection_emails', { candidate_ids: JSON.stringify(selectedIds) }).then(res => {
                console.log(res);
                dispatch(setLoading(false));
                if (res.success) {
                    toast.dismiss();
                    toast.success('Mail sent successfully');
                }
            }).catch(error => {
                dispatch(setLoading(false));
                toast.dismiss();
                toast.error('Something went wrong');
            })
        } else {
            toast.dismiss();
            toast.error('Select Candidate');
        }
    }

    return (
        <div className='main-candidates-wrapper'>
            <div className="user-table">
                <Table responsive="md">
                    <thead>
                        <tr>
                            <th className='text-center'><input type="checkbox" checked={selectAll} onChange={handleSelectAll} /></th>
                            <th className='text-center'>S.No</th>
                            <th className='text-center'>Name</th>
                            <th className='text-center'>Email</th>
                            <th className='text-center'>Contact Number</th>
                            <th className='text-center'>MCQ Score</th>
                            <th className='text-center'>AI Interview Score</th>
                        </tr>
                    </thead>
                    <tbody>
                        {users.map((item, index) => {
                            return (
                                <tr key={index}>
                                    <td className='text-center'><input type="checkbox" onChange={(e) => handleSingleSelect(e, index)} checked={item.selected} /></td>
                                    <td className='text-center'>{index + 1}</td>
                                    <td className='text-center' style={{color: '#1875fe', textDecoration: 'underline', cursor: 'pointer'}} onClick={() => navigate('/candidateSummary', { state : {userId: item.id, job_id}})}>{item.username}</td>
                                    <td className='text-center'>{item.email_id}</td>
                                    <td className='text-center'>{item.whatsapp_number}</td>
                                    <td className='text-center'>{item.mcq_score}</td>
                                    <td className='text-center'>{item.interview_score}</td>

                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            </div>
            <div className="bottom">
                <button onClick={openSendLink}>Send Offer Letter</button>
                <button onClick={handleReject}>Rejected Candidates</button>
            </div>
            <Modal
                className='send_offer_letter_modal'
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={modalShow}
                onHide={() => setModalShow(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Send Offer Letter</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSendOfferLetter} className='form-fields'>
                        <div className='job_title field'>
                            <label htmlFor="job_title">Job Title</label>
                            <input type='text' name='job_title' id='job_title' value={formData.job_title} disabled></input>
                            <span className='form-error'>{formDataError.job_titleError}</span>
                        </div>
                        <div className='job_location field'>
                            <label htmlFor="job_location">Job Location</label>
                            <input type='text' name='job_location' id='job_location' value={formData.job_location} disabled></input>
                            <span className='form-error'>{formDataError.job_locationError}</span>
                        </div>
                        <div className='gross_ctc field'>
                            <label htmlFor="gross_ctc">Gross CTC</label>
                            <input type='text' name='gross_ctc' id='gross_ctc' value={formData.gross_ctc} onChange={handleSendOfferFormChange}></input>
                            <span className='form-error'>{formDataError.gross_ctcError}</span>
                        </div>
                        <div className='fixed_ctc field'>
                            <label htmlFor="fixed_ctc">Fixed CTC</label>
                            <input type='text' name='fixed_ctc' id='fixed_ctc' value={formData.fixed_ctc} onChange={handleSendOfferFormChange}></input>
                            <span className='form-error'>{formDataError.fixed_ctcError}</span>
                        </div>
                        <div className='generate-offer-letter-btn'>
                            <button className='p-2'>Send Offer Letter</button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </div>
    )
}
