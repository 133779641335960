import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import HireNowXLogo from '../assests/images/HireNowXLogo.png';
import like from '../assests/images/like.png';
import './../scss/AssessmentSuccess.scss';


function AssessmentSuccess() {
  const navigate = useNavigate();
  const location = useLocation();
  const type = location?.state ? location?.state?.type : null;
  return (
    <div className='assessment-success-wrapper'>
      <div className='company-logo'>
        <img onClick={() => navigate('/')} src={HireNowXLogo} alt='Logo'></img>
      </div>
      <div className='assessment-success-content'>
        <img src={like} alt="img" />
        {type === null && (
          <div className='text-center message'>
            <h3>You have successfully completed the assessment</h3>
            <h3>You may now close this window.</h3>
          </div>
        )}
        {type === 'mcqslot' && (
          <div className='text-center mcq-slot message'>
            <h3>Your MCQ assessment has been successfully scheduled.</h3>
            <h3>You may now close this window.</h3>
          </div>
        )}
        {type === 'aislot' && (
          <div className='text-center message'>
            <h3>Your AI Interview assessment has been successfully scheduled.</h3>
            <h3>You may now close this window.</h3>
          </div>
        )}
      </div>
      <div className='powered-by-section'>
        <p>Powered by</p>
        <img src={HireNowXLogo} alt='img'></img>
      </div>
    </div>
  )
}

export default AssessmentSuccess