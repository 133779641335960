import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    loading: false,
    user: {},
    previewQuestions: []
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload;
        },

        setUser: (state, action) => {
            state.user = action.payload
        },

        setPreviewQuestions: (state, action) => {
            state.previewQuestions = action.payload
        }
    },
})

// Action creators are generated for each case reducer function
export const { setLoading, setUser, setPreviewQuestions } = userSlice.actions

export default userSlice.reducer