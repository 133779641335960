import React, { useEffect, useState } from 'react';
import '../../scss/JobDetails.scss'
import { useDispatch } from 'react-redux';
import { setLoading } from '../../redux/userSlice';
import axios from '../../util/api';
import toast from 'react-hot-toast';
import { useSearchParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { Modal } from 'react-bootstrap';
import { LuUpload } from "react-icons/lu";

function JobDetails() {
    const dispatch = useDispatch();
    const [params, setParams] = useSearchParams();
    const [job, setJob] = useState(null);
    const [modalShow, setModalShow] = useState(false);
    const [formData, setFormData] = useState({
        pdf_files: []
    });

    const [formDataError, setFormDataError] = useState({
        pdfFileError: ''
    })

    useEffect(() => {
        dispatch(setLoading(true));
        axios.post('get_job_details_jwt', { token: params.get('token') }).then(res => {
            console.log(res);
            dispatch(setLoading(false));
            if (res.success) {
                setJob(res.job);
            }
        }).catch(error => {
            console.log(error);
            dispatch(setLoading(false));
            toast.dismiss();
            toast.error('Invalid link');
        })
    }, [])

    const getInitials = (name) => {
        if (!name) {
            return '';
        }
        const names = name.split(' ');
        let initials = names[0].charAt(0).toUpperCase();
        if (names.length > 1) {
            initials += names[1].charAt(0).toUpperCase();
        }
        return initials;
    }

    const handleChange = (e) => {
        const { name, files } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: Array.from(files)
        }));
    }

    const validateFormErrors = () => {
        let isError = false;
        let errors = {
            pdfFileError: '',
        }

        if (!formData.pdf_files.length) {
            errors.pdfFileError = "Please upload your resume";
            isError = true;
        }

        setFormDataError(errors);
        return isError;
    }

    const handleApply = (e) => {
        e.preventDefault();
        if (!validateFormErrors()) {
            const formDataObj = new FormData();
            formDataObj.append('token', params.get('token'));
            formDataObj.append('pdf_file', formData.pdf_files[0]);
    
            dispatch(setLoading(true));
    
            axios.post('/upload_resume_job', formDataObj, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then((res) => {
                if (res.success) {
                    toast.success(res.message);
                    setModalShow(false);
                }
                dispatch(setLoading(false));
            }).catch((error) => {
                console.log(error);
                dispatch(setLoading(false));
                toast.error('Failed to upload resume');
            });
        }
    }

    return (
        <>
            {job && (
                <div className='job-details-wrapper'>
                    <div className='job-details-content-section'>
                        <div className='job-profile-details-section'>
                            <div className='logo'>{getInitials(job.job_title)}</div>
                            <div className='job-profile-details'>
                                <div className='heading-section'>
                                    <h2>{job.job_title}</h2>
                                    <div className='buttons'>
                                        <button onClick={() => setModalShow(true)}>Apply Now</button>
                                    </div>
                                </div>
                                <div className='details'>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td className='label'>Company Name</td>
                                                <td className='value'><span>:</span>{job.company_name}</td>
                                            </tr>
                                            <tr>
                                                <td className='label'>No. of Openings</td>
                                                <td className='value'><span>:</span>{job.number_of_opening}</td>
                                            </tr>
                                            <tr>
                                                <td className='label'>Location</td>
                                                <td className='value'><span>:</span>{job.job_location}</td>
                                            </tr>
                                            <tr>
                                                <td className='label'>Created Job</td>
                                                <td className='value'><span>:</span>{dayjs(job.creation_time).format('DD MMM YYYY')}</td>
                                            </tr>
                                            <tr>
                                                <td className='label'>Salary Range</td>
                                                <td className='value'><span>:</span>{job.salary_min_range}-{job.salary_max_range} LPA</td>
                                            </tr>
                                            <tr>
                                                <td className='label'>Job Type</td>
                                                <td className='value'><span>:</span>{job.job_type}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className='job-description-section'>
                            <div className='heading-section'>
                                <h2>Job Description:</h2>
                            </div>
                            <div className='description'>
                                <textarea value={job.job_description} disabled></textarea>
                            </div>
                        </div>

                        <div className='job-responsibilities-section'>
                            <div className='heading-section'>
                                <h2>Responsibilities:</h2>
                            </div>
                            <div className='responsibilities'>
                                <ul>
                                    {job.job_responsibility?.map((item, index) => {
                                        return <li key={index}>{item}</li>
                                    })}
                                </ul>
                            </div>
                        </div>


                        <div className='job-requirements-section'>
                            <div className='heading-section'>
                                <h2>Additional Requirements:</h2>
                            </div>
                            <div className='requirements'>
                                <ul>
                                    {job.additional_detail?.map((item, index) => {
                                        return <li key={index}>{item}</li>
                                    })}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <Modal
                        className='apply_now_modal'
                        size="md"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>Apply Now</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <form onSubmit={handleApply}>
                                <div className='upload_resume'>
                                    <h2>Upload for Extraction</h2>
                                    <label htmlFor='resume_upload'>
                                        <div className='upload-section'>
                                            <input id='resume_upload' className='d-none' type='file' accept='.pdf' onChange={handleChange} name='pdf_files'></input>
                                            <div className='logo'>
                                                <LuUpload size={24} />
                                            </div>
                                            <p>Click here to upload your resume</p>
                                        </div>
                                    </label>
                                    <p className='error'>{formDataError.pdfFileError}</p>
                                    {formData?.pdf_files.length > 0 && (
                                        <div className='resume_list'>
                                            {formData?.pdf_files?.map((pdf, index) => {
                                                return (
                                                    <div className='resume' key={index}>{pdf.name}</div>
                                                )
                                            })}
                                        </div>
                                    )}
                                </div>
                                <div className='button'>
                                    <button>Submit</button>
                                </div>
                            </form>
                        </Modal.Body>
                    </Modal>
                </div>
            )}
        </>
    )
}

export default JobDetails