import React, { useState, useEffect } from 'react';
import '../../scss/BookInterviewSlot.scss';
import toast from 'react-hot-toast';
import axios from '../../util/api';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../redux/userSlice';
import TokenService from '../../util/TokenService';
import HireNowXLogo from '../../assests/images/HireNowXLogo.png';
import TimedOutImg from '../../assests/images/timedOut.png';
import 'react-phone-number-input/style.css';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';

// const allowedCountries = ['US', 'CA', 'GB', 'IN', 'AU'];

function BookInterviewSlot() {
    const navigate = useNavigate();
    const [value, setValue] = useState();
    const [minDateTime, setMinDateTime] = useState('');
    const [selectedDateTime, setSelectedDateTime] = useState('');
    const [isFormExpired, setIsFormExpired] = useState(false);
    const [formData, setFormData] = useState({ name: '', email: '' });
    const [testType, setTestType] = useState("");
    const location = useLocation();
    const dispatch = useDispatch();
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');
    const [formErrors, setFormErrors] = useState({ nameError: '', whatsappNumberError: '', emailError: '', dateTimeError: '' });

    useEffect(() => {
        const now = new Date();
        if (token) {
            const decryptData = TokenService.decryptToken(token);
            const validUptoDate = new Date(decryptData.time);
            if (now > validUptoDate) {
                setIsFormExpired(true);
                return;
            }
            if (decryptData) {
                setFormData({ ...formData, email: decryptData.email });
                setTestType(decryptData.testType);
            }
        } else {
            setTimeout(() => {
                toast.dismiss(); toast.error('Token not found');
            }, 500);
        }

        // To apply validation for past date and time
        now.setMinutes(now.getMinutes() - now.getTimezoneOffset());
        const localDateTime = now.toISOString().slice(0, 16);
        setMinDateTime(localDateTime);
    }, [navigate, token]);

    const handleDateTimeChange = (e) => {
        const selectedTime = new Date(e.target.value);
        const currentTime = new Date();
        if (selectedTime < currentTime) {
            toast.dismiss(); toast.error("You cannot select a past time");
            setSelectedDateTime('');
        } else {
            setSelectedDateTime(e.target.value);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    const validateFormError = () => {
        let errors = {
            nameError: '',
            whatsappNumberError: '',
            emailError: '',
            dateTimeError: ''
        };
        let isError = false;
        
        if (!formData.name) {
            errors.nameError = 'Please enter name';
            isError = true;
        }

        if (!value) {
            errors.whatsappNumberError = "WhatsApp Number is required";
            isError = true;
        } else if (!isValidPhoneNumber(value)) {
            errors.whatsappNumberError = "Invalid Number";
            isError = true;
        }

        if (!formData.email) {
            errors.emailError = 'Please enter email';
            isError = true;
        }

        if (!selectedDateTime) {
            errors.dateTimeError = "Please select data and time";
            isError = true;
        }

        setFormErrors(errors);
        return isError;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!validateFormError() && token) {
            const data = {
                name: formData.name,
                datetime: selectedDateTime,
                whatsapp: value,
                token: token
            };
            if (testType === 'interview') {
                dispatch(setLoading(true));
                axios.post('/interview_form', data).then((res) => {
                    if (res.success) {
                        navigate('/thankyou', { state: { type: 'aislot' }, replace: true });
                    } else {
                        alert(res.message);
                    }
                    dispatch(setLoading(false));
                }).catch((error) => {
                    console.log(error);
                    dispatch(setLoading(false));
                });
            } else if (testType === 'mcq') {
                dispatch(setLoading(true));
                axios.post('/form', data).then((res) => {
                    if (res.success) {
                        navigate('/thankyou', { state: { type: 'mcqslot' }, replace: true });
                    } else {
                        toast.dismiss(); toast.error(res.message);
                    }
                    dispatch(setLoading(false));
                }).catch((error) => {
                    console.log(error);
                    dispatch(setLoading(false));
                    toast.dismiss(); toast.error('Internal error');
                });
            }
        }
    };

    return (
        <div className='book-slot-wrapper'>
            <div className='header'>
                <img src={HireNowXLogo} alt="logo" />
            </div>
            {isFormExpired ? (
                <div className='form-expired-container'>
                    <img src={TimedOutImg} alt='img'></img>
                    <div className='text-description'>
                        <h2>EXPIRED</h2>
                        <p>You cannot access the form now.</p>
                    </div>
                </div>) : (
                <div className='book-slot-container'>
                    <h1>Book Your {testType === 'interview' ? 'Avatar Interview' : 'MCQ Assessment'} Slot</h1>
                    <form onSubmit={handleSubmit}>
                        <div className='name field'>
                            <label htmlFor='name'>Name</label>
                            <input type='text' id='name' name='name' onChange={handleChange}></input>
                            <p className='error'>{formErrors.nameError}</p>
                        </div>
                        <div className='date-and-time field'>
                            <label htmlFor='dateAndTime'>Date and Time</label>
                            <input
                                type='datetime-local'
                                id='dateAndTime'
                                name='dateAndTime'
                                min={minDateTime}
                                value={selectedDateTime}
                                onChange={handleDateTimeChange}
                            ></input>
                            <p className='error'>{formErrors.dateTimeError}</p>
                        </div>
                        <div className='whatsapp-number field'>
                            <label htmlFor='whatsappNumber'>WhatsApp Number</label>
                            <PhoneInput
                                id='whatsappNumber'
                                defaultCountry="IN"
                                // countries={allowedCountries}
                                value={value}
                                onChange={setValue}
                            />
                            <p className='error'>{formErrors.whatsappNumberError}</p>
                        </div>
                        <div className='email field'>
                            <label htmlFor='email'>Email</label>
                            <input type='email' id='email' name='email' value={formData.email} disabled></input>
                            <p className='error'>{formErrors.emailError}</p>
                        </div>
                        <div className='submit'>
                            <button type='submit'>Submit</button>
                        </div>
                    </form>
                </div>)}
            <div className='powered-by-section'>
                <p>Powered by</p>
                <img src={HireNowXLogo} alt='img'></img>
            </div>
        </div>
    );
}

export default BookInterviewSlot;
