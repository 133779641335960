import React from 'react';
import { IoChevronBackCircleSharp, IoChevronForwardCircleSharp } from 'react-icons/io5';
import styled from 'styled-components';

function Pagination({ total, page, setPage }) {
  
  const handleNext = () => {
    if (page < total) {
      setPage(page + 1);
    }
  };

  const handlePrev = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  return (
    <Wrapper>
      <span onClick={handlePrev} className='icons'>
        <IoChevronBackCircleSharp />
      </span>
      <div className='text-muted total-text'>
        <span>{page}</span>
        <span className='total-middle-text'> of </span>
        <span>{total}</span>
      </div>
      <span onClick={handleNext} className='icons'>
        <IoChevronForwardCircleSharp />
      </span>
    </Wrapper>
  );
}

export default Pagination;
const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2px 0;

  .icons {
    font-size: 30px;
    margin: 0 15px;
    color: #0a84ed;
    cursor: pointer;
  }

  .total-text {
    letter-spacing: 1px;
    margin: 0 10px;
    color: #000 !important;
  }

  .per-page-dropdown {
    margin-left: 20px;

    label {
      margin-right: 5px;
      font-size: 14px;
    }

    select {
      padding: 5px;
      font-size: 14px;
    }
  }
`;