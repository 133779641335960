import React, { useEffect, useState } from 'react'
import Header from '../../../components/Header';
import FinalMCQTestPreview from '../../../components/FinalMCQTestPreview';
import axios from '../../../util/api';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading, setPreviewQuestions } from '../../../redux/userSlice';
import toast from 'react-hot-toast';

export default function PoolQuestions({ job_id, poolQuestion, setPoolQuestion }) {
    console.log('pool');

    const dispatch = useDispatch();
    const finalQuestion = useSelector(state => state.user.previewQuestions)
    const [selectAll, setSelectAll] = useState(false);

    const handleSelect = (e, index) => {
        let updatedList = [...poolQuestion];
        let updatedItem = { ...updatedList[index] };

        let isQuestionPresent = finalQuestion.find((item) => item.question === updatedItem.question);
        if (isQuestionPresent && e.target.checked) {
            toast.dismiss();
            toast.error('Question Already Present');
            return;
        }

        updatedItem.isSelected = e.target.checked;
        updatedList[index] = updatedItem;
        setSelectAll(updatedList.every(item => item.isSelected))
        setPoolQuestion(updatedList);

        if (e.target.checked) {
            let moveQuestion = { ...updatedList[index] };
            moveQuestion.pool = index
            moveQuestion.isSelected = false

            let newFinal = [...finalQuestion];
            newFinal.push(moveQuestion);

            dispatch(setPreviewQuestions(newFinal))
        } else {
            let newFinal = [...finalQuestion];
            newFinal = newFinal.filter(item => item.pool !== index);
            dispatch(setPreviewQuestions(newFinal));
        }
    }

    const handleSelectAll = (e) => {
        setSelectAll(e.target.checked);
        let updatedList = [...poolQuestion];
        setPoolQuestion(updatedList.map(item => ({ ...item, isSelected: e.target.checked })))

        if (e.target.checked) {
            let remainingManualQuestion = updatedList.filter(item => !item.isSelected).map((item, index) => ({ ...item, pool: index, isSelected: false }));
            let newFinal = [...finalQuestion, ...remainingManualQuestion];
            dispatch(setPreviewQuestions(newFinal));
        } else {
            let manualArray = Array.from({ length: poolQuestion.length }, (_, index) => index)
            let newFinal = [...finalQuestion];
            newFinal = newFinal.filter(item => !manualArray.includes(item.pool));
            dispatch(setPreviewQuestions(newFinal));
        }
    };

    const handleSubmit = () => {
        const selectedQuestions = poolQuestion.filter((question) => question.isSelected)
        console.log('selected Questions', selectedQuestions);
    }

    useEffect(() => {
        if (poolQuestion.length <= 0) {
            dispatch(setLoading(true));
            axios.post('get_pool_data', { job_id }).then(res => {
                console.log(res);
                dispatch(setLoading(false));
                if (res.success) {
                    setPoolQuestion(res.quizzes);
                }
            }).catch(error => {
                console.log(error);
                dispatch(setLoading(false));
            })
        }
    }, [])

    return (
        <div className='pool-wrapper'>
            <div className='pool-test-container'>
                <div className='d-flex p-2 gap-2'>
                    <input type='checkbox' id='selectAll' checked={selectAll} onChange={handleSelectAll}></input>
                    <label htmlFor='selectAll'>Select All</label></div>
                <div className='questions-container'>
                    {poolQuestion.map((question, index) => (
                        <div key={index} className='question-card'>
                            <div className="question-count d-flex gap-2"><input type='checkbox' checked={question.isSelected} onChange={(e) => handleSelect(e, index)}></input>Question {index + 1}</div>
                            <h3 className='question'>{question.question}</h3>
                            <ul className='options'>
                                <li>A. {question.optionone}</li>
                                <li>B. {question.optiontwo}</li>
                                <li>C. {question.optionthree}</li>
                                <li>D. {question.optionfour}</li>
                            </ul>
                            <p className='answer'><strong>Correct Answer:</strong> {question.answer}</p>
                        </div>
                    ))}
                </div>
            </div>
            <div className='final-mcq-test-preview-wrapper'>
                {finalQuestion.length ? (
                    <FinalMCQTestPreview job_id={job_id} />
                ) : (
                    <>
                        <h2>Final MCQ Test</h2>
                        <div className='select-the-questions-wrapper'>
                            <h2>Select the Questions</h2>
                            <p>To Finalize the Test!</p>
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}
