import React, { useEffect, useState } from 'react';
import { MdDelete } from "react-icons/md";
import axios from '../util/api';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading, setPreviewQuestions } from '../redux/userSlice';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

function FinalMCQTest({ job_id, manualQuestion, setManualQuestion, aiGeneratedQuestion, setAiGeneratedQuestion, poolQuestion, setPoolQuestion }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [selectAll, setSelectAll] = useState(false);
    const previewQuestions = useSelector(state => state.user.previewQuestions);
    const [finalQuestion, setFinalQuestion] = useState((JSON.parse(sessionStorage.getItem('questions')) || []));

    const handleSelect = (e, index) => {
        let updatedList = [...finalQuestion];
        let updatedItem = { ...updatedList[index] };
        updatedItem.isSelected = e.target.checked;
        updatedList[index] = updatedItem;
        setSelectAll(updatedList.every(item => item.isSelected))
        setFinalQuestion(updatedList);
    }

    const handleSelectAll = (e) => {
        setSelectAll(e.target.checked);
        let updatedList = [...finalQuestion];
        setFinalQuestion(updatedList.map(item => ({ ...item, isSelected: e.target.checked })));
    };

    const manageQuestionRemoveHandler = (selectedQuestions) => {
        const unSelectedQuestions = finalQuestion.filter((question) => !question.isSelected);
        setFinalQuestion(unSelectedQuestions);
        sessionStorage.setItem('questions', JSON.stringify(unSelectedQuestions));

        let question_String = [];
        let newManual = [...manualQuestion];
        let newAiGenerated = [...aiGeneratedQuestion];
        let newPool = [...poolQuestion];

        selectedQuestions.forEach(item => {
            question_String.push(item.question);

            if (('manual' in item) && item.manual <= manualQuestion.length - 1) {
                let updatedItem = { ...newManual[item.manual] };
                updatedItem.isSelected = false;
                newManual[item.manual] = updatedItem;
            }
            if (('ai' in item) && item.ai <= aiGeneratedQuestion.length - 1) {
                let updatedItem = { ...newAiGenerated[item.ai] };
                updatedItem.isSelected = false;
                newAiGenerated[item.ai] = updatedItem;
            }
            if (('pool' in item) && item.pool <= poolQuestion.length - 1) {
                let updatedItem = { ...newPool[item.pool] };
                updatedItem.isSelected = false;
                newPool[item.pool] = updatedItem;
            }
        });

        setManualQuestion(newManual);
        setAiGeneratedQuestion(newAiGenerated);
        setPoolQuestion(newPool);

        let newPreview = previewQuestions.filter(item => !question_String.includes(item.question));
        dispatch(setPreviewQuestions(newPreview));

    }

    const manageSingleQuestionRemoveHandler = (selectedQuestions) => {
        const unSelectedQuestions = finalQuestion.filter((question) => question.question !== selectedQuestions.question);
        setFinalQuestion(unSelectedQuestions);
        sessionStorage.setItem('questions', JSON.stringify(unSelectedQuestions));

        let question_String = [];
        let newManual = [...manualQuestion];
        let newAiGenerated = [...aiGeneratedQuestion];
        let newPool = [...poolQuestion];

        [selectedQuestions].forEach(item => {
            question_String.push(item.question);

            if (('manual' in item) && item.manual <= manualQuestion.length - 1) {
                let updatedItem = { ...newManual[item.manual] };
                updatedItem.isSelected = false;
                newManual[item.manual] = updatedItem;
            }
            if (('ai' in item) && item.ai <= aiGeneratedQuestion.length - 1) {
                let updatedItem = { ...newAiGenerated[item.ai] };
                updatedItem.isSelected = false;
                newAiGenerated[item.ai] = updatedItem;
            }
            if (('pool' in item) && item.pool <= poolQuestion.length - 1) {
                let updatedItem = { ...newPool[item.pool] };
                updatedItem.isSelected = false;
                newPool[item.pool] = updatedItem;
            }
        });

        setManualQuestion(newManual);
        setAiGeneratedQuestion(newAiGenerated);
        setPoolQuestion(newPool);

        let newPreview = previewQuestions.filter(item => !question_String.includes(item.question));
        dispatch(setPreviewQuestions(newPreview));

    }

    const handleDelete = () => {
        const selectedQuestions = finalQuestion.filter((question) => question.isSelected);
        if (selectedQuestions.length > 0) {
            const question_string = selectedQuestions.map((question) => {
                return question.question
            })

            const data = {
                job_id: job_id,
                question_string: JSON.stringify(question_string)
            }

            dispatch(setLoading(true));
            axios.post('/delete_quiz_questions', data).then((res) => {
                if (res.success) {
                    toast.success(res.message)
                    manageQuestionRemoveHandler(selectedQuestions);
                }
                dispatch(setLoading(false))
            }).catch((error) => {
                dispatch(setLoading(false))
                console.log(error)
            })
        } else {
            toast.error('Select atleast one question')
        }
    }

    const handleSingleDelete = (selectedQuestion) => {
        const selectedQuestions = [selectedQuestion];
        if (selectedQuestions.length > 0) {
            const question_string = selectedQuestions.map((question) => {
                return question.question
            })
            const data = {
                job_id: job_id,
                question_string: JSON.stringify(question_string)
            }

            dispatch(setLoading(true));
            axios.post('/delete_quiz_questions', data).then((res) => {
                if (res.success) {
                    toast.success(res.message);
                    manageSingleQuestionRemoveHandler(selectedQuestion)
                }
                dispatch(setLoading(false))
            }).catch((error) => {
                dispatch(setLoading(false))
                console.log(error)
            })
        } else {
            toast.error('Select atleast one question')
        }
    }

    const handleSaveQuestions = (e) => {
        e.preventDefault();
        dispatch(setLoading(true));
        const data = {
            job_id,
            quizzes: JSON.stringify(finalQuestion)
        }
        axios.post('/save_quiz', data).then(res => {
            console.log(res);
            dispatch(setLoading(false));
            if (res.success) {
                toast.success('Quiz updated Successfully');
                navigate(`/corporateDashboard/jobs/${job_id}`)
            }
        }).catch(error => {
            console.log(error);
            dispatch(setLoading(false));
        })
    }

    return (
        <div className='final-mcq-test-wrapper'>
            <div className='final-mcq-test-container'>
                {/* <h2>Final MCQ Test</h2> */}
                <div className='content-body'>
                    <div className='select-all-section'>
                        <div className='d-flex p-2 gap-2'>
                            {/* <input type='checkbox' id='selectAll' checked={selectAll} onChange={handleSelectAll}></input>
                            <label htmlFor='selectAll'>Select All</label> */}
                        </div>
                        <div className='delete'>
                            {/* <MdDelete size={24} onClick={handleDelete} /> */}
                            <div className='button'>
                                <button className='btn' onClick={handleSaveQuestions}>Save</button>
                            </div>
                        </div>
                    </div>
                    <div className='questions-container'>
                        {finalQuestion.map((question, index) => (
                            <div key={index} className='question-card'>
                                <div className="question-count d-flex gap-2">
                                    {/* <input type='checkbox' checked={question.isSelected} onChange={(e) => handleSelect(e, index)}></input> */}
                                    Question {index + 1}
                                    <MdDelete size={18} onClick={() => handleSingleDelete(question)} />
                                </div>
                                <h3 className='question'>{question.question}</h3>
                                <ul className='options'>
                                    <li>A. {question.optionone}</li>
                                    <li>B. {question.optiontwo}</li>
                                    <li>C. {question.optionthree}</li>
                                    <li>D. {question.optionfour}</li>
                                </ul>
                                <p className='answer'><strong>Correct Answer:</strong> {question.answer}</p>
                            </div>
                        ))}
                    </div>
                    {finalQuestion.length > 0 && (
                        <div className='button'>
                            <button className='btn' onClick={handleSaveQuestions}>Save</button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default FinalMCQTest