import React, { useEffect, useRef, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import FinalMCQTestPreview from '../../../components/FinalMCQTestPreview';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading, setPreviewQuestions } from '../../../redux/userSlice';
import axios from '../../../util/api';
import noQuestionsYetImg from '../../../assests/images/no_questions_yet.png';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import toast from 'react-hot-toast';

export default function Manual({ job_id, manualQuestion, setManualQuestion }) {
    const dispatch = useDispatch();
    const finalQuestion = useSelector(state => state.user.previewQuestions);
    const [modalShow, setModalShow] = useState(false);
    const [selectAll, setSelectAll] = useState(false);
    const fileInputRef = useRef(null);
    const [addQuestionFormData, setAddQuestionFormData] = useState({
        question: '',
        optionA: '',
        optionB: '',
        optionC: '',
        optionD: '',
        correctAnswer: ''
    })
    const [errors, setErrors] = useState({});
    const handleSelect = (e, index) => {
        let updatedList = [...manualQuestion];
        let updatedItem = { ...updatedList[index] };

        let isQuestionPresent = finalQuestion.find((item) => item.question === updatedItem.question);
        if (isQuestionPresent && e.target.checked) {
            toast.dismiss();
            toast.error('Question Already Present');
            return;
        }

        updatedItem.isSelected = e.target.checked;
        updatedList[index] = updatedItem;
        setSelectAll(updatedList.every(item => item.isSelected))
        setManualQuestion(updatedList);

        if (e.target.checked) {
            let moveQuestion = { ...updatedList[index] };
            moveQuestion.manual = index
            moveQuestion.isSelected = false

            let newFinal = [...finalQuestion];
            newFinal.push(moveQuestion);

            dispatch(setPreviewQuestions(newFinal))
        } else {
            let newFinal = [...finalQuestion];
            newFinal = newFinal.filter(item => item.manual !== index);
            dispatch(setPreviewQuestions(newFinal));
        }
    }

    const handleSelectAll = (e) => {
        setSelectAll(e.target.checked);
        let updatedList = [...manualQuestion];
        setManualQuestion(updatedList.map(item => ({ ...item, isSelected: e.target.checked })))

        if (e.target.checked) {
            let remainingManualQuestion = updatedList.filter(item => !item.isSelected).map((item, index) => ({ ...item, manual: index }));
            let newFinal = [...finalQuestion, ...remainingManualQuestion];
            dispatch(setPreviewQuestions(newFinal));
        } else {
            let manualArray = Array.from({ length: manualQuestion.length }, (_, index) => index)
            let newFinal = [...finalQuestion];
            newFinal = newFinal.filter(item => !manualArray.includes(item.manual));
            dispatch(setPreviewQuestions(newFinal));
        }
    };

    const handleDownloadTemplate = () => {
        const data = [
            {
                "job_title": "",
                "question": "",
                "optionone": "",
                "optiontwo": "",
                "optionthree": "",
                "optionfour": "",
                "answer": ""
            }
        ]

        const ws = XLSX.utils.json_to_sheet(data);

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

        const fileName = "questionsTemplate.xlsx";

        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
        saveAs(blob, fileName);
    };

    const handleCSVFile = (e) => {
        let file = e.target.files[0];
        console.log(file);
        dispatch(setLoading(true));
        let data = {
            job_id: job_id,
            file
        }
        axios.post('/exel_upload_quiz', data).then(res => {
            console.log(res);
            dispatch(setLoading(false));
            if (res.success) {
                let newManual = [...manualQuestion, ...res.quiz.map((item => ({ ...item, isSelected: false })))]
                setManualQuestion(newManual);
                setSelectAll(false);
            }
        }).catch(error => {
            console.log(error);
            dispatch(setLoading(false));
        });
        fileInputRef.current.value = null;
    }

    const handleChange = (e) => {
        const { value, name } = e.target;
        setAddQuestionFormData((prevData) => {
            return (
                {
                    ...prevData,
                    [name]: value,
                }
            )
        })
    }

    const validateForm = (formData) => {
        let validationErrors = {};
        if (!formData.question.trim()) validationErrors.question = "Question is required.";
        if (!formData.optionA.trim()) validationErrors.optionA = "Option A is required.";
        if (!formData.optionB.trim()) validationErrors.optionB = "Option B is required.";
        if (!formData.optionC.trim()) validationErrors.optionC = "Option C is required.";
        if (!formData.optionD.trim()) validationErrors.optionD = "Option D is required.";
        if (!formData.correctAnswer.trim()) validationErrors.correctAnswer = "Correct Answer is required.";
        if (![formData.optionA, formData.optionB, formData.optionC, formData.optionD].includes(formData.correctAnswer)) {
            validationErrors.correctAnswer = "Correct Answer must match one of the options.";
        }
        return validationErrors;
    };

    const handleAddManual = (e) => {
        e.preventDefault();
        const errors = validateForm(addQuestionFormData);
        if (Object.keys(errors).length > 0) {
            setErrors(errors);
            console.log("Validation failed:", errors);
        } else {
            setErrors({})
            console.log("Form is valid. Handle add manually:", addQuestionFormData);
            let newQuestion = {
                job_title: "React Developer",
                question: "Which algorithm is used for finding patterns in time series data?",
                optionfour: "Linear Regression",
                optionone: "ARIMA",
                optionthree: "Naive Bayes",
                optiontwo: "SVM",
                answer: "option 3"
            }

            let newFinal = [...finalQuestion];
            newFinal.push(newQuestion);

            dispatch(setPreviewQuestions(newFinal))
            setModalShow(false);
        }
    };

    return (
        <div className='manual-wrapper'>
            <div className='manual-test-container'>
                <div className='buttons-section'>
                    <input type="file" hidden id="fileInput" ref={fileInputRef} onChange={handleCSVFile} />
                    <button onClick={handleDownloadTemplate}>Download Template</button>
                    <button onClick={() => fileInputRef.current.click()}>Upload Questions</button>
                    <button onClick={() => setModalShow(true)}>Add Manually</button>
                </div>
                {manualQuestion.length ? (
                    <div className='manual-test'>
                        <div className='d-flex p-2 gap-2'>
                            <input type='checkbox' id='selectAll' checked={selectAll} onChange={handleSelectAll}></input>
                            <label htmlFor='selectAll'>Select All</label>
                        </div>
                        <div className='questions-container'>
                            {manualQuestion.map((question, index) => (
                                <div key={index} className='question-card'>
                                    <div className="question-count d-flex gap-2"><input type='checkbox' checked={question.isSelected} onChange={(e) => handleSelect(e, index)}></input>Question {index + 1}</div>
                                    <h3 className='question'>{question.question}</h3>
                                    <ul className='options'>
                                        <li>A. {question.optionone}</li>
                                        <li>B. {question.optiontwo}</li>
                                        <li>C. {question.optionthree}</li>
                                        <li>D. {question.optionfour}</li>
                                    </ul>
                                    <p className='answer'><strong>Correct Answer:</strong> {question.answer}</p>
                                </div>
                            ))}
                        </div>
                    </div>

                ) : (
                    <div className='no-question-found-wrapper'>
                        <img src={noQuestionsYetImg} alt='Img'></img>
                        <div className='description-text'>
                            <h2>No Questions Yet!</h2>
                            <p><span onClick={handleDownloadTemplate}>Download Template</span> and upload question.</p>
                        </div>
                    </div>
                )}
            </div>
            <div className='final-mcq-test-preview-wrapper'>
                {finalQuestion.length ? (
                    <FinalMCQTestPreview job_id={job_id} />
                ) : (
                    <>
                        <h2>Final MCQ Test</h2>
                        <div className='select-the-questions-wrapper'>
                            <h2>Select the Questions</h2>
                            <p>To Finalize the Test!</p>
                        </div>
                    </>
                )}
            </div>
            <Modal
                className='add_question_modal'
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={modalShow}
                onHide={() => setModalShow(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Add Question</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleAddManual}>
                        <div className='question field'>
                            <label htmlFor='question'>Question<span>*</span></label>
                            <input type='text' id='question' name='question' value={addQuestionFormData.question} onChange={handleChange}></input>
                            <span className='form-error'>{errors.question}</span>
                        </div>
                        <div className='option_a field'>
                            <label htmlFor='option_a'>Option A<span>*</span></label>
                            <input type='text' id='option_a' name='optionA' value={addQuestionFormData.optionA} onChange={handleChange}></input>
                            <span className='form-error'>{errors.optionA}</span>
                        </div>
                        <div className='option_b field'>
                            <label htmlFor='option_b'>Option B<span>*</span></label>
                            <input type='text' id='option_b' name='optionB' value={addQuestionFormData.optionB} onChange={handleChange}></input>
                            <span className='form-error'>{errors.optionB}</span>
                        </div>
                        <div className='option_c field'>
                            <label htmlFor='option_c'>Option C<span>*</span></label>
                            <input type='text' id='option_c' name='optionC' value={addQuestionFormData.optionC} onChange={handleChange}></input>
                            <span className='form-error'>{errors.optionC}</span>
                        </div>
                        <div className='option_d field'>
                            <label htmlFor='option_d'>Option D<span>*</span></label>
                            <input type='text' id='option_d' name='optionD' value={addQuestionFormData.optionD} onChange={handleChange}></input>
                            <span className='form-error'>{errors.optionD}</span>
                        </div>
                        <div className='correct_answer field'>
                            <label htmlFor='correctAnswer'>Correct Answer<span>*</span></label>
                            <input type='text' id='correctAnswer' name='correctAnswer' value={addQuestionFormData.correctAnswer} onChange={handleChange}></input>
                            <span className='form-error'>{errors.correctAnswer}</span>
                        </div>
                        <div className='save-btn'>
                            <button className='btn'>Save</button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </div>
    )
}
