import toast from 'react-hot-toast';
import axios from '../../util/api';
import React, { useState, useEffect } from 'react';
import { BiEdit } from "react-icons/bi";
import { MdDeleteOutline } from "react-icons/md";
// import CreatePost from '../../components/CreatePost';
// import ShortlistResume from '../../components/ShortlistResume';
import { useNavigate, Link } from 'react-router-dom';
import { FaSearch } from "react-icons/fa";
import { setLoading } from '../../redux/userSlice';
import { useDispatch } from 'react-redux';
import Switch from 'react-switch';
import Header from '../../components/Header';
import Select from "react-select";
import { ProgressBar } from 'react-bootstrap';
import Pagination from '../../components/Pagination';
import dayjs from 'dayjs';
import Swal from 'sweetalert2';
import { IoSearchOutline } from "react-icons/io5";
import tokenService from '../../util/TokenService';

const job_type_options = [
  { value: "allJobs", label: "All Jobs" },
  { value: "1", label: "Active" },
  { value: "0", label: "Not Active" },
];

function Jobs() {
  const [job_type, setJob_type] = useState(job_type_options[0]);
  const [jobs, setJobs] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [search, setSearch] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = tokenService.getUser();
  console.log(user);

  const fetchJobs = () => {
    const data = {
      job_status: job_type.value === 'allJobs' ? '' : job_type.value,
      search_term: search,
      page: page,
      page_size: 9
    }
    dispatch(setLoading(true));
    axios.post('/get_jobs', data).then((res) => {
      if (res.success) {
        setJobs(res.jobs);
        if (res.total_pages <= 0) {
          setTotalPages(1);
        } else {
          setTotalPages(res.total_pages);
        }
      } else {
        toast.dismiss(); toast.error('error in fetching jobs')
      }
      dispatch(setLoading(false));
    }).catch((error) => {
      dispatch(setLoading(false));
      console.log(error);
      toast.dismiss(); toast.error('Internal server error')
    })
  }

  useEffect(() => {
    fetchJobs();
  }, [job_type, page])

  const handleSwitchChange = (job_id) => {
    const jobToUpdate = jobs.find((job) => job.job_id === job_id);
    if (!jobToUpdate) return;

    const previousStatus = jobToUpdate.job_status;
    const updatedJobs = jobs.map((job) => {
      if (job.job_id === job_id) {
        return { ...job, job_status: job.job_status === 1 ? 0 : 1 };
      }
      return job;
    });

    setJobs(updatedJobs);

    const data = {
      job_id: job_id,
    };

    axios.post('/update_job_status', data)
      .then((response) => {
        if (response.success) {
          toast.dismiss(); toast.success('Job Status updated successfully');
        } else {
          const revertedJobs = jobs.map((job) => {
            if (job.job_id === job_id) {
              return { ...job, job_status: previousStatus };
            }
            return job;
          });
          setJobs(revertedJobs);
          toast.dismiss(); toast.error('Failed to update job status');
        }
      })
      .catch((error) => {
        console.log(error);
        const revertedJobs = jobs.map((job) => {
          if (job.job_id === job_id) {
            return { ...job, job_status: previousStatus };
          }
          return job;
        });
        setJobs(revertedJobs);
        toast.dismiss(); toast.error('Failed to update job status');
      });
  };

  const handleDeleteJob = (job_id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0a84ed",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete"
    }).then((result) => {
      if (result.isConfirmed) {
        if (job_id) {
          const data = {
            job_id
          }
          dispatch(setLoading(true))
          axios.post('/delete_job', data).then((res) => {
            if (res.success) {
              setJobs((prevData) => (prevData.filter((job) => job.job_id !== job_id)));
              toast.dismiss(); toast.success(res.message);
            } else {
              toast.dismiss(); toast.error(res.message);
            }
            dispatch(setLoading(false))
          }).catch((error) => {
            dispatch(setLoading(false))
            toast.dismiss(); toast.error('Something went wrong');
            console.log(error);
          })
        }
      }
    });
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      setPage(1);
      fetchJobs();
    }
  }

  return (
    <div className='jobs-wrapper d-flex flex-column gap-4'>
      <Header heading={'Job Listings'} description={'Overview of job listings created till date'} />
      <div className='search-with-dropdown'>
        <div className='search'>
          <input type='text' value={search} onChange={(e) => setSearch(e.target.value)} onKeyDown={handleKeyDown} placeholder='Search by Job title'></input>
          <IoSearchOutline size={24} onClick={fetchJobs} />
        </div>
        <div className='job-switch'>
          <Select
            value={job_type}
            onChange={setJob_type}
            options={job_type_options}
          />
        </div>
      </div>
      <div className='jobs-content'>
        {jobs.map((job, index) => {
          return (
            <div className='card' key={index}>
              <div className='card-header' style={job.job_status === 0 ? { backgroundColor: '#ECECEC' } : { backgroundColor: '#DFF3FE' }}>
                <div className='job-switch-section'>
                  <Switch
                    onChange={() => handleSwitchChange(job.job_id)}
                    checked={job.job_status === 1 ? true : false}
                    height={20}
                    width={40}
                    handleDiameter={18}
                    offColor='#C9C9C9'
                    onColor='#49AF4F'
                    uncheckedIcon={false}
                    checkedIcon={false}
                    disabled={!(user?.role === 'corporateAdmin' || user?.user_id === job.user_id)}
                  />
                  {(user?.role === 'corporateAdmin' || user?.user_id === job.user_id) && (
                    <div className='delete' onClick={() => handleDeleteJob(job.job_id)}><MdDeleteOutline /></div>
                  )}
                </div>
                <div className='organization-details-section'>
                  <span className='organization'>{job.company_name}</span>
                  <h2 className='designation'>{`${job.job_title} (${job.job_id})`}</h2>
                  <p className='position'>Position : {job.number_of_opening}</p>
                </div>
                <div className='hiring-pipeline-section'>
                  <div className='hired'>
                    <span>Hired</span>
                    <div className='progress-bar-data'>
                      <ProgressBar now={job.hired.hired_count / job.number_of_opening * 100} />
                      <span>{job.hired.hired_count}</span>
                    </div>
                  </div>
                  <div className='pipeline'>
                    <span>Pipelined</span>
                    <div className='progress-bar-data'>
                      <ProgressBar now={job.pipeline.total_candidates / job.number_of_opening * 100} />
                      <span>{job.pipeline.total_candidates}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className='card-footer-section'>
                <div className='created-on'>
                  <span className='created-on-text'>Created on</span>
                  <span className='created-on-date'>{dayjs(job.creation_time).format('DD MMM YYYY')}</span>
                </div>
                <div className='btn-section'>
                  <button onClick={() => {
                    sessionStorage.removeItem('questions');
                    navigate(`/corporateDashboard/jobs/${job.job_id}`)}}>Details</button>
                </div>
              </div>
            </div>
          )
        })}
      </div>
      <Pagination page={page} setPage={setPage} total={totalPages} />
    </div>
  )
}

export default Jobs