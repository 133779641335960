import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import '../../scss/Job.scss';
import axios from '../../util/api';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../redux/userSlice';
import TokenService from '../../util/TokenService';
import Header from '../../components/Header';
import dayjs from 'dayjs';
import { Modal } from 'react-bootstrap';
import Select from "react-select";
import { FiPlusCircle } from "react-icons/fi";
import { FiMinusCircle } from "react-icons/fi";
import { IoShareSocialOutline } from "react-icons/io5";
import { FaLink } from "react-icons/fa6";

const jobTypeOptions = [
    { value: 'Full-Time', label: 'Full-Time' },
    { value: 'Part-Time', label: 'Part-Time' }
]

function Job() {
    const { jobId } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = TokenService.getUser();
    const [job, setJob] = useState({});
    const [modalShow, SetModalShow] = useState(false);
    const [minDateTime, setMinDateTime] = useState('');
    const [link, setLink] = useState('');
    const [shareJobModalShow, setShareJobModalShow] = useState(false);
    const [editFormData, setEditFormData] = useState({
        designation: '',
        company: '',
        no_of_openings: '',
        location: '',
        job_description: '',
        minSalary: '',
        maxSalary: ''
    });
    const [editFormErrors, setEditFormDataErrors] = useState({
        designationError: '',
        companyError: '',
        no_of_openingsError: '',
        locationError: '',
        job_descriptionError: '',
        minSalaryError: '',
        maxSalaryError: '',
        responsibilitiesError: '',
        requirementsError: ''
    });
    const [jobType, setJobType] = useState(jobTypeOptions[0]);
    const [responsibilities, setResponsibilities] = useState(['']);
    const [additionalRequirements, setAdditionalRequirements] = useState(['']);

    const getInitials = (name) => {
        if (!name) {
            return '';
        }
        const names = name.split(' ');
        let initials = names[0].charAt(0).toUpperCase();
        if (names.length > 1) {
            initials += names[1].charAt(0).toUpperCase();
        }
        return initials;
    }

    const handleEditModalClick = () => {
        setEditFormData((prevData) => ({
            ...prevData,
            designation: job.job_title,
            company: job.company_name,
            no_of_openings: job.number_of_opening,
            location: job.job_location,
            job_description: job.job_description,
            minSalary: job.salary_min_range?.toString(),
            maxSalary: job.salary_max_range?.toString(),
        }))
        let responsibilities = job.job_responsibility ? job?.job_responsibility?.filter((responsibility) => responsibility.trim().length > 0) : [];
        if (responsibilities.length === 0) {
            responsibilities = ['']
        };
        setResponsibilities(responsibilities);
        let additionalDetails = job.additional_detail ? job.additional_detail.filter((additional_detail) => additional_detail.trim().length > 0) : [];
        if (additionalDetails.length === 0) {
            additionalDetails = [''];
        }
        setAdditionalRequirements(additionalDetails);
        setJobType((prevData) => ({ ...prevData, value: job.job_type }));
        SetModalShow(true);
    }

    const handleResponsibilitiesChange = (e, index) => {
        const newResponsibilities = [...responsibilities];
        newResponsibilities[index] = e.target.value;
        setResponsibilities(newResponsibilities);
    }

    const handleResponsibilitiesIncrement = () => {
        if (responsibilities.length < 6) {
            setResponsibilities([...responsibilities, ''])
        }
    }

    const handleResponsibilitiesDecrement = (index) => {
        if (responsibilities.length > 1) {
            const newKeySkills = responsibilities.filter((_, i) => i !== index);
            setResponsibilities(newKeySkills);
        }
    }

    const handleRequirementsChange = (e, index) => {
        const newAdditionalRequirements = [...additionalRequirements];
        newAdditionalRequirements[index] = e.target.value;
        setAdditionalRequirements(newAdditionalRequirements);
    }

    const handleRequirementsIncrement = (index) => {
        if (additionalRequirements.length < 6) {
            setAdditionalRequirements([...additionalRequirements, ''])
        }
    }

    const handleRequirementsDecrement = (index) => {
        if (additionalRequirements.length > 1) {
            const newAdditionalRequirements = additionalRequirements.filter((_, i) => i !== index);
            setAdditionalRequirements(newAdditionalRequirements);
        }
    }

    const handleEditFormChange = (e) => {
        const { name, value } = e.target;
        setEditFormData((prevData) => ({
            ...prevData,
            [name]: value
        }))
    }

    const validateEditForm = () => {
        const errors = {
            designationError: '',
            companyError: '',
            no_of_openingsError: '',
            locationError: '',
            job_descriptionError: '',
            minSalaryError: '',
            maxSalaryError: '',
            requirementsError: '',
            responsibilitiesError: ''
        }

        let isError = false;

        if (!editFormData?.designation?.trim()) {
            errors.designationError = "Please enter valid designation";
            isError = true;
        }

        if (!editFormData?.company?.trim()) {
            errors.companyError = "Please enter valid company";
            isError = true;
        }

        if (!editFormData?.no_of_openings) {
            errors.no_of_openingsError = "Please enter no of openings";
            isError = true;
        }

        if (!editFormData?.location?.trim()) {
            errors.locationError = 'Please enter location';
            isError = true;
        }

        if (!editFormData?.job_description?.trim()) {
            errors.job_descriptionError = 'Please enter Job description';
            isError = true;
        }

        if (!editFormData?.minSalary?.trim()) {
            errors.minSalaryError = "Please enter min salary";
            isError = true;
        }

        if (!editFormData?.maxSalary?.trim()) {
            errors.maxSalaryError = "Please enter max salary";
            isError = true;
        }

        // if (additionalRequirements?.some(requirement => requirement.trim() === '')) {
        //   errors.requirementsError = "Please enter all requirements";
        //   isError = true;
        // }

        // if (responsibilities?.some(responsibility => responsibility.trim() === '')) {
        //   errors.responsibilitiesError = 'Please enter all responsibilities';
        //   isError = true;
        // }

        setEditFormDataErrors(errors);
        return isError;
    }

    const handleCopyLink = () => {
        navigator.clipboard.writeText(link).then(() => {
            toast.success('Link copied successfully')
        })
    }

    const handleEdit = (e) => {
        e.preventDefault();

        if (!validateEditForm()) {
            const data = {
                job_title: editFormData.designation,
                job_location: editFormData.location,
                no_of_openings: editFormData.no_of_openings,
                job_responsibility: JSON.stringify(responsibilities),
                salary_min_range: editFormData.minSalary,
                salary_max_range: editFormData.maxSalary,
                additional_detail: JSON.stringify(additionalRequirements),
                job_description: editFormData.job_description,
                job_type: jobType.value,
                job_id: jobId
            }
            dispatch(setLoading(true))
            axios.post('/edit_job', data).then((res) => {
                if (res.success) {
                    const payload = {
                        job_id: jobId
                    }
                    toast.dismiss(); toast.success(res.message);
                    getJobDetails(payload)
                    SetModalShow(false);
                }
                dispatch(setLoading(false));
            }).catch((error) => {
                dispatch(setLoading(false));
                console.log(error)
            })
        }
    }

    function getJobDetails(data) {
        if (data) {
            dispatch(setLoading(true))
            axios.post('/get_job_details', data).then((res) => {
                if (res.success) {
                    setJob(res.job);
                }
                dispatch(setLoading(false))
            }).catch((error) => {
                console.log(error);
                toast.dismiss(); toast.error('Internal error');
                dispatch(setLoading(false))
            })
        }
    }

    useEffect(() => {
        const now = new Date();
        now.setMinutes(now.getMinutes() - now.getTimezoneOffset());
        const localDateTime = now.toISOString().slice(0, 16);
        setMinDateTime(localDateTime);

        const data = {
            job_id: jobId
        }

        getJobDetails(data);
    }, [])

    const handleShareJob = () => {
        dispatch(setLoading(true));
        axios.post('share_link_job', { job_id: jobId }).then(res => {
            dispatch(setLoading(false));
            if (res.success) {
                setLink(res.share_link);
                setShareJobModalShow(true);
            }
        }).catch(error => {
            console.log(error);
            dispatch(setLoading(false));
            toast.dismiss();
            toast.error('Something went wrong');
        })
    }

    return (
        <div className='job-wrapper'>
            <Header heading={'Jobs Description'} description={'Lets find your next great hire today'} />
            <div className='job-details-content-section'>
                <div className='job-profile-details-section'>
                    <div className='logo'>{getInitials(job?.job_title)}</div>
                    <div className='job-profile-details'>
                        <div className='heading-section'>
                            <h2>{job.job_title}</h2>
                            <div className='buttons'>
                                <IoShareSocialOutline onClick={handleShareJob} className='share' size={28} />
                                <span style={{ background: job.job_status === 0 ? '#ececec' : '', color: job.job_status === 0 ? '#000' : '' }}>{job.job_status ? 'Active' : 'Inactive'}</span>
                                {(user?.role === 'corporateAdmin' || user?.user_id === job.user_id) && (
                                    <button onClick={handleEditModalClick}>Edit</button>
                                )}
                            </div>
                        </div>
                        <div className='details'>
                            <table>
                                <tbody>
                                    <tr>
                                        <td className='label'>Company Name</td>
                                        <td className='value'><span>:</span>{job.company_name}</td>
                                    </tr>
                                    <tr>
                                        <td className='label'>No. of Openings</td>
                                        <td className='value'><span>:</span>{job.number_of_opening}</td>
                                    </tr>
                                    <tr>
                                        <td className='label'>Location</td>
                                        <td className='value'><span>:</span>{job.job_location}</td>
                                    </tr>
                                    <tr>
                                        <td className='label'>Created Job</td>
                                        <td className='value'><span>:</span>{dayjs(job.creation_time).format('DD MMM YYYY')}</td>
                                    </tr>
                                    <tr>
                                        <td className='label'>Salary Range</td>
                                        <td className='value'><span>:</span>{job.salary_min_range}-{job.salary_max_range} LPA</td>
                                    </tr>
                                    <tr>
                                        <td className='label'>Job Type</td>
                                        <td className='value'><span>:</span>{job.job_type}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                {job.job_status === 1 && (
                    <div className='buttons-section'>
                        <button onClick={() => navigate('/corporateDashboard/jobs/questions', { state: { job_id: job.job_id, date: job.creation_time, title: job.job_title } })}>Create Question Paper</button>
                        <button onClick={() => navigate('/corporateDashboard/jobs/addResume', {
                            state: {
                                data: {
                                    job_id: job.job_id,
                                    job_title: job.job_title,
                                    job_location: job.job_location,
                                    job_description: job.job_description
                                }
                            }
                        })}>Add Resumes</button>
                        <button onClick={() => navigate('/corporateDashboard/jobs/candidates', { state: { job_id: job.job_id } })}>Candidates List</button>
                    </div>
                )}
                <div className='job-description-section'>
                    <div className='heading-section'>
                        <h2>Job Description:</h2>
                    </div>
                    <div className='description'>
                        <textarea value={job.job_description} rows={10} style={{ width: '100%', border: 'none', background: '#fff' }} disabled></textarea>
                    </div>
                </div>
                {job?.job_responsibility?.filter((responsibility) => responsibility && responsibility.trim().length > 0).length > 0 && (
                    <div className='job-responsibilities-section'>
                        <div className='heading-section'>
                            <h2>Responsibilities:</h2>
                        </div>
                        <div className='responsibilities'>
                            <ul>
                                {job?.job_responsibility?.filter((responsibility) => responsibility.trim().length > 0)?.map((item, index) => {
                                    return <li key={index}>{item}</ li>
                                })}
                            </ul>
                        </div>
                    </div>
                )}
                {job?.additional_detail?.filter((detail) => detail && detail.trim().length > 0).length > 0 && (
                    <div className='job-requirements-section'>
                        <div className='heading-section'>
                            <h2>Additional Requirements:</h2>
                        </div>
                        <div className='requirements'>
                            <ul>
                                {job?.additional_detail?.filter((detail) => detail.trim().length > 0)?.map((item, index) => (
                                    <li key={index}>{item}</li>
                                ))}
                            </ul>
                        </div>
                    </div>
                )}
            </div>
            <Modal
                className='edit_modal'
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={modalShow}
                onHide={() => SetModalShow(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Edit Job Description</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleEdit} className='form fields'>
                        <div className='designation field'>
                            <label htmlFor="designation">Job Title:<span className='mandate-field'>*</span></label>
                            <input type='text' id='designation' name='designation' onChange={handleEditFormChange} value={editFormData.designation}></input>
                            <p className='form-error'>{editFormErrors.designationError}</p>
                        </div>
                        <div className='company field'>
                            <label htmlFor='company'>Company Name:<span className='mandate-field'>*</span></label>
                            <input type='text' name='company' id='company' onChange={handleEditFormChange} value={editFormData.company}></input>
                            <p className='form-error'>{editFormErrors.companyError}</p>
                        </div>
                        <div className='no-of-openings field'>
                            <label htmlFor='no_of_openings'>No. of Openings:<span className='mandate-field'>*</span></label>
                            <input type='number' name='no_of_openings' id='no_of_openings' onChange={handleEditFormChange} value={editFormData.no_of_openings}></input>
                            <p className='form-error'>{editFormErrors.no_of_openingsError}</p>
                        </div>
                        <div className='location field'>
                            <label htmlFor='location'>Location:<span className='mandate-field'>*</span></label>
                            <input type='text' name='location' id='location' onChange={handleEditFormChange} value={editFormData.location}></input>
                            <p className='form-error'>{editFormErrors.locationError}</p>
                        </div>
                        <div className='salary-range field'>
                            <label htmlFor='salary_range'>Salary Range:<span className='mandate-field'>*</span></label>
                            <div className='salary-inputs'>
                                <div className='salary-input'>
                                    <input type='text' value={editFormData.minSalary} name='minSalary' onChange={handleEditFormChange}></input>
                                    <span>LPA</span>
                                </div>
                                <span className='seperator'> - </span>
                                <div className='salary-input'>
                                    <input type='text' value={editFormData.maxSalary} name='maxSalary' onChange={handleEditFormChange}></input>
                                    <span>LPA</span>
                                </div>
                            </div>
                            <p className='form-error'>{editFormErrors.minSalaryError}</p>
                            <p className='form-error'>{editFormErrors.maxSalaryError}</p>
                        </div>
                        <div className='job-type field'>
                            <label htmlFor='job_type'>Job Type:<span className='mandate-field'>*</span></label>
                            <Select
                                value={jobType}
                                onChange={setJobType}
                                options={jobTypeOptions}
                            />
                        </div>
                        <div className='job-description field'>
                            <label htmlFor='job_description'>Job description:<span className='mandate-field'>*</span></label>
                            <textarea id='job_description' name='job_description' className='job_description' onChange={handleEditFormChange} value={editFormData.job_description}></textarea>
                            <p className='form-error'>{editFormErrors.job_descriptionError}</p>
                        </div>
                        <div className='responsibilities field'>
                            <label htmlFor='responsibilities'>Responsibilities:</label>
                            {responsibilities.map((responsibility, index) => {
                                return (
                                    <div className='responsibilities-input-section' key={index}>
                                        <input type='text' id='responsibilities' name='responsibilities' value={responsibility} onChange={(e) => handleResponsibilitiesChange(e, index)}></input>
                                        <div className='increment-decrement-buttons'>
                                            <FiPlusCircle onClick={() => handleResponsibilitiesIncrement(index)} size={20} />
                                            <FiMinusCircle onClick={() => handleResponsibilitiesDecrement(index)} size={20} />
                                        </div>
                                    </div>
                                )
                            })}
                            <p className='form-error'>{editFormErrors.responsibilitiesError}</p>
                        </div>
                        <div className='requirements field'>
                            <label htmlFor='requirements'>Additional Requirements:</label>
                            {additionalRequirements.map((requirement, index) => {
                                return (
                                    <div className='requirements-input-section' key={index}>
                                        <input type='text' id='requirements' name='requirements' value={requirement} onChange={(e) => handleRequirementsChange(e, index)}></input>
                                        <div className='increment-decrement-buttons'>
                                            <FiPlusCircle onClick={() => handleRequirementsIncrement(index)} size={20} />
                                            <FiMinusCircle onClick={() => handleRequirementsDecrement(index)} size={20} />
                                        </div>
                                    </div>
                                )
                            })}
                            <p className='form-error'>{editFormErrors.requirementsError}</p>
                        </div>
                        <div className='edit-btn'>
                            <button>Edit</button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
            <Modal
                className='share_job_modal'
                size='md'
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={shareJobModalShow}
                onHide={() => setShareJobModalShow(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Share Job</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='sharable-link-wrapper'>
                        <p className='link'><FaLink size={18} />{link}</p>
                        <button onClick={handleCopyLink}>Copy</button>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default Job;
