import React, { useEffect, useRef, useState } from 'react';
import '../../scss/MCQAssessment.scss';
import Countdown from 'react-countdown';
import axios from '../../util/api';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../redux/userSlice';
import toast from 'react-hot-toast';
import { backend_url } from '../../util/config';
import { useLocation, useNavigate } from 'react-router-dom';
import TokenService from '../../util/TokenService';
import HireNowXLogo from '../../assests/images/HireNowXLogo.png';
import CloudLogo from '../../assests/images/cloudLogo.png';
import { ProgressBar } from 'react-bootstrap';
import { uploadFileToAzure } from '../../util/azureConfig';

function MCQAssessment() {
    const [timer, setTimer] = useState(null);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const videoRef = useRef(null);
    const dispatch = useDispatch();
    const location = useLocation();
    const [questions, setQuestions] = useState([]);
    const [totalQuestions, setTotalQuestions] = useState('');
    const [totalQuestionsAnswered, setTotalQuestionsAnswered] = useState(0);
    const [totalQuestionsInReview, setTotalQuestionsInReview] = useState(0);
    const [candidateDetails, setCandidateDetails] = useState({ email: '', name: '' , job_Title: ''});
    const [isTestStarted, setIsTestStarted] = useState(false);
    const chunks = useRef([]);
    const [mediaRecorder, setMediaRecorder] = useState(null);
    const [cameraAccess, setCameraAccess] = useState(null);
    const navigate = useNavigate();
    const token = location?.state?.token;

    const handleStartTest = async (e) => {
        e.preventDefault();
        try {
            const stream = await navigator.mediaDevices.getUserMedia({
                video: {
                    width: { ideal: 1280 },
                    height: { ideal: 720 },
                    aspectRatio: 16 / 9
                }
            });

            const recorder = new MediaRecorder(stream);
            recorder.ondataavailable = event => {
                if (event.data.size > 0) {
                    chunks.current.push(event.data);
                }
            };
            recorder.onerror = error => {

            }

            dispatch(setLoading(true));
            if (token) {
                const decryptData = TokenService.decryptToken(token);
                TokenService.setAccessToken(token);

                axios.post('/gen_quiz_fetch').then((res) => {
                    if (res.success) {
                        setIsTestStarted(true);
                        setTimer(Date.now() + decryptData?.duration * 60 * 1000);
                        setQuestions(res?.questions)
                        setTotalQuestions(res?.questions?.length)
                    } else {
                        toast.dismiss(); toast.error('Unable to fetch quiz')
                    }
                    dispatch(setLoading(false))
                }).catch((error) => {
                    dispatch(setLoading(false))
                    console.log(error);
                    toast.dismiss(); toast.error('Internal Error')
                })
            }

            recorder.start();
            setMediaRecorder(recorder);
            setCameraAccess(true);
        } catch (error) {
            if (error.name === 'NotAllowedError') {
                console.error('Camera access denied. Please allow camera access in your browser settings.');
                alert('Camera access was denied. Please enable camera access in your browser settings and try again.');
            } else if (error.name === 'NotFoundError') {
                console.error('No camera found. Please connect a camera and try again.');
                alert('No camera found. Please connect a camera and try again.');
            } else {
                console.error('An unexpected error occurred:', error);
                alert('An unexpected error occurred. Please check your camera settings and try again.');
            }
            setCameraAccess(false);
        }
    }

    useEffect(() => {
        if (isTestStarted && videoRef.current && mediaRecorder) {
            videoRef.current.srcObject = mediaRecorder.stream;
        }
    }, [isTestStarted, mediaRecorder])

    const renderer = ({ minutes, seconds, completed }) => {
        if (completed) {
            handleEndInterview();
            return <span>0s</span>;
        } else {
            const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
            const formattedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
            return <span>{formattedMinutes}:{formattedSeconds}</span>;
        }
    };


    const handleOptionChange = (questionIndex, optionId, optionValue) => {
        const updatedQuestion = [...questions];
        updatedQuestion[questionIndex].selectedOption = { optionId, optionValue };
        updatedQuestion[questionIndex].isMarkedForReview = false;
        setQuestions(updatedQuestion);
        const questionsAnswered = updatedQuestion.filter((question) => (question.selectedOption.optionValue));
        setTotalQuestionsAnswered(questionsAnswered.length);
    }

    const handleMarkForReview = (currentQuestionIndex) => {
        const updatedQuestions = [...questions];
        updatedQuestions[currentQuestionIndex] = {
            ...updatedQuestions[currentQuestionIndex],
            isMarkedForReview: true,
        };
        setQuestions(updatedQuestions)
        setCurrentQuestionIndex(currentQuestionIndex + 1);
        const questionsInReview = updatedQuestions.filter((question) => (question.isMarkedForReview));
        setTotalQuestionsInReview(questionsInReview.length);
    }

    const handleEndInterview = () => {
        if (mediaRecorder) {
            mediaRecorder.onstop = async () => {
                const blob = new Blob(chunks.current, { type: 'video/webm' });
                const blobName = `video-${Date.now()}.webm`;
                try {
                    dispatch(setLoading(true));
                    const response = await uploadFileToAzure(blob, blobName);
                    if (response.success) {
                        let data = JSON.stringify({
                            "questions": questions,
                            "mcq_link": response.url
                        });

                        let config = {
                            method: 'post',
                            maxBodyLength: Infinity,
                            url: `${backend_url}/evaluate`,
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            data: data
                        };
                        if (questions.length) {
                            dispatch(setLoading(true));
                            axios.request(config)
                                .then((response) => {
                                    if (response.success) {
                                        navigate('/thankyou', { replace: true });
                                    } else {
                                        toast.dismiss(); toast.error('unable to submit quiz')
                                    }
                                    dispatch(setLoading(false))
                                })
                                .catch((error) => {
                                    dispatch(setLoading(false));
                                    console.log(error);
                                    toast.dismiss(); toast.error('Internal Error')
                                });
                        }
                    }
                } catch (error) {
                    alert(error.message);
                    dispatch(setLoading(false));
                }
            };
            mediaRecorder.stop();
        }

        if (videoRef.current.srcObject) {
            const tracks = videoRef.current.srcObject.getTracks();
            tracks.forEach(track => track.stop());
        }
    }

    const handleSaveAndNext = () => {
        if (currentQuestionIndex < questions.length - 1) {
            setCurrentQuestionIndex(currentQuestionIndex + 1);
            const questionsAnswered = questions.filter((question) => (question.selectedOption.optionValue));
            setTotalQuestionsAnswered(questionsAnswered.length);
            let updatedQuestion = [...questions];
            updatedQuestion[currentQuestionIndex].visited = true;
            setQuestions(updatedQuestion)
        }
    }

    const handleQuestionChangeClick = (index) => {
        setCurrentQuestionIndex(index)
    }

    useEffect(() => {
        if (token) {
            let decryptedTokenData = TokenService.decryptToken(TokenService.getAccessToken());
            setCandidateDetails(prevData => ({
                ...prevData,
                email: decryptedTokenData?.email,
                name: decryptedTokenData?.username,
                job_Title: decryptedTokenData?.job_title
            }));
        } else {
            setTimeout(() => {
                toast.dismiss(); toast.error('Token not found')
            }, 500);
        }
    }, [])

    

    return (
        <>
            <div className='mcq-assessment-wrapper'>
                <div className='logo-section'>
                    <img src={HireNowXLogo} alt='Logo'></img>
                </div>
                <div className='assessment-section' style={{ paddingBottom: !isTestStarted ? "85px" : '' }}>
                    <div className='heading-section'>
                        <h2>Welcome to the MCQ Test</h2>
                        {isTestStarted ? (
                            <p>Job Title: {candidateDetails.job_Title}</p>
                        ) : (
                            <p>This test assesses your knowledge and skills. Answer all questions to the best of your ablility.</p>
                        )}
                    </div>
                    {!isTestStarted ? (
                        <>
                            <div className='content-body'>
                                <div className='candidate-details-section'>
                                    <h2>Job Title: {candidateDetails.job_Title}</h2>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td className='label'>Candidate Name</td>
                                                <td className='value'><span>:</span>{candidateDetails.name}</td>
                                            </tr>
                                            <tr>
                                                <td className='label'>Candidate Email Id</td>
                                                <td className='value'><span>:</span>{candidateDetails.email}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className='instructions-section'>
                                    <h2>Instructions:</h2>
                                    <ol>
                                        <li>Read Each Question Carefully: Make sure you understand what is being asked before selecting your answer.</li>
                                        <li>Time Management: Keep track of your time. It is advisable to allocate time evenly across all questions.</li>
                                        <li>Selection of Answers: Choose the option that you believe is most correct. Only one option should be selected for each question.</li>
                                        <li>Review: If time permits, review your answers before submitting the test.</li>
                                        <li>No External Help: This test should be completed independently without assistance from others or the use of unauthorized materials.</li>
                                        <li>Technical Requirements: Ensure your device is fully charged and that you have a stable internet connection.</li>
                                        <li>Submission: You must submit the test before the time expires. Once submitted, you cannot change your answers.</li>
                                        <li>Integrity: Any form of cheating or dishonesty will result in disqualification.</li>
                                    </ol>
                                </div>
                            </div>
                            <div className='start-test'>
                                <button className='start-test-btn' onClick={handleStartTest}>Start Test</button>
                            </div>
                        </>

                    ) : (
                        <div className='assessment-test-wrapper'>
                            <div className='assessment-test-container'>
                                <div className='assessment'>
                                    <div className='left'>
                                        <div className='question'>
                                            <p className='question-number-text'><span>{`${questions[currentQuestionIndex]?.questionNumber}. `}</span>{questions[currentQuestionIndex]?.questionText}</p>
                                            {questions[currentQuestionIndex]?.options.map((option, index) => {
                                                return (
                                                    <div key={index} className='options' style={{
                                                        border: option?.id === questions[currentQuestionIndex]?.selectedOption?.optionId
                                                            ? '1px solid #0a84ed'
                                                            : ''
                                                    }}>
                                                        <input
                                                            type='radio'
                                                            name='options'
                                                            id={option.id}
                                                            checked={option.id === questions[currentQuestionIndex]?.selectedOption?.optionId}
                                                            onChange={() => handleOptionChange(currentQuestionIndex, option?.id, option?.value)}
                                                        ></input>
                                                        <label htmlFor={option.id}>{option.value}</label>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                        <div className='buttons-section'>
                                            <div className='question-count'>
                                                <div className='answered-section'>
                                                    <span>Answered : </span>
                                                    <span>{totalQuestionsAnswered}/{totalQuestions}</span>
                                                </div>
                                                <div className='interviewInReview-section'>
                                                    <span>In Review : </span>
                                                    <span>{totalQuestionsInReview}</span>
                                                </div>
                                                <div className='unanswered-section'>
                                                    <span>Unanswered : </span>
                                                    <span>{totalQuestions - totalQuestionsAnswered}/{totalQuestions}</span>
                                                </div>
                                                {/* <p className='description'>All questions are compulsory*</p> */}
                                            </div>
                                            <div className='buttons'>
                                                {!(currentQuestionIndex === questions.length - 1) && (
                                                    <>
                                                        <button className='skip-button' onClick={() => handleMarkForReview(currentQuestionIndex)}>Mark for Review</button>
                                                        <button className='save-next-button' onClick={handleSaveAndNext}>Save & Next</button>
                                                    </>
                                                )}
                                                <button className='finish-button' onClick={handleEndInterview}>Finish</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='right'>
                                        <div className='question-count-container'>
                                            <div className='questions-counting'>
                                                {questions.map((question, index) => {
                                                    return (
                                                        <span key={index} className='question-count count' onClick={() => handleQuestionChangeClick(index)} style={{ backgroundColor: (currentQuestionIndex === index ? 'blue' : (question.isMarkedForReview ? 'yellow' : question.selectedOption.optionValue ? 'green' : question.visited ? 'red' : 'grey')), color: 'white' }} >{index + 1}</span>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                        <video height={'100%'} ref={videoRef} autoPlay></video>
                                        <div className='question-info'>
                                            <div className='time-remaining'>
                                                <span className='recording-icon'></span>
                                                <Countdown
                                                    className='countdown-timer'
                                                    date={timer}
                                                    renderer={renderer}
                                                    key={timer}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    )}
                </div>
                <div className='footer-section'>
                    <span>Powered by </span>
                    <img src={HireNowXLogo} alt='Logo'></img>
                </div>
            </div>
        </>
    )
}

export default MCQAssessment;