import React, { useState, useEffect } from 'react';
import '../../scss/Teams.scss';
import axios from '../../util/api';
import toast from 'react-hot-toast';
import Modal from 'react-bootstrap/Modal';
import { setLoading } from '../../redux/userSlice';
import { useDispatch } from 'react-redux';
import TokenService from '../../util/TokenService';
import { Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { CiEdit } from "react-icons/ci";
import Header from '../../components/Header';
import { CiUser } from "react-icons/ci";
import Swal from 'sweetalert2';


function Teams() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [corporateUsers, setCorporateUsers] = useState([]);
    const [selectAll, setSelectAll] = useState(false);

    const user = TokenService.getUser();
    let org_domain = user?.email?.split('@')[1]?.split('.')[0];

    useEffect(() => {
        if (user) {
            dispatch(setLoading(true))
            axios.post('/get_corporate_users').then((res) => {
                if (res.success) {
                    setCorporateUsers(res.users?.map((item) => ({ ...item, selected: false })));
                }
                dispatch(setLoading(false))
            }).catch((error) => {
                console.log(error);
                toast.dismiss();toast.error('Internal Error');
                dispatch(setLoading(false));
            })
        }
    }, [])

    const handleSelectAll = (e) => {
        setSelectAll(e.target.checked);
        let updatedList = [...corporateUsers];
        setCorporateUsers(updatedList.map(item => ({ ...item, selected: e.target.checked })))
    }

    const handleSingleSelect = (e, index) => {
        let updatedList = [...corporateUsers];
        updatedList[index].selected = e.target.checked;
        setSelectAll(updatedList.every(item => item.selected))
        setCorporateUsers(updatedList);
    }

    const handleDeleteMember = () => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#0a84ed",
            cancelButtonColor: "#d33",
            confirmButtonText: "Delete"
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(setLoading(true));
                const emails = corporateUsers.filter(user => user.selected).map(user => user.email);
                axios.post('/delete_users', { emails: JSON.stringify(emails) }).then(res => {
                    if (res.success) {
                        setCorporateUsers(prev => prev.filter(user => !emails.includes(user.email)))
                    }
                    dispatch(setLoading(false));
                }).catch(error => {
                    dispatch(setLoading(false));
                    console.log(error);
                })
            }
        });
    }

    return (
        <div className='teams-wrapper'>
            <Header heading={'My Team'} description={'Explore the talented individuals who make our company thrive. Get to know their roles, backgrounds and contributions'} />
            <div className="team-table">
                <Table responsive="md">
                    <thead>
                        <tr>
                            {user.role === 'corporateAdmin' && (
                                <th><input type="checkbox" checked={selectAll} onChange={handleSelectAll} /></th>
                            )}
                            <th className='text-center'>S.No</th>
                            <th className='text-center'>Employee ID</th>
                            <th className='text-center'>Name</th>
                            <th className='text-center'>Email</th>
                            <th className='text-center'>Designation</th>
                            <th className='text-center'>Contact Number</th>
                            {user.role === 'corporateAdmin' && (
                                <th></th>
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {corporateUsers.map((item, index) => {
                            return (
                                <tr key={index}>
                                    {user.role === 'corporateAdmin' && (
                                        <td><input type="checkbox" onChange={(e) => handleSingleSelect(e, index)} checked={item.selected} /></td>
                                    )}
                                    <td className='text-center'>{index + 1}</td>
                                    <td className='text-center'>{item.employee_id}</td>
                                    <td className='text-center'>{item.username}</td>
                                    <td className='text-center'>{item.email}</td>
                                    <td className='text-center'>{item.designation}</td>
                                    <td className='text-center'>{item.phone_number}</td>
                                    {user.role === 'corporateAdmin' && (
                                        <td>
                                            <CiUser className='profile-icon' onClick={() => navigate('/corporateDashboard/teams/userProfile', { state: { user_id: item.id } })} />
                                        </td>
                                    )}
                                </tr>
                            )
                        })}
                    </tbody>
                </Table>
            </div>
            {user.role === 'corporateAdmin' && (
                <div className="bottom">
                    <button onClick={() => navigate('/corporateDashboard/teams/addTeam')} className='add-member'>Add Team Member</button>
                    <button onClick={handleDeleteMember} className='delete-member'>Delete Team Member</button>
                </div>
            )}
        </div>

    )
}

export default Teams