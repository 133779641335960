import React, { forwardRef, useState, useEffect } from 'react';
import Styled from 'styled-components';
import '../styles/_mixins.scss';

const PostTemplate = forwardRef((props, ref) => {
  const [logoUrl, setLogoUrl] = useState('');

  useEffect(() => {
    if (props.formData.logo && props.formData.logo[0]) {
      const fileUrl = URL.createObjectURL(props.formData.logo[0]);
      setLogoUrl(fileUrl);
      return () => URL.revokeObjectURL(fileUrl);
    }
  }, [props.formData.logo]);

  return (
    <Wrapper ref={ref}>
      <div className='post-template-container'>
        <div className='logo'>
          <img src={logoUrl} alt='img'></img>
        </div>
        <div className='we-are-hiring-banner'>
          <h2>WE ARE</h2>
          <h1>HIRING!</h1>
          <h4>{props.formData.position}</h4>
        </div>
        <p>Share your resume : {props.email}</p>
      </div>
    </Wrapper>
  );
});

const Wrapper = Styled.div`
  .post-template-container {
    width: 100%;
    margin: auto;
    background-color: #4848a3;
    padding: 1rem 0 1rem 1rem;

    .we-are-hiring-banner {
      display: flex;
      flex-direction: column;
      justify-content: center;

      h2 {
        background-color: #ffbc00;
        color: blue;
        width: 20%;
      }

      h1 {
        background-color: blue;
        color: #fff;
        width: 30%;
      }

      h4 {
        width: 20%;
        background-color: #ffbc00;
        color: blue;
      }
    }
  }
`;

export default PostTemplate;
