import * as SpeechSDK from "microsoft-cognitiveservices-speech-sdk";
import { BlobServiceClient } from '@azure/storage-blob';
import { MaleAvatarAppConfig, FemaleAvatarAppConfig } from "./config";

const cogSvcRegion = MaleAvatarAppConfig.cogSvcRegion
const cogSvcSubKey = MaleAvatarAppConfig.cogSvcSubKey
let voiceName = MaleAvatarAppConfig.voiceName
let avatarCharacter = MaleAvatarAppConfig.avatarCharacter
let avatarStyle = MaleAvatarAppConfig.avatarStyle
const avatarBackgroundColor = "#FFFFFFFF";

var iceServerUrl = MaleAvatarAppConfig.iceUrl
var iceServerUsername = MaleAvatarAppConfig.iceUsername
var iceServerCredential = MaleAvatarAppConfig.iceCredential

const accountName = process.env.REACT_APP_AZURE_STORAGE_ACCOUNT_NAME;
const sasToken = process.env.REACT_APP_AZURE_STORAGE_SAS_TOKEN;

export const createWebRTCConnection = () => {

    var peerConnection = new RTCPeerConnection({
        iceServers: [{
            urls: [iceServerUrl],
            username: iceServerUsername,
            credential: iceServerCredential
        }]
    })

    return peerConnection;

}

export const createAvatarSynthesizer = (gender) => {
    if (gender === 'Female') {
        voiceName = FemaleAvatarAppConfig.voiceName
        avatarCharacter = FemaleAvatarAppConfig.avatarCharacter
        avatarStyle = FemaleAvatarAppConfig.avatarStyle
    }

    const speechSynthesisConfig = SpeechSDK.SpeechConfig.fromSubscription(cogSvcSubKey, cogSvcRegion)

    speechSynthesisConfig.speechSynthesisVoiceName = voiceName;

    const videoFormat = new SpeechSDK.AvatarVideoFormat()

    let videoCropTopLeftX = 600
    let videoCropBottomRightX = 1320
    videoFormat.setCropRange(new SpeechSDK.Coordinate(videoCropTopLeftX, 50), new SpeechSDK.Coordinate(videoCropBottomRightX, 1080));


    const talkingAvatarCharacter = avatarCharacter
    const talkingAvatarStyle = avatarStyle

    const avatarConfig = new SpeechSDK.AvatarConfig(talkingAvatarCharacter, talkingAvatarStyle, videoFormat)
    avatarConfig.backgroundColor = avatarBackgroundColor;
    let avatarSynthesizer = new SpeechSDK.AvatarSynthesizer(speechSynthesisConfig, avatarConfig)

    avatarSynthesizer.avatarEventReceived = function (s, e) {
        var offsetMessage = ", offset from session start: " + e.offset / 10000 + "ms."
        if (e.offset === 0) {
            offsetMessage = ""
        }
        console.log("[" + (new Date()).toISOString() + "] Event received: " + e.description + offsetMessage)
    }

    return avatarSynthesizer;

}

export const uploadFileToAzure = async (file, blobName) => {
    if (!file) {
        throw new Error('No file provided');
    }

    const blobServiceClient = new BlobServiceClient(
        `https://${accountName}.blob.core.windows.net?${sasToken}`
    );
    const containerClient = blobServiceClient.getContainerClient('interview-recordings');

    const blockBlobClient = containerClient.getBlockBlobClient(blobName);

    const uploadOptions = {
    };

    try {
        await blockBlobClient.uploadBrowserData(file, uploadOptions);
        const videoUrl = blockBlobClient.url;
        return { success: true, url: videoUrl };
    } catch (error) {
        console.error("Error uploading file: ", error);
        return { success: false }
    }
};
