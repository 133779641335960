import React, { useEffect, useState } from 'react';
import Header from '../../components/Header';
import '../../scss/AddTeam.scss';
import axios from '../../util/api';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../redux/userSlice';
import toast from 'react-hot-toast';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';

export default function AddTeam() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const [phoneNumber, setPhoneNumber] = useState();
    const user_id = location?.state ? location.state.user_id : null;

    const [candidate, setCandidate] = useState({ firstName: '', lastName: '', designation: '', department: '', employeeid: '', email: '' })
    const [candidateError, setCandidateError] = useState({ firstName: '', lastName: '', designation: '', department: '', employeeid: '', phoneNumber: '', email: '' })

    const validateForm = () => {
        let isValid = true;
        let errors = { firstName: '', lastName: '', designation: '', department: '', employeeid: '', phoneNumber: '', email: '' };

        if (!candidate.firstName) {
            errors.firstName = "First Name is required";
            isValid = false;
        }

        if (!candidate.lastName) {
            errors.lastName = "Last Name is required";
            isValid = false;
        }

        if (!candidate.designation) {
            errors.designation = "Designation is required";
            isValid = false;
        }

        if (!candidate.department) {
            errors.department = "Department is required";
            isValid = false;
        }

        if (!candidate.employeeid) {
            errors.employeeid = "Employee ID is required";
            isValid = false;
        }

        if (!phoneNumber) {
            errors.phoneNumber = "Phone Number is required";
            isValid = false;
        } else if (!isValidPhoneNumber(phoneNumber)) {
            errors.phoneNumber = "Phone Number is invalid";
            isValid = false;
        }

        if (!candidate.email) {
            errors.email = "Email is required";
            isValid = false;
        } else if (!/\S+@\S+\.\S+/.test(candidate.email)) {
            errors.email = "Email is invalid";
            isValid = false;
        }

        setCandidateError(errors);
        return isValid;
    }

    const handlePhoneNumberChange = (value) => {
        setPhoneNumber(value)
    }

    const handleAddMember = (e) => {
        e.preventDefault();
        if (validateForm()) {
            let data = {
                email: candidate.email,
                username: `${candidate.firstName} ${candidate.lastName}`,
                designation: candidate.designation,
                department: candidate.department,
                employee_id: candidate.employeeid,
                phone_number: phoneNumber
            }

            let url = '/add_corporate_user';
            if (user_id) {
                url = '/edit_corporate_user';
                data.id = user_id;
            }

            dispatch(setLoading(true));
            axios.post(url, data).then(res => {
                dispatch(setLoading(false));
                if (res.success) {
                    if(user_id){
                        toast.dismiss();toast.success('User updated successfully!')
                    }else{
                        toast.dismiss();toast.success('User created successfully!')
                    }
                    navigate('/corporateDashboard/teams')
                }
            }).catch(error => {
                console.log(error);
                dispatch(setLoading(false));
            })
        }
    }

    useEffect(() => {
        if (user_id) {
            dispatch(setLoading(true))
            axios.post('/get_corporate_user_by_id', { id: user_id }).then(res => {
                dispatch(setLoading(false));
                if (res.success && res.user) {
                    const name = res.user.username?.split(' ');
                    setCandidate({
                        firstName: name[0],
                        lastName: name[1] ? name[1] : '',
                        designation: res.user.designation,
                        department: res.user.department,
                        employeeid: res.user.employee_id,
                        phoneNumber: res.user.phone_number,
                        email: res.user.email
                    })
                }
            }).catch(error => {
                console.log(error);
                dispatch(setLoading(false));
            })
        }
    }, [])

    return (
        <div className="add-team-wrapper">
            <Header heading={'Add Your Team Members'} description={'Expand your team by adding new members. Fill in the details below to include them in your organization’s structure.'} />
            <div className="add-team-form">
                <form onSubmit={handleAddMember}>
                    <div className="first-section">
                        <div className="form-fields">
                            <label htmlFor="firstName">First Name:</label>
                            <input type="text" name="firstName" id="firstName" value={candidate.firstName} autoComplete='on' onChange={(e) => setCandidate({ ...candidate, [e.target.name]: e.target.value })} />
                            <span>{candidateError.firstName}</span>
                        </div>
                        <div className="form-fields">
                            <label htmlFor="lastName">Last Name:</label>
                            <input type="text" name="lastName" id="lastName" value={candidate.lastName} autoComplete='on' onChange={(e) => setCandidate({ ...candidate, [e.target.name]: e.target.value })} />
                            <span>{candidateError.lastName}</span>
                        </div>
                        <div className="form-fields">
                            <label htmlFor="designation">Designation:</label>
                            <input type="text" name="designation" id="designation" value={candidate.designation} autoComplete='on' onChange={(e) => setCandidate({ ...candidate, [e.target.name]: e.target.value })} />
                            <span>{candidateError.designation}</span>
                        </div>
                        <div className="form-fields">
                            <label htmlFor="department">Department:</label>
                            <input type="text" name="department" id="department" value={candidate.department} autoComplete='on' onChange={(e) => setCandidate({ ...candidate, [e.target.name]: e.target.value })} />
                            <span>{candidateError.department}</span>
                        </div>
                        <div className="form-fields">
                            <label htmlFor="firstName">Email:</label>
                            <input type="email" name="email" id="email" value={candidate.email} autoComplete='on' onChange={(e) => setCandidate({ ...candidate, [e.target.name]: e.target.value })} />
                            <span>{candidateError.email}</span>
                        </div>
                        <div className="form-fields">
                            <label htmlFor="employeeid">Employee ID:</label>
                            <input type="text" name="employeeid" id="employeeid" value={candidate.employeeid} autoComplete='on' onChange={(e) => setCandidate({ ...candidate, [e.target.name]: e.target.value })} />
                            <span>{candidateError.employeeid}</span>
                        </div>
                        <div className="form-fields">
                            <label htmlFor="phoneNumber">Phone Number:</label>
                            <PhoneInput
                                    id= 'phoneNumber'
                                    defaultCountry="IN"
                                    // countries={allowedCountries}
                                    value={phoneNumber}
                                    onChange={handlePhoneNumberChange}
                                />
                            {/* <input type="text" name="phoneNumber" id="phoneNumber" value={candidate.phoneNumber} autoComplete='on' onChange={(e) => setCandidate({ ...candidate, [e.target.name]: e.target.value })} /> */}
                            <span>{candidateError.phoneNumber}</span>
                        </div>
                    </div>

                    <div className="text-center bottom">
                        <button type='submit'>{!user_id ? 'Send Invite' : 'Update'}</button>
                    </div>
                </form>
            </div>
        </div>
    )
}
