import React from 'react'
import { Doughnut } from 'react-chartjs-2';
import { Chart, ArcElement, Legend } from 'chart.js'

function DoughnutChart({ data }) {
  const dataSet = {
    labels: ['Open Positions', 'AI Interview Scheduled', 'HR Interview Scheduled', 'Offer Letter Sent', 'Not Interested Candidates'],
    datasets: [
      {
        label: '# of Votes',
        data: [data.open_positions, data.ai_interview, data.hr_interview, data.offer_letter, data.not_interested_candidates],
        backgroundColor: [
          '#01B9B7',
          '#0A84ED',
          '#B9DBFE',
          '#9D53F2',
          '#ff374b',
        ],
        borderColor: [
          '#01B9B7',
          '#0A84ED',
          '#B9DBFE',
          '#9D53F2',
          '#ff374b',
        ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: true,
    cutout: '70%',
    plugins: {
      legend: {
        display: false,
        position: 'bottom', // You can change this to 'bottom', 'left', or 'right' as needed
        align: 'start',
      },
    },
  };
  Chart.register(ArcElement, Legend);
  return (
    <>
      <div className='chart-container'>
        <Doughnut data={dataSet} options={options} width={300} />
      </div>
      <div className="legends">
        <div className="legend"><span style={{ background: '#01B9B7' }}></span>Open Positions</div>
        <div className="legend"><span style={{ background: '#0A84ED' }}></span>AI Interview Scheduled</div>
        <div className="legend"><span style={{ background: '#B9DBFE' }}></span>HR Interview Scheduled</div>
        <div className="legend"><span style={{ background: '#9D53F2' }}></span>Offer Letters Sent</div>
        <div className="legend"><span style={{ background: '#ff374b' }}></span>Not Interested Candidates</div>
      </div>
    </>
  )
}

export default DoughnutChart