import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from '../util/api';
import { setLoading, setPreviewQuestions } from '../redux/userSlice';
import toast from 'react-hot-toast';

function FinalMCQTestPreview({ job_id }) {
    const dispatch = useDispatch();
    const finalQuestion = useSelector(state => state.user.previewQuestions);
    const handleSubmit = () => {
        sessionStorage.setItem('questions', JSON.stringify(finalQuestion));
        toast.success('Question saved successfully');
    }

    return (
        <>
            <div className='preview-heading'><span>Preview</span> <button className='btn' onClick={handleSubmit}>Move To Final</button> </div>
            <div className='final-mcq-test-preview-container'>
                <div className='content-body'>
                    <div className='questions-container'>
                        {finalQuestion.map((question, index) => (
                            <div key={index} className='question-card'>
                                <h3 className='question'>{index + 1}. {question.question}</h3>
                                <ul className='options'>
                                    <li>A. {question.optionone}</li>
                                    <li>B. {question.optiontwo}</li>
                                    <li>C. {question.optionthree}</li>
                                    <li>D. {question.optionfour}</li>
                                </ul>
                                <p className='answer'><strong>Correct Answer:</strong> {question.answer}</p>
                            </div>
                        ))}
                    </div>
                    {/* <div className='button'>
                        <button className='btn' onClick={handleSubmit}>Save</button>
                    </div> */}
                </div>
            </div>
        </>
    )
}

export default FinalMCQTestPreview